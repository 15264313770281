import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/services/globals.service';
moment.locale('pt-br');
@Component({
  selector: 'app-apuracao',
  templateUrl: './apuracao.component.html',
  styleUrls: ['./apuracao.component.css'],
})
export class ApuracaoComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public eleicoes = [];
  public assembleias = [];
  public filteredEleicoes = [];

  public active = 1;

  public filterDataInicio = '';
  public filterDataFinal = '';
  public filterSituacao = '';

  private cargaAssembleias;
  private infoSindicato: any = {};

  private labelSituação;

  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private toaster: ToasterService,
    private trataDatas: TrataNumeros,
    private router: Router,
    public globals: GlobalsService,

    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.cargaAssembleias = true;
    this._getInfoSindicato();
  }

  private async _getInfoSindicato() {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.sindicatosCnpj +
          `/${this.auth.currentUserValue.nomeCompleto}`
      );
      this.infoSindicato = resp.body.documento;
      this._getEleicoes();
      this.filterElections();
    } catch (err) {
      console.error('[[ERRO GET INFO SINDICATO]]');
    }
  }

  onTabChanged(tab) {
    if (this.cargaAssembleias) {
      this._getAssembleias();
    }
  }

  private async _getEleicoes() {
    try {
      this.blockUi.start('Carregando as informações');
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleicoes +
          `?idSindicato=${this.infoSindicato._id}`
      );
      this.eleicoes = []; // Limpar a lista antes de adicionar novos dados
      await Promise.all(
        resp.body.documento.map(async (e) => {
          if (e.status) {
            this.eleicoes.push({
              _id: e._id,
              nome: e.nome,
              dataInicio: e.dataInicio,
              dataFinal: e.dataFinal,
              idSindicato: e.idSindicato,
              eleitores: e.eleitores,
              ordemDia: e.ordemDia,
              situacao: await this._checkPeriodo(e),
              status: e.staus,
            });
          }
        })
      );

      console.log('Eleicoes carregadas:', this.eleicoes);
      this.filteredEleicoes = [...this.eleicoes];

      this.blockUi.stop();
      this.filterElections();
    } catch (err) {
      this.blockUi.stop();
      console.error('Erro ao obter eleições:', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  private async _getAssembleias() {
    try {
      this.blockUi.start('Carregando as informações');
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.assembleias +
          `?idSindicato=${this.infoSindicato._id}`
      );
      resp.body.documento.forEach(async (a) => {
        if (a.ativa) {
          a.chamadas.forEach((c) => {
            c.dataInicio = moment(c.dataInicio).add(3, 'hours').format('L LT');
            c.dataFim = moment(c.dataFim).add(3, 'hours').format('L LT');
          });
          this.assembleias.push({
            _id: a._id,
            nome: a.nome,
            chamadas: a.chamadas,
            idSindicato: a.idSindicato,
            eleitores: a.eleitores,
            ordemDia: a.ordemDia,
            descricao: a.descricao,
            situacao: await this._checkPeriodoAssembleia(
              a.chamadas[a.chamadas.length - 1].dataFim
            ),
            status: a.ativa,
          });
        }
      });
      this.cargaAssembleias = false;
      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET ELEIÇÕES]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  openListaPresenca(eleicao) {
    this.router.navigate(['/lista-presenca', eleicao._id]);
  }

  private _checkPeriodo(e) {
    if (
      this.trataDatas.validaDataHoraMenor(
        moment().format('DD/MM/YYYY HH:mm'),
        e.dataInicio
      )
    ) {
      this.labelSituação = 'ELEIÇÃO AINDA NÃO INICIADA';
      return this.labelSituação;
    } else {
      if (
        this.trataDatas.validaDataHoraMaiorOuIgual(
          moment().format('DD/MM/YYYY HH:mm'),
          e.dataFinal
        )
      ) {
        this.labelSituação = 'APURAÇÃO CONCLUÍDA';
        return this.labelSituação;
      } else {
        this.labelSituação = 'AGUARDANDO O ENCERRAMENTO';
        return this.labelSituação;
      }
    }
  }

  private _checkPeriodoAssembleia(dataFim) {
    return this.trataDatas.validaDataHoraMenorOuIgual(
      dataFim,
      moment().format('L LT')
    );
  }

  async openDashboardEleicao(eleicao) {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.urnaEleicoes +
          `?idEleicao=${eleicao._id}`
      );
      if (resp.body.documento.length > 0) {
        this.router.navigate(['/apuracao-eleicao', eleicao._id]);
      } else {
        if (
          this.trataDatas.validaDataHoraMenor(
            moment().format('DD/MM/YYYY HH:mm'),
            eleicao.dataInicio
          )
        ) {
          this.toaster.pop(
            'error',
            'SEVI',
            'Esta eleição ainda não foi iniciada.'
          );
        } else {
          // this.toaster.pop("error", "SEVI", "Esta eleição não possui nenhum voto computado.");
          this.router.navigate(['/apuracao-eleicao', eleicao._id]);
        }
      }
    } catch (err) {
      console.error('[[ERRO GET URNA]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Não foi possivel resgatar as informações desta eleição, tente novamente se o erro persistir comunique os gestores da plataforma.'
      );
    }
  }

  openListaPresencaAssembleias(assembleia) {
    this.router.navigate(['/lista-presenca-assembleia', assembleia._id]);
  }

  async openDashboardAssembleias(assembleia) {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.urnaAssembleia +
          `?idAssembleia=${assembleia._id}`
      );
      if (resp.body.documento.length > 0) {
        this.router.navigate(['/apuracao-assembleia', assembleia._id]);
      } else {
        this.toaster.pop(
          'error',
          'SEVI',
          'Esta assembleia não possui nem um voto computado.'
        );
      }
    } catch (err) {
      console.error('[[ERRO GET URNA]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Não foi possivel resgatar as informações desta assembleia, tente novamente se o erro persistir comunique os gestores da plataforma.'
      );
    }
  }

  filterElections() {
    if (
      !this.filterDataInicio &&
      !this.filterDataFinal &&
      !this.filterSituacao
    ) {
      this.filteredEleicoes = [...this.eleicoes];
      return;
    }

    this.filteredEleicoes = this.eleicoes.filter((e) => {
      const filterDataInicioValid =
        !this.filterDataInicio ||
        moment(e.dataInicio, 'DD/MM/YYYY').isSameOrAfter(
          moment(this.filterDataInicio, 'DD/MM/YYYY')
        );
      const filterDataFinalValid =
        !this.filterDataFinal ||
        moment(e.dataFinal, 'DD/MM/YYYY').isSameOrBefore(
          moment(this.filterDataFinal, 'DD/MM/YYYY')
        );
      const filterSituacaoValid =
        !this.filterSituacao || e.situacao === this.filterSituacao;

      return (
        filterDataInicioValid && filterDataFinalValid && filterSituacaoValid
      );
    });
  }
}
