import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { UtilService } from 'src/app/services/util.service';
import * as moment from 'moment';
moment.locale('pt-br');
@Component({
  selector: 'app-modal-add-chamada',
  templateUrl: './modal-add-chamada.component.html',
  styleUrls: ['./modal-add-chamada.component.css']
})
export class ModalAddChamadaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private util: UtilService,
    private trataDatas: TrataNumeros
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      chamada: [null, Validators.required],
      dataInicio: [null, Validators.required],
      horaInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      horaFim: [null, Validators.required],
    });

    if (this.item.tipo === "edit") {
      this.form.patchValue({
        chamada: this.item.obj.chamada,
        dataInicio: this.item.obj.dataInicio.split(" ")[0],
        horaInicio: this.item.obj.dataInicio.split(" ")[1],
        dataFim: this.item.obj.dataFim ? this.item.obj.dataFim.split(" ")[0] : null,
        horaFim: this.item.obj.dataFim ? this.item.obj.dataFim.split(" ")[1] : null
      });
      this.item.lista.splice(this.item.lista.findIndex(l => l === this.item.obj), 1);
    }
  }

  public validaCampos(tipo) {
    if (this.util.checkVariavel(this.form.get(tipo).value)) {
      let value = this.form.get(tipo).value;
      switch (tipo) {
        case "dataInicio":
          let dataInicioSplit = value.split("/");
          if (value.length < 10 || !(moment([dataInicioSplit[2], parseInt(dataInicioSplit[1]) - 1, dataInicioSplit[0]]).isValid())) {
            this.form.patchValue({ dataInicio: null })
            this.toaster.pop("error", "SEVI", "A data ou formato inválido, verifique");
          } else if (!this.trataDatas.validaDataMaiorOuIgual(value, moment().format("L"))) {
            this.form.patchValue({ dataInicio: null })
            this.toaster.pop("error", "SEVI", "A data não pode ser menor que a data atual");
          } else if (this.util.checkVariavel(this.form.get("dataFim").value)) {
            if (this.trataDatas.validaDataMaior(value, this.form.get("dataFim").value)) {
              this.form.patchValue({ dataInicio: null })
              this.toaster.pop("error", "SEVI", "A data inicio não pode ser maior que a data fim");
            } else {
              this._validaRange();
            }
          }
          break;
        case "horaInicio":
          if (value.length === 5) {
            let hi = value.split(":")[0];
            let mi = value.split(":")[1];
            this.form.patchValue({ horaInicio: `${parseInt(hi) > 23 ? '23' : hi}:${parseInt(mi) > 59 ? '59' : mi}` });
            this._validaRange();
          } else {
            this.form.patchValue({ horaInicio: null })
            this.toaster.pop("error", "SEVI", "A hora informada está em formato inválido");
          }
          break;
        case "dataFim":
          let dataFimSplit = value.split("/");
          if (value.length < 10 || !(moment([dataFimSplit[2], parseInt(dataFimSplit[1]) - 1, dataFimSplit[0]]).isValid())) {
            this.form.patchValue({ dataFim: null })
            this.toaster.pop("error", "SEVI", "A data ou formato inválido, verifique");
          } else if (!this.trataDatas.validaDataMaiorOuIgual(value, moment().format("L"))) {
            this.form.patchValue({ dataFim: null })
            this.toaster.pop("error", "SEVI", "A data não pode ser menor que a data atual");
          } else if (this.util.checkVariavel(this.form.get("dataInicio").value)) {
            if (this.trataDatas.validaDataMenor(value, this.form.get("dataInicio").value)) {
              this.form.patchValue({ dataFim: null })
              this.toaster.pop("error", "SEVI", "A data final não pode ser menor que a data inicio");
            } else {
              this._validaRange();
            }
          }
          break;
        case "horaFim":
          if (value.length === 5) {
            let hf = value.split(":")[0];
            let mf = value.split(":")[1];
            this.form.patchValue({ horaFim: `${parseInt(hf) > 23 ? '23' : hf}:${parseInt(mf) > 59 ? '59' : mf}` });
            this._validaRange();
          } else {
            this.form.patchValue({ horaFim: null })
            this.toaster.pop("error", "SEVI", "A hora informada está em formato inválido");
          }
          break;
        default:
          break;
      }
    }
  }

  public _validaRange() {
    let form = this.form.getRawValue();
    if (this.util.checkVariavel(form.dataInicio) && this.util.checkVariavel(form.horaInicio) && this.util.checkVariavel(form.dataFim) && this.util.checkVariavel(form.horaFim)) {
      if (this.trataDatas.validaDataHoraMaior(`${form.dataInicio} ${form.horaInicio}`, `${form.dataFim} ${form.horaFim}`)) {
        this.toaster.pop("error", "SEVI", "As datas são iguais mas o horário final é menor que horário inicial");
        this.form.patchValue({ horaFim: null })
      }
    }
  }

  private _checkPeriodo(dataInicio, horaInicio, dataFim, horaFim) {
    let result = [];
    this.item.lista.forEach(c => {
      if (!(this.trataDatas.validaDataHoraMenor(`${dataInicio} ${horaInicio}`, c.dataInicio) || this.trataDatas.validaDataHoraMaiorOuIgual(`${dataInicio} ${horaInicio}`, c.dataFim))) {
        if (result.filter(r => r === false).length === 0)
          this.toaster.pop("error", "SEVI", "A data e hora inicial está dentro de alguma chamada já cadastrada, verfique a mesma");
        result.push(false);
      }
      if (!(this.trataDatas.validaDataHoraMaior(`${dataFim} ${horaFim}`, c.dataFim) || this.trataDatas.validaDataHoraMenorOuIgual(`${dataFim} ${horaFim}`, c.dataInicio))) {
        if (result.filter(r => r === false).length === 0)
          this.toaster.pop("error", "SEVI", "A data e hora final está dentro de alguma chamada já cadastrada, verfique a mesma");
        result.push(false);
      }
      if (this.trataDatas.validaDataHoraMaiorOuIgual(c.dataInicio, `${dataInicio} ${horaInicio}`) && this.trataDatas.validaDataHoraMenorOuIgual(c.dataFim, `${dataFim} ${horaFim}`)) {
        if (result.filter(r => r === false).length === 0)
          this.toaster.pop("error", "SEVI", "Existe uma ou mais chamadas cadastrada dentro do range informado, verfique as datas e horários informadas");
        result.push(false);
      }
    });
    return result.filter(r => r === false).length === 0;
  }

  salvar() {
    this.submitted = true;
    if (this.form.valid) {
      if (this._checkPeriodo(this.form.get('dataInicio').value, this.form.get('horaInicio').value, this.form.get('dataFim').value, this.form.get('horaFim').value)) {
        this.modal.close(this.form.getRawValue());
      }
    } else {
      this.toaster.pop("error", "SEVI", "Verifique os dados obrigatórios e se existe ao menos uma chamada adicionada a assembléia.");
    }
  }
}
