<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{
      item.tipo === "edit" ? "Edição de" : item.tipo === "view" ? "" : "Novo"
    }}
    Eleitor
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="add-margin-top col-md-6 col-12">
        <label class="labels">CPF<span class="campo-required"> *</span></label>
        <input
          class="form-control input-round"
          type="text"
          id="cpf"
          formControlName="cpf"
          [ngClass]="{ invalido: form.get('cpf').invalid && submitted }"
          (blur)="checkCpf(this.form.get('cpf').value)"
          [textMask]="{ mask: globals.masks.cpf, guide: false }"
        />
        <p class="erro" *ngIf="form.get('cpf').invalid && submitted">
          Campo Obrigatório
        </p>
      </div>
      <div class="add-margin-top col-md-6 col-12">
        <label class="labels">Nome<span class="campo-required"> *</span></label>
        <input
          class="form-control input-round"
          type="text"
          id="nome"
          formControlName="nome"
          [ngClass]="{ invalido: form.get('nome').invalid && submitted }"
        />
        <p class="erro" *ngIf="form.get('nome').invalid && submitted">
          Campo Obrigatório
        </p>
      </div>

      <div class="add-margin-top col-md-6 col-12">
        <label class="labels"
          >E-mail<span class="campo-required"> *</span></label
        >
        <input
          class="form-control input-round"
          type="text"
          id="email"
          formControlName="email"
          [ngClass]="{ invalido: form.get('email').invalid && submitted }"
        />
        <p class="erro" *ngIf="form.get('email').invalid && submitted">
          Campo Obrigatório ou e-mail fora dos padrões
        </p>
      </div>
      <div class="add-margin-top col-md-6 col-12">
        <label class="labels">Sindicato/Empresa</label>
        <input
          class="form-control input-round"
          type="text"
          id="empresa"
          formControlName="empresa"
        />
      </div>
    </div>
    <!--
          <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Status do Eleitor</label>
                <label class="switch">
                    <input type="checkbox" formControlName="status">
                    <span class="slider round" [ngClass]="{sliderDisabled: item.tipo === 'view'}">
                        <p class="label-switch"
                            [ngClass]="{'label-switch-padding-right': form.get('status').value, 'label-switch-padding-left': !form.get('status').value}">
                            {{form.get('status').value ? 'ATIVA' : 'INATIVA'}}
                        </p>
                    </span>
                </label>
            </div>
        </div>
     -->
  </form>
</div>
<div class="modal-footer" style="justify-content: center">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="modal.close(false)"
  >
    Cancelar
  </button>
  <button
    type="button"
    class="btn btn-outline-success"
    *ngIf="item.tipo !== 'view'"
    (click)="salvar()"
  >
    Salvar
  </button>
</div>
