import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Ng2ScreenshotAPI } from 'ng2-screenshot';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
moment.locale("pt-br");

@Component({
  selector: 'app-dashboard-apuracao-assembleias',
  templateUrl: './dashboard-apuracao-assembleias.component.html',
  styleUrls: ['./dashboard-apuracao-assembleias.component.css']
})
export class DashboardApuracaoAssembleiasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;
  toggle = false;
  api: Ng2ScreenshotAPI;
  apiInitialized($event: Ng2ScreenshotAPI) {
    this.api = $event;
  }

  public nomeSind = "";
  public nomeAssembleia = "";
  public apuracaoAssembleia = [];
  public info = "";
  public show = false;

  private pautas = [];
  private votosAssembleia = [];

  constructor(
    private apiService: ApiService,
    private auth: AuthenticationService,
    private toaster: ToasterService,
    private trataDatas: TrataNumeros,
    private acRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.nomeSind = this.auth.currentUserValue.nomeCompleto;
    this._init(this.acRoute.snapshot.paramMap.get("assembleiaId"));
  }

  isOpenChange($event: boolean) {
    this.toggle = $event;
    document.getElementById("toggle-btn").classList.add("toggle-false");
    document.getElementById("toggle-btn").classList.remove("toggle-true");
  }

  async download() {
    try {
      await this.api.download()
    } catch (err) {
      console.error("[[DOWNLOAD IMAGE ERROR]]", err);
    }
  }

  private _checkPeriodoAssembleia(dataFim) {
    return this.trataDatas.validaDataHoraMenorOuIgual(moment(dataFim).add(3, "hours").format("L LT"), moment().format("L LT"));
  }

  private async _init(id) {
    this.blockUi.start("Processando Dados");
    try {
      const assembleia = await this.apiService.getGenericId(environment.urlBase + environment.paths.assembleias, id);

      if (!this._checkPeriodoAssembleia(assembleia.body.documento.chamadas[assembleia.body.documento.chamadas.length - 1].dataFim)) {
        this.toaster.pop("error", "SEVI", "O período de apuração desta assembleia ainda está fechado.");
        this.blockUi.stop();
        this.router.navigate(["/apuracao"])
        return;
      }

      const pautasResp = await this.apiService.getGeneric(environment.urlBase + environment.paths.pautas + `?idAssembleia=${id}`);
      const votosResp = await this.apiService.getGeneric(environment.urlBase + environment.paths.urnaAssembleia + `?idAssembleia=${id}`);

      this.nomeAssembleia = assembleia.body.documento.nome;
      this.pautas = pautasResp.body.documento;
      this.votosAssembleia = votosResp.body.documento;

      if (this.votosAssembleia.length > 0) {
        let qtdEleitores = assembleia.body.documento.eleitores.length;
        this.pautas.forEach(p => {
          this.apuracaoAssembleia.push({
            name: p.nome,
            idPauta: p._id,
            index: p.index,
            apuracao: [
              {
                name: "APROVAÇÕES",
                value: 0,
              },
              {
                name: "REPROVAÇÕES",
                value: 0,
              },
              {
                name: "ABSTENÇÕES",
                value: 0,
              },
              {
                name: "AUSENTES",
                value: qtdEleitores - this.votosAssembleia.length
              }
            ]
          });
        });

        this.votosAssembleia.forEach(va => {
          va.votos.forEach(v => {
            let index = this.apuracaoAssembleia.findIndex(a => a.idPauta === v.idPauta);
            switch (v.voto) {
              case "APROVO":
                this.apuracaoAssembleia[index].apuracao[0].value = this.apuracaoAssembleia[index].apuracao[0].value + 1;
                break;
              case "REPROVO":
                this.apuracaoAssembleia[index].apuracao[1].value = this.apuracaoAssembleia[index].apuracao[1].value + 1;
                break;
              case "ABSTENHO":
                this.apuracaoAssembleia[index].apuracao[2].value = this.apuracaoAssembleia[index].apuracao[2].value + 1;
                break;
              default:
                break;
            }
          });
        });

        this.info = `TOTAL DE ELEITORES APTOS: ${qtdEleitores} | TOTAL DE VOTOS CONTABILIZADOS: ${this.votosAssembleia.length} | TOTAL DE ELEITORES AUSENTES: ${qtdEleitores - this.votosAssembleia.length}`;

        setTimeout(() => {
          this.show = true;
          this.blockUi.stop();
        }, 500);

      } else {
        this.blockUi.stop();
        this.toaster.pop("error", "SEVI", "Não existem votos computados nesta eleição.");
      }

    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO GET RESULTADOS]]", err)
    }
  }

}
