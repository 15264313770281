import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
moment.locale('pt-br');

@Component({
  selector: 'app-lista-presenca-assembleias',
  templateUrl: './lista-presenca-assembleias.component.html',
  styleUrls: ['./lista-presenca-assembleias.component.css']
})
export class ListaPresencaAssembleiasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public momentUse = moment;
  public nomeSind = "";
  public listaVotantes = [];
  public assembleia;
  public qtdPresenca = 0

  private eleitores = [];
  private controleVotos = [];

  constructor(
    private toaster: ToasterService,
    private api: ApiService,
    private auth: AuthenticationService,
    private trataDocs: TrataDocsService,
    private acRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.nomeSind = this.auth.currentUserValue.nomeCompleto;
    this._getEleicao(this.acRoute.snapshot.paramMap.get("assembleiaId"));
  }

  private async _getEleicao(id) {
    this.blockUi.start("Carregando a Lista")
    try {
      const resp = await this.api.getGenericId(environment.urlBase + environment.paths.assembleias, id);
      this.assembleia = resp.body.documento;
      this._getEleitores(this.assembleia)
    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO GET ELEIÇÃO]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
    }
  }

  private async _getEleitores(assembleia) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + `${environment.paths.eleitores}?sindicatos=${assembleia.idSindicato}`);
      this.eleitores = resp.body.documento;
      const respVoto = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idAssembleia=${assembleia._id}`);
      this.controleVotos = respVoto.body.documento;
      if (this.eleitores.length > 0) {
        this.eleitores.forEach(async eleitor => {
          if (assembleia.eleitores.includes(eleitor._id)) {
            try {
              this.listaVotantes.push({
                entidade: eleitor.empresa,
                nome: eleitor.nome,
                cpf: this.trataDocs.addMaskCpf(eleitor.cpf),
                presenca: this.controleVotos.length <= 0 ? "NC" : this.controleVotos.filter(cv => cv.idEleitor === eleitor._id && cv.idProcuracao === undefined).length > 0 ? "C" : "NC"
              });
              this.listaVotantes = this.listaVotantes.sort((a, b) => a["nome"].toUpperCase().localeCompare(b["nome"].toUpperCase()));
            } catch (err) {
              this.blockUi.stop();
              console.error("[[ERRO GET ELEITORES]] => ", err)
            }
          }
        });

          this.qtdPresenca = this.listaVotantes.filter(lv => lv.presenca === "C").length;

        this.blockUi.stop();
      } else {
        this.toaster.pop("error", "SEVI", "Não existem eleitores selecionados nesta eleição.")
        this.blockUi.stop();
      }
    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO GET ELEITORES]] =>", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
    }
  }



  print() {
    window.print();
  }

}
