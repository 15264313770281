import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalConfirmacaoComponent } from 'src/app/modais/modal-confirmacao/modal-confirmacao.component';
import { ModalEleitoresComponent } from 'src/app/modais/modal-eleitores/modal-eleitores.component';
import { ModalUploadEleitoresComponent } from 'src/app/modais/modal-upload-eleitores/modal-upload-eleitores.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { forEachChild } from '@schematics/angular/third_party/github.com/Microsoft/TypeScript/lib/typescript';

@Component({
  selector: 'app-eleitores',
  templateUrl: './eleitores.component.html',
  styleUrls: ['./eleitores.component.css'],
})
export class EleitoresComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  public searchVal: string = '';

  public eleitores = [];
  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  public opcoes = [5, 10, 25, 50];

  private ultimaPagCarregada = 1;
  private infoSindicato: any = {};
  public sortColumn: string = '';
  public sortOrder: boolean = true;
  constructor(
    public modal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this._getInfoSindicato();
  }

  private async _getEleitores() {
    try {
      this.blockUi.start('Carregando as informações');
      setTimeout(async () => {
        const resp = await this.api.getGeneric(
          environment.urlBase +
            environment.paths.eleitores +
            `?sindicatos=${this.infoSindicato._id}`
        );
        this.eleitores = resp.body.documento;
        this.eleitores = this.eleitores.sort((a, b) =>
          a['nome'].toUpperCase().localeCompare(b['nome'].toUpperCase())
        );
        this.numRegistros = this.eleitores.length;
        await this.preProcess();
        this.blockUi.stop();
      }, 2500);
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET ELEICOES]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  private async preProcess() {
    this.eleitores.map((item) => {
      const instituicao = item.instituicao;
      instituicao?.forEach((data, index) => {
        if (data.id === this.infoSindicato._id) {
          item.index = index;
        }
      });
    });
  }

  private async _getInfoSindicato() {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.sindicatosCnpj +
          `/${this.authService.currentUserValue.nomeCompleto}`
      );
      this.infoSindicato = resp.body.documento;
      this._getEleitores();
    } catch (err) {
      console.error('[[ERRO GET INFO SINDICATO]]');
    }
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.eleitores = [];
    this.blockUi.start('Carregando');
    setTimeout(() => {
      this._getEleitores();
      this.blockUi.stop();
    }, 1500);
  }

  openModalUpload() {
    const modalRef = this.modal.open(ModalUploadEleitoresComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      idSindicato: this.infoSindicato._id,
    };
    modalRef.result.then((res) => {
      if (res) {
        this.paginaAtual = 1;
        this.eleitores = [];
        this._getEleitores();
      }
    });
  }

  openModalEleitor(tipo, eleitor?) {
    const modalRef = this.modal.open(ModalEleitoresComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      idSindicato: this.infoSindicato._id,
      tipo: tipo,
      obj: eleitor,
    };
    modalRef.result.then(async (res) => {
      if (res) {
        if (tipo === 'edit') {
          let index = this.eleitores.findIndex((e) => e._id === res._id);
          this.eleitores[index] = res;
          await this.preProcess();
        } else {
          this.paginaAtual = 1;
          this.eleitores = [];
          this._getEleitores();
        }
      }
    });
  }

  async excluir(eleitor) {
    const resp = await this.api.getGeneric(
      environment.urlBase +
        environment.paths.sindicatosCnpj +
        `/${this.authService.currentUserValue.nomeCompleto}`
    );

    const idInstituicao = resp.body.documento._id;

    eleitor.sindicatoId = idInstituicao;

    const modalRef = this.modal.open(ModalConfirmacaoComponent, {
      size: 'sm',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      title: 'Excluir Eleitor?',
      msg: 'Você realmente deseja excluir o eleitor selecionado de sua lista de eleitores?',
    };
    modalRef.result.then(async (res) => {
      if (res) {
        try {
          await this.api.deleteGeneric(
            environment.urlBase +
              environment.paths.eleitores +
              `/${eleitor._id}`,
            eleitor
          );

          this.toaster.pop('success', 'SEVI', 'Eleitor excluído de sua lista.');

          window.location.reload();
        } catch (err) {
          console.error('[[ERRO PUT ELEITOR]] => ', err);
          this.toaster.pop(
            'error',
            'SEVI',
            'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
          );
        }
      }
    });
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortColumn = column;

    this.eleitores.sort((a, b) => {
      let valueA: string | null = null;
      let valueB: string | null = null;

      if (column === 'instituicao') {
        valueA = a.instituicao?.[0]?.empresa?.toLowerCase() ?? null;
        valueB = b.instituicao?.[0]?.empresa?.toLowerCase() ?? null;
      } else {
        valueA = a[column]?.toLowerCase() ?? null;
        valueB = b[column]?.toLowerCase() ?? null;
      }

      if (valueA === null && valueB === null) {
        return 0;
      }

      if (valueA === null) {
        return this.sortOrder ? 1 : -1;
      }

      if (valueB === null) {
        return this.sortOrder ? -1 : 1;
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      }

      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn === column) {
      return this.sortOrder ? 'fas fa-angle-down' : 'fas fa-angle-up';
    }
    return 'fas fa-arrows-alt-v';
  }

  get filteredEleitor() {
    if (!this.searchVal) {
      return this.eleitores;
    }
    return this.eleitores.filter((sind) =>
      sind.nome.toLowerCase().includes(this.searchVal.toLowerCase())
    );
  }
}
