<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Votação</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

    <div *ngIf="!liberarVoto" style="text-align: center;">
        <h5>Voto(s) já computado(s)</h5>
    </div>

    <div *ngIf="liberarVoto">


        <div *ngIf="selecaoVoto" style="text-align: center;">
            <div *ngIf="tipos.length > 0 && votoComputado === false">
                <h5>Selecione o seu tipo de voto</h5>
                <div class="btn-chapa" *ngFor="let t of tipos" (click)="selectTipo(t)">
                    {{t.label}}
                </div>
            </div>
            <div *ngIf="votoComputado">
                <p>Seu voto já foi computado.</p>
            </div>
        </div>


        <div *ngIf="!selecaoVoto">
            <div class="row" *ngIf="chapas.length > 0">
                <div class="col-md-4 col-12">
                    <div class="btn-chapa" *ngFor="let c of chapas" (click)="selectChapa(c)">
                        {{c.nomeChapa ? c.nomeChapa : "EM BRANCO"}}
                    </div>
                    <div *ngIf="chapaSelecionada">
                        <span *ngIf="!habilitarVotacao" class="alert-danger text-center">Período de Votação Fechado</span>
                        <div style="text-align: center; margin: 10px 0px;">
                            <button type="button" class="btn btn-outline-success" [disabled]="!habilitarVotacao"  (click)="votar()">Votar em
      {{chapaSelecionada.nomeChapa}}</button>
                        </div>
                    </div>

                </div>
                <div class="col-md-8 col-12">
                    <p class="erro" *ngIf="chapaSelecionada === null">Selecione sua opção de voto ao lado.</p>
                    <div *ngIf="chapaSelecionada">
                        <h5 style="text-align: center;">{{chapaSelecionada.nomeChapa}}</h5>
                        <!-- <div style="text-align: center; margin: 10px 0px;">
                            <button type="button" class="btn btn-outline-success" (click)="votar()">Votar em
          {{chapaSelecionada.nomeChapa}}</button>
                        </div> -->
                        <div *ngIf="chapaSelecionada.integrantes">
                            <table *ngIf="chapaSelecionada.nomeChapa">
                                <thead>
                                    <tr>
                                        <th class="th-rigth-border">Nome</th>
                                        <th class="th-rigth-border">Cargo</th>
                                    </tr>
                                </thead>
                                <tbody id="teste">
                                    <tr *ngFor="let i of chapaSelecionada.integrantes">
                                        <td>{{ i.nome }}</td>
                                        <td>{{ i.cargo }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row" *ngIf="chapas.length <= 0">
                <p class="erro" *ngIf="chapaSelecionada === null">Não existem chapas cadastradas para esta eleição.</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button *ngIf="!selecaoVoto" type="button" class="btn btn-outline-primary" (click)="reset()">Retornar</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
</div>