import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';
import { TrataNumeros } from './trata-numeros.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private comToken = false;
  constructor(
    private http: HttpClient,
    private globals: GlobalsService,
    private trataNumeros: TrataNumeros
  ) {}

  getGeneric(path: string, query: string = '') {
    let headers = this._formaToken();
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(path + query, { headers: headers, observe: 'response' })
        .subscribe(
          (res: any) => {
            this._updateTokens(
              res.headers.get('token'),
              res.headers.get('refreshtoken')
            );
            resolve(<any>res);
          },
          (erro) => {
            reject(<any>erro);
          }
        );
    });
  }

  getGenericId(path: string, id: string) {
    let headers = this._formaToken();
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(path + '/' + id, { headers: headers, observe: 'response' })
        .subscribe(
          (res: any) => {
            this._updateTokens(
              res.headers.get('token'),
              res.headers.get('refreshtoken')
            );
            resolve(<any>res);
          },
          (erro) => {
            reject(<any>erro);
          }
        );
    });
  }

  getGenericSemToken(path: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(path).subscribe(
        (res: any) => {
          resolve(<any>res);
        },
        (erro) => {
          reject(<any>erro);
        }
      );
    });
  }

  postGenericSemToken(path: string, objeto: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(path, objeto).subscribe(
        (res: any) => {
          resolve(<any>res);
        },
        (erro) => {
          reject(<any>erro);
        }
      );
    });
  }

  postGeneric(path: string, objeto: any) {
    let headers = this._formaToken();
    return new Promise<any>((resolve, reject) => {
      this.http
        .post(path, objeto, { headers: headers, observe: 'response' })
        .subscribe(
          (res: any) => {
            this._updateTokens(
              res.headers.get('token'),
              res.headers.get('refreshtoken')
            );
            resolve(<any>res);
          },
          (erro) => {
            reject(<any>erro);
          }
        );
    });
  }

  putGeneric(path: string, objeto: any) {
    let headers = this._formaToken();
    return new Promise<any>((resolve, reject) => {
      this.http
        .put(path, objeto, { headers: headers, observe: 'response' })
        .subscribe(
          (res: any) => {
            this._updateTokens(
              res.headers.get('token'),
              res.headers.get('refreshtoken')
            );
            resolve(<any>res);
          },
          (erro) => {
            reject(<any>erro);
          }
        );
    });
  }

  putGenericSemToken(path: string, objeto: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.put(path, objeto).subscribe(
        (res: any) => {
          resolve(<any>res);
        },
        (erro) => {
          reject(<any>erro);
        }
      );
    });
  }

  deleteGeneric(path: string, objeto?: any) {
    let headers = this._formaToken();
    return new Promise<any>((resolve, reject) => {
      this.http
        .request('delete', path, {
          headers: headers,
          body: objeto,
          observe: 'response',
        })
        .subscribe(
          (res: any) => {
            this._updateTokens(
              res.headers.get('token'),
              res.headers.get('refreshtoken')
            );
            resolve(<any>res);
          },
          (erro) => {
            reject(<any>erro);
          }
        );
    });
  }

  private _formaToken() {
    let headers = new HttpHeaders();
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user.access_token && user.refreshtoken) {
      return (headers = new HttpHeaders({
        token: user.access_token,
        refreshtoken: user.refreshtoken,
      }));
    } else if (sessionStorage.getItem('token')) {
      return (headers = new HttpHeaders({ token: user.access_token }));
    } else {
      return (headers = headers);
    }
  }

  private _updateTokens(accesstoken, refreshtoken) {
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    user.access_token = accesstoken;
    user.refreshtoken = refreshtoken;
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  DownLoad(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  viaCep(cep) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`https://viacep.com.br/ws/${cep}/json/`).subscribe(
        (res) => {
          resolve(<any>res);
        },
        (err) => {
          console.error('[[ERRO VIA CEP]]', err);
          reject(<any>err);
        }
      );
    });
  }
}
