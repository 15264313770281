<!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">

        Sindicato</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">

        <div class="row">
            <div class="add-margin-top col-lg-8 col-md-6 col-12">
                <label class="labels">Nome do usuário<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome" [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-lg-4 col-md-6 col-12">
                <label class="labels">CPF/CNPJ<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="cnpj" formControlName="cnpj" [ngClass]="{'invalido':form.get('cnpj').invalid && submitted}" [textMask]="{mask: globals.masks.cnpj, guide: false}" (blur)="verificaCnpj(this.form.get('cnpj').value)">
                <p class="erro" *ngIf="form.get('cnpj').invalid && submitted">Campo Obrigatório</p>
            </div>
        </div>

        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">E- mail<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="email" formControlName="email" [ngClass]="{'invalido':form.get('email').invalid && submitted}">
                <p class="erro" *ngIf="form.get('email').invalid && submitted">Campo Obrigatório ou o formato de e-mail é inválido
                </p>
            </div>
        </div>
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Tipo do usuário</label>
                <select class="form-control input-round" type="text" id="tipo" formControlName="tipo" role="textbox">
                  <option *ngFor="let r of hardCode.roles" [value]="r.role"> {{r.role}}
                    {{r.role ? '(' + r.role + ')' : ""}} </option>
                </select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">{{item.tipo === "view" ? "Fechar" :
    "Cancelar"}}</button>
    <button type="button" class="btn btn-outline-success" *ngIf="item.tipo !== 'view'" (click)="salvar(this.form['tipo'])">Salvar</button>
</div> -->

<div class="modal-header">
    <h4 class="modal-title">Novo Usuário</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">

        <div class="row ">
            <div class="add-margin-top col-lg-6 col-md-6 col-6">
                <label class="labels">Nome do usuário<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome" [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-lg-6 col-md-6 col-6">
                <label class="labels">Tipo do usuário</label>
                <select class="form-control input-round" type="text" id="tipo" formControlName="tipo" role="textbox">
          <option *ngFor="let r of hardCode.roles" [value]="r.role"> {{r.label}}
            <!-- {{r.role ? '(' + r.role + ')' : ""}}  -->
          </option>
        </select>
            </div>

        </div>

        <div class="row">
            <ng-container *ngIf="form.get('tipo').value === null; else elseTemplate">
                <div class="add-margin-top col-lg-4 col-md-6 col-12" *ngIf="form.get('tipo').value === 'SEVI_SIND[*]'">
                    <label class="labels">CNPJ<span class="campo-required"> *</span></label>
                    <input class="form-control input-round" type="text" id="cpf_cnpj" formControlName="cpf_cnpj" [ngClass]="{'invalido':form.get('cpf_cnpj').invalid && submitted}" [textMask]="{mask: globals.masks.cnpj}" disabled>
                    <p class="erro" *ngIf="form.get('cpf_cnpj').invalid && submitted">Campo Obrigatório</p>
                </div>
                <div class="add-margin-top col-lg-4 col-md-6 col-12" *ngIf="!(form.get('tipo').value === 'SEVI_SIND[*]')">
                    <label class="labels">CPF<span class="campo-required"> *</span></label>
                    <input class="form-control input-round" type="text" id="cpf_cnpj" formControlName="cpf_cnpj" [ngClass]="{'invalido':form.get('cpf_cnpj').invalid && submitted}" [textMask]="{mask: globals.masks.cpf}" disabled>
                    <p class="erro" *ngIf="form.get('cpf_cnpj').invalid && submitted">Campo Obrigatório</p>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="add-margin-top col-lg-4 col-md-6 col-12" *ngIf="form.get('tipo').value === 'SEVI_SIND[*]'">
                    <label class=" labels ">CNPJ<span class="campo-required "> *</span></label>
                    <input class="form-control input-round " type="text " id="cpf_cnpj" formControlName="cpf_cnpj" [ngClass]="{ 'invalido':form.get( 'cpf_cnpj').invalid && submitted} " [textMask]="{mask: globals.masks.cnpj} ">
                    <p class="erro " *ngIf="form.get( 'cpf_cnpj').invalid && submitted ">Campo Obrigatório</p>
                </div>
                <div class="add-margin-top col-lg-4 col-md-6 col-12 " *ngIf="!(form.get( 'tipo').value==='SEVI_SIND[*]' ) ">
                    <label class="labels ">CPF<span class="campo-required "> *</span></label>
                    <input class="form-control input-round " type="text " id="cpf_cnpj" formControlName="cpf_cnpj" [ngClass]="{ 'invalido':form.get( 'cpf_cnpj').invalid && submitted} " [textMask]="{mask: globals.masks.cpf} ">
                    <p class="erro " *ngIf="form.get( 'cpf_cnpj').invalid && submitted ">Campo Obrigatório</p>
                </div>
            </ng-template>
            <div class="add-margin-top col-8 ">
                <label class="labels ">E- mail<span class="campo-required "> *</span></label>
                <input class="form-control input-round " type="text " id="email" formControlName="email" [ngClass]="{ 'invalido':form.get( 'email').invalid && submitted} ">
                <p class="erro " *ngIf="form.get( 'email').invalid && submitted ">Campo Obrigatório ou o formato de e-mail é inválido
                </p>
            </div>
        </div>

        <div class="row ">
            <div class="add-margin-top col-6 ">
                <label class="labels ">Login<span class="campo-required"> *</span></label>
                <input class="form-control input-round " type="text " id="login" formControlName="login" [ngClass]="{ 'invalido':form.get( 'login').invalid && submitted} ">
                <p class="erro " *ngIf="form.get( 'login').invalid && submitted ">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-6 ">
                <label class="labels ">Senha<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="password" id="password" formControlName="password" [ngClass]="{ 'invalido':form.get( 'password').invalid && submitted} ">
                <p class="erro " *ngIf="form.get( 'password').invalid && submitted ">Campo Obrigatório</p>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer ">
    <button type="button " class="btn btn-outline-success " (click)="salvar() ">Salvar</button>
    <button type="button " class="btn btn-outline-dark " (click)="passBack()">Fechar</button>
</div>