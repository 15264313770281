import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import * as $ from 'jquery';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ModalResetSenhaComponent } from 'src/app/modais/modal-reset-senha/modal-reset-senha.component';
import { ModalAvisoComponent } from 'src/app/modais/modal-aviso/modal-aviso.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public form: FormGroup;
  public submitted = false;
  public tooltip = "Exibir Senha";

  constructor(
    public formBuilder: FormBuilder,
    public globals: GlobalsService,
    private api: ApiService,
    private router: Router,
    private toaster: ToasterService,
    private modalService: NgbModal,
    private trataDocs: TrataDocsService,
    private authService: AuthenticationService
  ) {
    this.blockUi.stop();
  }

  ngOnInit(): void {
    $(".conteudo").addClass("background");
    this.form = this.formBuilder.group({
      login: [null, Validators.required],
      senha: [null, Validators.required]
    })
  }

  async login() {
    this.submitted = true;
    if (this.form.valid) {
      this.blockUi.start("Carregando")
      try {
        let login = this.form.getRawValue();
        login.login = this.trataDocs.validaCPF(login.login) || this.trataDocs.validaCNPJ(login.login) ? this.trataDocs.limpaDoc(login.login) : login.login;
        const resp = await this.authService.login(login.login, login.senha);
        switch (resp.roles[0]) {
          case "SEVI_ADM[*]":
            $(".conteudo").removeClass("background");
            this.router.navigate(['/home-administrador'])
            break;
          case "SEVI_SIND[*]":
            $(".conteudo").removeClass("background");
            this.router.navigate(['/home-sindicatos'])
            break;
          case "SEVI_ELEITOR[*]":
            $(".conteudo").removeClass("background");
            this.router.navigate(['/home-eleitor'])
            break;
          default:
            break;
        }
        this.blockUi.stop();
      } catch (err) {
        this.toaster.pop("error", "SEVI", "Login ou senha incorretos, tente novamente");
        this.blockUi.stop();
      }
    }
  }

  exibirSenha() {
    if ($("#senha").attr("type") === "password") {
      document.getElementById("senha").setAttribute("type", "text")
      document.getElementById("iconExibirSenha").classList.remove("fa-eye")
      document.getElementById("iconExibirSenha").classList.add("fa-eye-slash")
      this.tooltip = "Ocultar Senha"
    } else {
      document.getElementById("senha").setAttribute("type", "password")
      document.getElementById("iconExibirSenha").classList.remove("fa-eye-slash")
      document.getElementById("iconExibirSenha").classList.add("fa-eye")
      this.tooltip = "Exibir Senha"
    }
  }

  openModalResetSenha() {
    const modalRef = this.modalService.open(ModalResetSenhaComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {};
    modalRef.result.then(res => {
      if (res) {
        const modalAvisoRef = this.modalService.open(ModalAvisoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
        modalAvisoRef.componentInstance.item = {
          title: "Nova Senha", msg: "A sua senha foi resetada com sucesso. Em breve você receberá em seu e-mail cadastrado a sua nova senha para realizar o acesso ao sistema, lembrando que o seu login é seu CNPJ ou CPF dependendo do seu perfil."
        };
      }
    });
  }



}
