<div *ngIf="assembleia">
    <div class="row row-acoes" style="text-align: center;">
        <div class="col-12">
            <button class="btn btn-success" (click)="print()"><i class="fas fa-print"></i> Imprimir Lista de
                Presença</button>
        </div>
    </div>

    <div class="row" style="text-align: center;">
        <div class="col-12 titulos">
            {{nomeSind}}
        </div>
        <div class="col-12 titulos">
            {{assembleia.nome}}
        </div>
        <div class="col-12 titulos">
            Tipo da Assembléia
        </div>
        <div class="col-12">
            <p style="margin: 0!important;">{{assembleia.ordinaria ? "Ordinária" : ""}} {{assembleia.ordinaria &&
                assembleia.extraordinaria? " e " : ""}} {{assembleia.extraordinaria ? "Extrardinária" : ""}}</p>
        </div>
        <div class="col-12 titulos">
            {{assembleia.chamadas.length > 1 ? "Convocações" : "Convocação"}} da Assembléia
        </div>
        <div class="col-12">
            <p style="margin: 0!important;" *ngFor="let c of assembleia.chamadas">{{c.chamada}} - {{momentUse(c.dataInicio).add(3, 'hours').format("L LT")}}</p>
        </div>
        <div class="col-12 titulos">
            Local: Via plataforma eletrônica - SEVI - Sistema Eletrônico de Votação para a Indústria
        </div>
        <div class="col-12 titulos">
            Ordem do Dia
        </div>
        <div class="col-12">
            {{assembleia.descricao ? assembleia.descricao : "Não informado"}}
        </div>
        <div class="col-12 titulos">
            LISTA DE PRESENÇA
        </div>
        <div class="col-12" style="text-align: end; padding: 0!important;">
            Votos realizados {{qtdPresenca}} de {{listaVotantes.length}}
        </div>
    </div>
    <table style="margin-top: 0!important;">
        <thead>
            <tr>
                <th class="th-rigth-border" style="width: 25%;">Entidade</th>
                <th class="th-rigth-border" style="width: 30%;">Nome do Representante</th>
                <th class="th-rigth-border" style="width: 15%;">CPF</th>
                <th class="th-rigth-border-green" style="width: 30%;">Presença</th>
            </tr>
        </thead>
        <tbody id="teste" *ngIf="listaVotantes.length > 0">
            <tr *ngFor="let lv of listaVotantes">
                <td>{{ lv.entidade }}</td>
                <td>{{ lv.nome }}</td>
                <td>{{ lv.cpf }}</td>
                <td>
                    <div
                        [ngClass]="{'presente': lv.presenca === 'C', 'nao-presente': lv.presenca === 'NC' ||  lv.presenca === 'RNC', 'representado': lv.presenca === 'R'}">
                        {{ lv.presenca === "C" ? "COMPARECEU" : lv.presenca === "NC" ? "NÃO COMPARECEU" : lv.presenca
                        === "R" ? "REPRESENTADO" : "PROCURADOR NÃO COMPARECEU"}}
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <p *ngIf="listaVotantes.length <= 0" style="color: #ff0000; font-weight: bold;">• Não existem eleitores selecionados para esta assembleia.</p>
</div>