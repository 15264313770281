import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalEleicoesComponent } from 'src/app/modais/modal-eleicoes/modal-eleicoes.component';
import { ModalGerenciaChapasComponent } from 'src/app/modais/modal-gerencia-chapas/modal-gerencia-chapas.component';
import { ModalSelecionarEleitoresComponent } from 'src/app/modais/modal-selecionar-eleitores/modal-selecionar-eleitores.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ModalProcuracaoComponent } from 'src/app/modais/modal-procuracao/modal-procuracao.component';
moment.locale('pt-br');
@Component({
  selector: 'app-home-sind',
  templateUrl: './home-sind.component.html',
  styleUrls: ['./home-sind.component.css'],
})
export class HomeSindComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  public searchVal: string = '';

  public eleicoes = [];
  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  public opcoes = [5, 10, 25, 50];
  private ultimaPagCarregada = 1;
  private infoSindicato: any = {};

  constructor(
    public modal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService,
    private authService: AuthenticationService,
    private trataDocs: TrataDocsService,
    private trataDatas: TrataNumeros
  ) {}

  ngOnInit(): void {
    this._getInfoSindicato();
  }

  private async _getInfoSindicato() {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.sindicatosCnpj +
          `/${this.authService.currentUserValue.nomeCompleto}`
      );
      this.infoSindicato = resp.body.documento;
      this._getEleicoes();
    } catch (err) {
      console.error('[[ERRO GET INFO SINDICATO]]');
    }
  }

  private async _getEleicoes() {
    try {
      this.blockUi.start('Carregando as informações');
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleicoes +
          `?idSindicato=${this.infoSindicato._id}`
      );
      resp.body.documento.forEach((e) => {
        this.eleicoes.push(e);
      });
      this.numRegistros = this.eleicoes.length;

      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET ELEIÇÕES]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.eleicoes = [];
    this.blockUi.start('Carregando');
    setTimeout(() => {
      this._getEleicoes();
      this.blockUi.stop();
    }, 1500);
  }

  modalEleicao(tipo, eleicao?) {
    if (eleicao) {
      if (tipo === 'view') {
        this._openModalEleicao(tipo, eleicao);
      } else {
        // if (this._checkPeriodo(eleicao)) {
        this._openModalEleicao(tipo, eleicao);
        // } else {
        //   this.toaster.pop("error", "SEVI", "O período desta eleição já esta fechado, não é possível editar a mesma.");
        // }
      }
    } else {
      this._openModalEleicao(tipo);
    }
  }

  private _openModalEleicao(tipo, eleicao?) {
    const modalRef = this.modal.open(ModalEleicoesComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      tipo: tipo,
      obj: eleicao,
      idSindicato: this.infoSindicato._id,
    };
    modalRef.result.then((res) => {
      if (res) {
        if (eleicao) {
          let index = this.eleicoes.findIndex((s) => s._id === eleicao._id);
          this.eleicoes[index] = res;
        } else {
          this.eleicoes.push(res);
        }
      }
    });
  }

  selecionarEleitores(eleicao) {
    // if (this._checkPeriodo(eleicao)) {
    const modalRef = this.modal.open(ModalSelecionarEleitoresComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      obj: eleicao,
      idSindicato: this.infoSindicato._id,
    };
    modalRef.result.then((res) => {
      if (res) {
        let index = this.eleicoes.findIndex((e) => e._id === eleicao._id);
        this.eleicoes[index] = res;
      }
    });
    // } else {
    //   this.toaster.pop("error", "SEVI", "O período desta eleição já esta fechado, não é possível gerenciar os eleitores.");
    // }
  }

  submeterProcuracao(eleicao) {
    if (this._checkPeriodo(eleicao)) {
      const modalRef = this.modal.open(ModalProcuracaoComponent, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.item = {
        idEleicao: eleicao._id,
        idSindicato: eleicao.idSindicato,
      };
      modalRef.result.then(async (res) => {
        try {
          let index = this.eleicoes.findIndex((e) => e._id === eleicao._id);
          const resp = await this.api.getGenericId(
            environment.urlBase + environment.paths.eleicoes,
            eleicao._id
          );
          this.eleicoes[index] = resp.body.documento;
        } catch (err) {
          console.error('[[ERRO GET ELEIÇÃO]] => ', err);
        }
      });
    } else {
      this.toaster.pop(
        'error',
        'SEVI',
        'O período desta eleição já esta fechado, não é possível gerenciar os eleitores.'
      );
    }
  }

  openModalChapa(eleicao) {
    // if (this._checkPeriodo(eleicao)) {
    const modalRef = this.modal.open(ModalGerenciaChapasComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      id: eleicao._id,
    };
    // } else {
    //   this.toaster.pop("error", "SEVI", "O período desta eleição já esta fechado, não é possível gerenciar as chapas.");
    // }
  }

  private _checkPeriodo(e) {
    return this.trataDatas.validaDataHoraMenorOuIgual(
      moment().format('DD/MM/YYYY HH:mm'),
      e.dataFinal
    );
  }

  get filteredEleicao() {
    if (!this.searchVal) {
      return this.eleicoes;
    }
    return this.eleicoes.filter((sind) =>
      sind.nome.toLowerCase().includes(this.searchVal.toLowerCase())
    );
  }
}
