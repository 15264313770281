<div class="row" style="justify-content: space-between; align-items: center">
  <h3 class="titulo">Eleições</h3>
  <button class="btn btn-outline-success" (click)="modalEleicao('new')">
    <i class="fas fa-plus"></i> Nova Eleição
  </button>
</div>
<hr />
<div class="d-flex justify-content-end">
  <input
    type="text"
    class="form-control"
    style="width: 400px; border-radius: 14px"
    [(ngModel)]="searchVal"
    placeholder="Buscar Eleição"
  />
</div>
<div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px">
  <table>
    <thead>
      <tr>
        <th class="th-rigth-border">Eleição</th>
        <th class="th-rigth-border">Período</th>
        <th class="th-rigth-border">Status</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody id="teste" *ngIf="eleicoes.length > 0">
      <tr
        *ngFor="
          let eleicao of filteredEleicao
            | slice : (paginaAtual - 1) * size : (paginaAtual - 1) * size + size
        "
      >
        <td>{{ eleicao.nome }}</td>
        <td>{{ eleicao.dataInicio }} à {{ eleicao.dataFinal }}</td>
        <td>
          <div
            [ngClass]="{
              'tag-apro': eleicao.status,
              'tag-repro': !eleicao.status
            }"
            class="tag-apro"
          >
            {{ eleicao.status ? "ATIVO" : "INATIVA" }}
          </div>
        </td>
        <td>
          <div style="display: inline-flex">
            <i
              class="fas fa-search acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Visualizar"
              (click)="modalEleicao('view', eleicao)"
            ></i>
            <i
              class="fas fa-edit acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Editar"
              (click)="modalEleicao('edit', eleicao)"
            ></i>
            <i
              class="fas fa-users acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Gerenciar Chapas"
              (click)="openModalChapa(eleicao)"
            ></i>
            <i
              class="fas fa-user acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Selecionar Eleitores"
              (click)="selecionarEleitores(eleicao)"
            ></i>
            <!-- <i class="far fa-file-alt acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Procurações" (click)="submeterProcuracao(eleicao)"></i> -->
          </div>
        </td>
      </tr>
    </tbody>
    <p
      *ngIf="filteredEleicao.length <= 0"
      style="color: #ff0000; font-weight: bold"
    >
      • Não existem eleções cadastradas.
    </p>
  </table>
</div>
<div class="row">
  <div class="col-12" style="padding: 0; text-align: right">
    Quantidade de Eleições: {{ eleicoes.length }}
  </div>
</div>
<div class="row" style="justify-content: space-between">
  <select
    style="width: fit-content !important"
    class="form-control"
    type="text"
    name="qtdPage"
    id="qtdPage"
    [(ngModel)]="size"
    (change)="changeOp(size)"
  >
    <option *ngFor="let op of opcoes">{{ op }}</option>
  </select>
  <ngb-pagination
    class="d-flex justify-content-end"
    [collectionSize]="filteredEleicao.length"
    [(page)]="paginaAtual"
    [pageSize]="size"
    [maxSize]="2"
    [rotate]="true"
    [ellipses]="false"
    [boundaryLinks]="false"
    (pageChange)="changePage($event)"
    size="sm"
  ></ngb-pagination>
</div>
