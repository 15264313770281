<div class="modal-header">
    <h4 class="modal-title">Informações do Usuário</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-6">
            <h4>Nome</h4>
            <p>{{this.user.nome}}</p>
        </div>
        <div class="col-6">
            <h4>CPF/CNPJ</h4>
            <p>{{this.cpf_cnpj}}</p>
        </div>
        <div class="col-6">
            <h4>Email</h4>
            <p>{{this.user.email}}</p>
        </div>
        <div class="col-6">
            <h4>Tipo</h4>
            <p>{{this.tipoUsuario}}</p>
        </div>
        <div class="col-6">
            <h4>Login</h4>
            <p>{{this.user.login}}</p>
        </div>
    </div>
</div>





<div class="modal-footer ">
    <button type="button " class="btn btn-outline-dark " (click)="modal.close( 'Close click') ">Fechar</button>
</div>