import { Role } from '../../models/role.enum';
import { User } from '../../models/user';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HardCodeService } from 'src/app/services/hard-code.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-modal-edit-sindicato',
  templateUrl: './modal-edit-sindicato.component.html',
  styleUrls: ['./modal-edit-sindicato.component.css'],
})
export class ModalEditSindicatoComponent implements OnInit {
  @Input() public tipo;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  @Input() name;
  @BlockUI() blockUi: NgBlockUI;
  public user: User;

  constructor(
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService,
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    public hardCode: HardCodeService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {}
  ngOnInit(): void {
    if (this.item && this.item.obj) {
      this.user = this.item.obj;

      this.form = this.formBuilder.group({
        cpf_cnpj: [this.trataDocs.limpaDoc(this.user.cpf)],
        nome: [this.user.nome],
        email: [
          this.user.email,
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        tipo: [this.user.roles[0]],
        login: [this.user.login],
        password: [this.user.password],
      });
    } else {
      console.error('Item ou item.obj está undefined:', this.item);
    }
  }

  async atualizar() {
    this.submitted = true;
    if (this.form.valid) {
      this.blockUi.start('Atualizando os dados');
      try {
        let resp: any = {};
        let newUser = new User();

        newUser._id = this.user._id;
        newUser.nome = this.form.get('nome').value;
        newUser.email = this.form.get('email').value;
        newUser.cpf = this.trataDocs.limpaDoc(this.form.get('cpf_cnpj').value);
        let roles = [];
        roles.push(this.form.get('tipo').value);
        newUser.roles = null;
        newUser.roles = roles;
        newUser.login = this.form.get('login').value;

        resp = this.api.putGeneric(
          environment.urlBase + environment.paths.usuario,
          newUser
        );
        if (this.form.get('password').touched) {
          this.trocar(this.form.get('password').value);
        }
        this.blockUi.stop();
        resp.then((data) => {
          newUser = data.body.result;
          newUser._id = this.user._id;
          this.toaster.pop('success', 'SEVI', 'Usuário atualizado');
          this.modal.close(newUser);

          //    window.location.reload();
        });
      } catch (err) {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista abra um BPM.'
        );
        console.error('[[ERRO POST USUARIO]] => ', err);
      }
    }
  }

  private getTipo(): string {
    switch (this.user.roles[0]) {
      case Role.SIND:
        return 'Sindicato';
        break;

      default:
        break;
    }
  }

  async trocar(senha) {
    if (this.form.valid) {
      this.blockUi.start('Carregando');
      try {
        await this.api.postGeneric(
          environment.urlBase + environment.paths.usuarioAlteraSenha,
          { login: this.form.get('login').value, senha: senha }
        );
        this.toaster.pop('success', 'SEVI', 'Senha alterada com sucesso');
        this.blockUi.stop();
      } catch (err) {
        console.error('[[ERRO PUT SENHA]] => ', err);
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista avise a equipe do edital.'
        );
        this.blockUi.stop();
      }
    }
  }

  private async trocarSenha(senha) {
    let user = {
      login: this.user.login,
      senha: senha,
    };

    try {
      await this.api.postGeneric(
        environment.urlBase + environment.paths.usuarioAlteraSenha,
        JSON.stringify(user)
      );
      this.toaster.pop(
        'success',
        'SEVI',
        'Senha alterada com sucesso, logue novamente na plataforma'
      );
      this.authService.logout();
    } catch (err) {
      console.error('[[ERRO PUT SENHA]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista avise a equipe do edital.'
      );
      this.blockUi.stop();
    }
  }
}
