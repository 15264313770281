import { ModalCorrecaoSindComponent } from './../modal-correcao-sind/modal-correcao-sind.component';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { UtilService } from 'src/app/services/util.service';

import * as XLSX from 'xlsx';
import * as alasql from 'alasql';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/models/role.enum';

declare var $: any;


@Component({
  selector: 'app-modal-upload-sind',
  templateUrl: './modal-upload-sind.component.html',
  styleUrls: ['./modal-upload-sind.component.css']
})
export class ModalUploadSindComponent implements OnInit {

  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public sindicatos = [];
  public sindicatosSalvos = [];
  public sindicatosComFalha = [];
  public sindicatosNaoImportados = [];
  public sindicatosExistentes = [];

  constructor(public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    public globals: GlobalsService,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService) { }

  ngOnInit(): void {
  }

  public async downloadEspelho() {
    let espelho = [{
      nome: "Exemplo",
      cnpj: "00000000000000",
      email: "sindicato@exemplo.com",
      cep: "00000000",
      endereco: "Ex.: Rua A",
      estado: "Ex.: Amazonas",
      cidade: "Ex.: Manaus",
      bairro: "Ex.: Parque 10",
      telefone: "(00) 00000-0000",
      celular: "(00) 00000-0000",
      responsavel: "Exemplo"
    }]
    await alasql.default(`SELECT * INTO XLSX("Espelho da Planilha para Upload de Sindicatos.xlsx", {headers: true}) FROM ?`, [espelho, "String"]);
  }

  onFileChange(ev) {
    //this.blockUi.start("Processando Planilha")
    this.sindicatos = [];
    this.sindicatosComFalha = [];
    let workBook = null;
    let jsonData = null;
    let sheetName = ""
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        sheetName = name
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, { blankrows: false, defval: null });
        return initial;



      }, {});

      for (let i = 0; i < jsonData[sheetName].length; i++) {
        if (this.trataDocs.validaCNPJ(jsonData[sheetName][i].cnpj.toString())) {
          this.sindicatos.push({
            nome: jsonData[sheetName][i].nome,
            cnpj: jsonData[sheetName][i].cnpj,
            email: jsonData[sheetName][i].email,
            cep: jsonData[sheetName][i].cep,
            endereco: jsonData[sheetName][i].endereco,
            estado: jsonData[sheetName][i].estado,
            cidade: jsonData[sheetName][i].cidade,
            bairro: jsonData[sheetName][i].bairro,
            telefone: jsonData[sheetName][i].telefone,
            celular: jsonData[sheetName][i].celular,
            responsavel: jsonData[sheetName][i].responsavel,
            ativo: true
          });
          this._checkCadastro(jsonData[sheetName][i]);
        } else {
          this.sindicatosComFalha.push({
            nome: jsonData[sheetName][i].nome,
            cnpj: jsonData[sheetName][i].cnpj,
            email: jsonData[sheetName][i].email,
          });
        }
      }
    }
    $("#my-file-selector").val(null);
    $("#upload-file-info").val(null);
    reader.readAsBinaryString(file);
  }

  private async _checkCadastro(sind) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.sindicatosCnpj + `/${this.trataDocs.limpaDoc(sind.cnpj)}`)
      this.sindicatosExistentes.push(resp.body.documento);
    } catch (err) {
      console.error("[[ERRO GET SINDICATO]] => ", err)
    }
  }

  salvar() {
    if (this.sindicatos.length > 0) {
      this.blockUi.start("Importando Sindicatos")

      // this.sindicatos.forEach(async sind => {
      //    let e = sind;
      //    let index = null;
      //    e.cnpj = this.trataDocs.limpaDoc(e.cnpj);
      //    try {
      //      if (this.sindicatosExistentes.findIndex(ee => ee.cnpj === e.cnpj) < 0) {
      //        await this._gerarLogin({
      //          nome: e.nome,
      //          cnpj: e.cnpj,
      //          email: e.email,
      //          status: true,
      //        });
      //      } else {
      //        index = this.sindicatosExistentes.findIndex(ee => ee.cnpj === e.cnpj);
      //        let sExiste = this.sindicatosExistentes[index];

      //      }
      //     } catch (err) {
      //      console.error("[[ERRO POST SINDICATO]] => ", err);
      //      this.sindicatosNaoImportados.push(e);
      //    }
      // });

      const resp = this.api.postGeneric(environment.urlBase + environment.paths.sindicatosUpload, this.sindicatos);
      resp.then(data => {
        console.log(data.body.responseList);
        this.sindicatosSalvos = data.body.responseList.success;
        this.blockUi.stop();
        this.toaster.pop("success", "SEVI", "Sindicatos importados com sucesso.");
        this.modal.close(this.sindicatosSalvos);
      });



    } else {
      this.toaster.pop("error", "SEVI", "Não existem sindicatos a serem importados.");
    }
  }

  private async _gerarLogin(sind) {
    try {
      // await this.api.postGeneric(environment.urlBase + environment.paths.usuario, {
      //   "nome": eleitor.nome,
      //   "email": eleitor.email,
      //   "cpf_cnpj": this.trataDocs.limpaDoc(eleitor.cpf),
      //   "role": Role.ELEITOR
      // });
      this.salvarSind(sind, 'new');
    } catch (err) {
      console.error("[[ERRO CADASTRA USUÁRIO]] => ", err)
      if (err.error.statusCode === 422) {
        this._addRole(sind);
      } else {
        this.blockUi.stop();
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      }
    }
  }

  private async _addRole(sind) {
    try {
      await this.api.postGeneric(environment.urlBase + environment.paths.usuario + `/${this.trataDocs.limpaDoc(sind.cnpj)}/role/${Role.SIND}`, {})
      this.salvarSind(sind, 'edit');
    } catch (err) {
      console.error("[[ERRO ADD ROLE]] => ", err)
      if (err.error.statusCode !== 404) {
        this.blockUi.stop();
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      } else {
        if(this.sindicatosExistentes.findIndex(ee => ee.cnpj === sind.cnpj) < 0){
          this.salvarSind(sind);
        } else {
          this.salvarSind(sind, 'edit');
        }
      }
    }
  }

  private async salvarSind(sind, tipo?) {
    console.log(sind);
    try {
      sind.cnpj = this.trataDocs.limpaDoc(sind.cnpj);
      if (tipo === "edit") {
        const resp = await this.api.putGeneric(environment.urlBase + environment.paths.sindicatos + `/${sind._id}`, sind)
        sind._id = resp.body.documento._id;
      } else {
        const resp = await this.api.postGeneric(environment.urlBase + environment.paths.sindicatos, sind)
        sind._id = resp.body.documento._id;
        this.sindicatosSalvos.push(resp.body.documento);
      }
    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO CADASTRO SINDICATO]]", err)
    }
  }

  openModalCorrecao(sind) {
    let index = this.sindicatosComFalha.findIndex(e => e === sind);
    const modalRef = this.ngbModal.open(ModalCorrecaoSindComponent, { size: 'md', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      obj: sind
    }
    modalRef.result.then(res => {
      if (res) {
        this.sindicatos.push(res);
        this._checkCadastro(res)
        this.sindicatosComFalha.splice(index, 1);
      }
    });
  }





}
