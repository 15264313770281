import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';

moment.locale('pt-br');


@Component({
  selector: 'app-modal-votacao-chapa',
  templateUrl: './modal-votacao-chapa.component.html',
  styleUrls: ['./modal-votacao-chapa.component.css']
})
export class ModalVotacaoChapaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  private checkVotos: any = [];

  public chapas = [];
  public chapaSelecionada = null;
  public tipos: any = []
  public liberarVoto = true;
  public selecaoVoto = true;
  public votoComputado = false;
  public habilitarVotacao = false;


  constructor(
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService,
    private trataDatas: TrataNumeros

  ) { }

  ngOnInit(): void {
    this._checkVotos(this.item.eleicao._id, this.item.eleitorId);
    this.checkVoto(this.item.eleicao._id, this.item.eleitorId);
    this._checkInfoEleicao();
  }

  private async checkVoto(idEleicao, idEleitor) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${idEleicao}&idEleitor=${idEleitor}`)
      let r = resp.body.documento;
      if (r.length > 0) {
        this.votoComputado = true;
      }
    } catch (err) {
      this.blockUi.stop();
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO GET ELEITOR]] => ", err);
    }
  }

  private async _getChapas(idEleicao) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.chapas + `?idEleicao=${idEleicao}`);
      if (resp.body.documento.length > 0) {
        this.chapas = resp.body.documento.filter(c => c.status === true);
        this.chapas.push(
          {
            _id: "em_branco",
            nomeChapa: "EM BRANCO"
          },
          {
            _id: "nulo",
            nomeChapa: "NULO"
          }
        )
      }
    } catch (err) {
      console.error("[[ERRO GET CHAPAS]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
    }
  }

  public async selectChapa(chapa) {
    this.chapaSelecionada = chapa;
    this._checkInfoEleicao();
  }

  public reset() {
    this.selecaoVoto = true
    this.chapaSelecionada = null;
  }

  public async selectTipo(tipo) {
    this.selecaoVoto = false
  }


  private async _checkInfoEleicao() {
    if (this.trataDatas.validaDataHoraMenor(moment().format("DD/MM/YYYY HH:mm"), this.item.eleicao.dataInicio)) {
      this.habilitarVotacao = false;
      return 'PERÍODO DE VOTAÇÃO NÃO INICIADO';
    } else {
      if (this.trataDatas.validaDataHoraMaiorOuIgual(moment().format("DD/MM/YYYY HH:mm"), this.item.eleicao.dataInicio) && this.trataDatas.validaDataHoraMenorOuIgual(moment().format("DD/MM/YYYY HH:mm"), this.item.eleicao.dataFinal)) {
        this.habilitarVotacao = true;
        return 'PERÍODO DE VOTAÇÃO ABERTO';
      } else {
        this.habilitarVotacao = false;

        return 'PERÍODO DE VOTAÇÃO FECHADO';
      }
    }
  }

  private async _checkVotos(idEleicao, idEleitor) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${idEleicao}&idEleitor=${idEleitor}`)
      this.checkVotos = resp.body.documento;
      this._getChapas(idEleicao)
      if (this.item.eleicao.votoEleitor && this.checkVotos.filter(c => c.idEleicao === idEleicao && c.idProcuracao === undefined).length <= 0) {
        this.tipos.push({ _id: "null", label: "Exercer meu voto" })
      }
      // else {
      //   this.tipos.push({ _id: "null", label: "" })
      // }
    } catch (err) {
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO CHECK VOTO]] => ", err);
    }
  }

  public async votar() {
    const modalRef = this.ngbModal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      title: "Confirmação de Voto",
      msg: `Você confirma o seu voto ${this.chapaSelecionada._id ? `para a chapa ${this.chapaSelecionada.nomeChapa}` : "EM BRANCO?"}`
    }
    modalRef.result.then(async res => {
      if (res) {
        try {

          await this.api.postGeneric(environment.urlBase + environment.paths.urnaEleicoes + `/${this.item.eleitorId}`, {
            "idEleicao": this.item.eleicao._id,
            "voto": this.chapaSelecionada._id ? this.chapaSelecionada._id : ""
          });

          this.liberarVoto = this.tipos.length > 0;
          if (this.tipos.length > 0) {
            this.reset();
            this.toaster.pop("success", "SEVI", "Voto realizado com sucesso.");
            let audio = new Audio("./assets/urna.mp3");
            audio.play();
            this.modal.close(true)
          } else {
            this.toaster.pop("success", "SEVI", "Voto realizado com sucesso.");
            let audio = new Audio("./assets/urna.mp3");
            audio.play();
            this.modal.close(true)
          }

        } catch (err) {
          console.error("[[ERRO POST VOTO]] => ", err);
          this.toaster.pop("error", "SEVI", "Período de votaçaõ fechado!");
        }
      }
    })
  }
}
