<div [id]="'cid'">
    <div id="div-chart" style="height: 700px;">
        <ngx-charts-bar-vertical [scheme]="colorScheme" [legend]="legend" [legendTitle]="legendTitle" [legendPosition]="legendPosition" [xAxis]="xAxis" [yAxis]="yAxis" [trimXAxisTicks]="trimXAxisTicks" [results]="data" [showDataLabel]="showDataLabel" [yAxisTicks]="yAxisTicks"
            [barPadding]="barPadding" [view]="[0, 600]" [xAxisTickFormatting]="setLabelFormatting" [showXAxisLabel]="true" [xAxisLabel]="title">
            <ng-template #tooltipTemplate let-model="model">
                <div style="padding: 10px;">
                    <div style="font-family: panton-bold;">
                        {{ model.name }}
                    </div>
                    <div>
                        {{ model.value }} Voto(s)
                    </div>
                </div>
            </ng-template>
        </ngx-charts-bar-vertical>
    </div>
    <!-- <div class="chart-acoes">
        <p class="chart-label">{{legend ? 'Ocultar Data Label' : 'Mostrar Data Label'}}</p>
        <label class="switch" style="margin-right: 10px;">
            <input type="checkbox" [(ngModel)]="showDataLabel">
            <span class="slider round"></span>
        </label>
        <p class="chart-label">{{legend ? 'Ocultar Legendas' : 'Mostrar Legendas'}}</p>
        <label class="switch">
            <input type="checkbox" [(ngModel)]="legend">
            <span class="slider round"></span>
        </label>
    </div> -->
</div>