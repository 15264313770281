// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url hml
  urlBase: 'https://sevi-backend.hml.fiesc.com.br/api/',
  // urlBase: 'http://localhost:5050/api/',
  paths: {
    login: 'login',
    usuario: 'usuario',
    usuario_login: 'usuario',
    usuarioAlteraSenha: 'usuario/alterarsenha',
    sindicatos: 'sindicatos',
    sindicatosUpload: 'sindicatos/upload',
    eleitoresUpload: 'eleitores/upload/',
    sindicatosCnpj: 'sindicatos/cnpj',
    eleicoes: 'eleicoes',
    apuracao: 'eleicoes/apuracao',
    chapas: 'chapas',
    eleitores: 'eleitores',
    eleitoresCpf: 'eleitores/cpf',
    urnaEleicoes: 'urnas',
    urnaAssembleia: 'urnas/assembleias',
    controleVotos: 'controleVotos',
    procuracoes: 'procuracoes',
    upload: 'upload',
    assembleias: 'assembleias',
    pautas: 'pautas',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
