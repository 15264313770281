<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Correção de Dados</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">

        <div class="row">
            <div class="add-margin-top col-lg-4 col-md-6 col-12">
                <label class="labels">CNPJ<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="cnpj" formControlName="cnpj" [ngClass]="{'invalido':form.get('cnpj').invalid && submitted}" [textMask]="{mask: globals.masks.cnpj, guide: false}" (blur)="verificaCnpj(this.form.get('cnpj').value)">
                <p class="erro" *ngIf="form.get('cnpj').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-lg-8 col-md-6 col-12">
                <label class="labels">Nome do Sindicato<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome" [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
        </div>
        <div class="row">
            <div class="add-margin-top col-lg-4 col-md-6 col-12">
                <label class="labels">CEP</label>
                <input class="form-control input-round" type="text" id="cep" formControlName="cep" [textMask]="{mask: globals.masks.cep, guide: false}" (blur)="verificaEndereco(this.form.get('cep').value)">
            </div>
            <div class="add-margin-top col-lg-8 col-md-6 col-12">
                <label class="labels">Endereço</label>
                <input class="form-control input-round" type="text" id="endereco" formControlName="endereco">
            </div>
            <div class="add-margin-top col-lg-4 col-md-6 col-12">
                <label class="labels">Bairro</label>
                <input class="form-control input-round" type="text" id="bairro" formControlName="bairro">
            </div>
            <div class="add-margin-top col-lg-4 col-md-6 col-12">
                <label class="labels">Cidade</label>
                <input class="form-control input-round" type="text" id="cidade" formControlName="cidade">
            </div>
            <div class="add-margin-top col-lg-4 col-md-12 col-12">
                <label class="labels">Estado (UF)</label>
                <select class="form-control input-round" type="text" id="uf" formControlName="uf" role="textbox">
                <option *ngFor="let estado of hardCode.estados" [value]="estado.sigla"> {{estado.label}}
                    {{estado.sigla ? '(' + estado.sigla + ')' : ""}} </option>
            </select>
            </div>
        </div>
        <div class="row">
            <div class="add-margin-top col-lg-4 col-md-4 col-12">
                <label class="labels">Nome do Responsável</label>
                <input class="form-control input-round" type="text" id="responsavel" formControlName="responsavel">
            </div>
            <div class="add-margin-top col-lg-4 col-md-4 col-12">
                <label class="labels">Telefone Fixo</label>
                <input class="form-control input-round" type="text" id="tel" formControlName="tel" [textMask]="{mask: globals.masks.tel, guide: false}">
            </div>
            <div class="add-margin-top col-lg-4 col-md-4 col-12">
                <label class="labels">Celular</label>
                <input class="form-control input-round" type="text" id="cel" formControlName="cel" [textMask]="{mask: globals.masks.tel, guide: false}">
            </div>
            <div class="add-margin-top col-12">
                <label class="labels">E- mail<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="email" formControlName="email" [ngClass]="{'invalido':form.get('email').invalid && submitted}">
                <p class="erro" *ngIf="form.get('email').invalid && submitted">Campo Obrigatório ou o formato de e-mail é inválido</p>
            </div>
        </div>
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Status do Sindicato</label>
                <label class="switch">
                <input type="checkbox" formControlName="ativo">
                <span class="slider round" [ngClass]="{sliderDisabled: item.tipo === 'view'}">
                    <p class="label-switch" [ngClass]="{'label-switch-padding-right': form.get('ativo').value, 'label-switch-padding-left': !form.get('ativo').value}">
                        {{form.get('ativo').value ? 'ATIVO' : 'INATIVO'}}
                    </p>
                </span>
            </label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" (click)="salvar()">Salvar</button>
</div>