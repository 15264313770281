<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Troca de Senha</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12" style="text-align: justify;">
                <p *ngIf="item.primeroAcesso">Este é o seu primeiro acesso e sugerimos que você efetue a troca de sua senha. Caso cancele esta operação o sistema entenderá que você optou por permanecer com a senha recebida em seu e-mail e não irá mais apresentar esta mensagem após
                    o login.</p>
                <p *ngIf="item.primeroAcesso">Caso opte por trocar sua senha em outro momento, você poderá efetuar a ação acessando o menu ao clicar em seu nome ou de sua empresa no canto superior direito de sua tela a qualquer momento.</p>
                <p>Ao efetuar a troca de sua senha, você retornará a tela de login e deverá realizar um novo acesso informando sua nova senha.</p>
            </div>
            <div class="col-6">
                <label>Senha</label><span class="campo-required"> *</span>
                <input class="form-control" type="password" id="senha" name="senha" formControlName="senha" role="textbox" autocomplete="off" [ngClass]="{invalido: form.get('senha').invalid && form.get('senha').dirty}">
            </div>
            <div class="col-6">
                <label>Confirmar Senha</label><span class="campo-required"> *</span>
                <input class="form-control" type="password" id="senhaConf" name="senhaConf" formControlName="senhaConf" Frole="textbox" autocomplete="off" [ngClass]="{invalido: form.get('senhaConf').invalid && form.get('senhaConf').dirty}">
            </div>
            <div class="acoes col-12">
                <label class="acao" (click)="exibirSenhas()">{{ocultarSenha ? 'Exibir senhas' : 'Ocultar senhas'}}</label>
            </div>
            <div class="col-12">
                <p class="erro" *ngIf="(form.get('senha').invalid) || (form.get('senhaConf').invalid)">
                    • A senha deve possuir no mínimo 8 caracteres e pelo menos uma letra e um número.
                </p>
                <p class="erro" *ngIf="(form.get('senha').value !== form.get('senhaConf').value)">
                    • As senhas não coincidem.
                </p>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" (click)="trocar()">Trocar Senha</button>
</div>