import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public form: FormGroup;
  public submitted: boolean = false;
  public ocultarSenha = true;
  private user;

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private authService: AuthenticationService,
  ) {
    this.user = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: [this.user.nome.replace(" ", ".")],
      password: [null, Validators.required],
    });
    this.form.get('user').disable();
  }

  async login() {
    this.submitted = true;
    if (this.form.valid) {
      this.blockUi.start("Logando")
      try {
        const resp = await this.authService.login(this.form.get('user').value, this.form.get('password').value);
        this.blockUi.stop();
        this.modal.close()
      } catch (err) {
        this.blockUi.stop();
      }
    }
  }

  logout() {
    this.authService.logout();
  }


  exibirSenha() {
    if ($("#password").attr("type") === "password") {
      document.getElementById("password").setAttribute("type", "text")
      this.ocultarSenha = false;
    } else {
      document.getElementById("password").setAttribute("type", "password")
      this.ocultarSenha = true;
    }
  }


}
