<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Gerenciamento das Chapas da Eleição</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row col-12" style="justify-content: space-between; align-items: center;">
        <h3 class="titulo">Chapas</h3>
        <button type="button" class="btn btn-outline-success" (click)="openModalChapa('new')"><i
                class="fas fa-plus"></i> Adicionar Chapa</button>
    </div>
    <div class="row col-12">
        <div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Chapa</th>
                        <th style="width: 150px;" class="th-rigth-border">Status</th>
                        <th style="width: 150px;">Ações</th>

                    </tr>
                </thead>
                <tbody id="teste" *ngIf="chapas.length > 0">
                    <tr *ngFor="let c of chapas">
                        <td>{{ c.nomeChapa }}</td>
                        <td>
                            <div *ngIf="c.status" class="tag-apro">
                                ATIVO
                            </div>
                            <div *ngIf="!c.status" class="tag-repro">
                                INATIVO
                            </div>
                        </td>
                        <td>
                            <div style="display: inline-flex;">
                                <i class="fas fa-search acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Visualizar" (click)="openModalChapa('view', c)"></i>
                                <i class="fas fa-edit acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Editar" (click)="openModalChapa('edit', c)"></i>
                                <i class="far fa-trash-alt acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Excluir" (click)="excluir(c._id)"></i>
                                <i class="fas fa-ban acoes" *ngIf="c.status" style="padding-left: 5px" placement="bottom" ngbTooltip="Inativar Chapa" (click)="changeStatus(c)"></i>
                                <i class="fas fa-check acoes" *ngIf="!c.status" style="padding-left: 5px" placement="bottom" ngbTooltip="Ativar Chapa" (click)="changeStatus(c)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <p *ngIf="chapas.length <= 0" style="color: #ff0000; font-weight: bold;">• Não existem chapas cadastradas.
                </p>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
</div>