import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToasterService} from 'angular2-toaster';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ApiService} from 'src/app/services/api.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {TrataDocsService} from 'src/app/services/trata-docs.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-lista-presenca',
  templateUrl: './lista-presenca.component.html',
  styleUrls: ['./lista-presenca.component.css']
})
export class ListaPresencaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public nomeSind = '';
  public listaVotantes = [];
  public eleicao;
  public qtdPresenca = 0;

  private procuracoes = [];
  private eleitores = [];
  private controleVotos = [];

  constructor(
    private toaster: ToasterService,
    private api: ApiService,
    private auth: AuthenticationService,
    private trataDocs: TrataDocsService,
    private acRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.nomeSind = this.auth.currentUserValue.nomeCompleto;
    this._getEleicao(this.acRoute.snapshot.paramMap.get('eleicaoId'));
  }

  private async _getEleicao(id) {
    this.blockUi.start('Carregando a Lista');
    try {
      const resp = await this.api.getGenericId(environment.urlBase + environment.paths.eleicoes, id);
      // const respP = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idEleicao=${id}`);
      this.eleicao = resp.body.documento;
      // this.procuracoes = respP.body.documento;
      this._getEleitores(this.eleicao);
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET ELEIÇÃO]] => ', err);
      this.toaster.pop('error', 'SEVI', 'Erro interno, tente novamente e caso o erro persista avise os administradores.');
    }
  }

  private preProcess(eleicao) {
    this.eleitores.map((item) => {
      const instituicao = item.instituicao;
      instituicao.forEach((data, index) => {
        if (data.id === eleicao.idSindicato) {
          item.index = index;
        }
      });
    });
    console.log(eleicao.idSindicato, this.eleitores);
  }

  private async _getEleitores(eleicao) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + `${environment.paths.eleitores}?sindicatos=${eleicao.idSindicato}`);
      this.eleitores = resp.body.documento;
      this.preProcess(eleicao);
      const respVoto = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${eleicao._id}`);
      this.controleVotos = respVoto.body.documento;
      if (this.eleitores.length > 0) {
        this.eleitores.forEach(async eleitor => {
          if (eleicao.eleitores.includes(eleitor._id)) {
            try {
              this.listaVotantes.push({
                entidade: eleitor.instituicao[eleitor.index].empresa,
                nome: eleitor.nome,
                cpf: this.trataDocs.addMaskCpf(eleitor.cpf),
                presenca: this.controleVotos.length <= 0 ? 'NC' : this.controleVotos.filter(cv => cv.idEleitor === eleitor._id && cv.idProcuracao === undefined).length > 0 ? 'C' : 'NC'
              });
              this.listaVotantes = this.listaVotantes.sort((a, b) => a['nome'].toUpperCase().localeCompare(b['nome'].toUpperCase()));
            } catch (err) {
              this.blockUi.stop();
              console.error('[[ERRO GET ELEITORES]] => ', err);
            }
          }
        });

        this.qtdPresenca = this.listaVotantes.filter(lv => lv.presenca === 'C').length;

        this.blockUi.stop();
      } else {
        this.toaster.pop('error', 'SEVI', 'Não existem eleitores selecionados nesta eleição.');
        this.blockUi.stop();
      }
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET ELEITORES]] =>', err);
      this.toaster.pop('error', 'SEVI', 'Erro interno, tente novamente e caso o erro persista avise os administradores.');
    }
  }

  private async _getEleitoresRepresentados() {
    this.procuracoes.forEach(async p => {
      let index = this.eleitores.findIndex(e => e._id === p.idRepresentado);
      this.listaVotantes.push({
        entidade: this.eleitores[index].empresa,
        nome: this.eleitores[index].nome,
        cpf: this.trataDocs.addMaskCpf(this.eleitores[index].cpf),
        presenca: this.controleVotos.filter(cv => cv.idProcuracao === p._id).length > 0 ? 'R' : 'RNC'
      });
      this.listaVotantes = this.listaVotantes.sort((a, b) => a['nome'].toUpperCase().localeCompare(b['nome'].toUpperCase()));
      this.qtdPresenca = this.listaVotantes.filter(lv => lv.presenca === 'C' || lv.presenca === 'R').length;
    });
  }

  print() {
    window.print();
  }

}
