<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Procurações
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row col-12" style="justify-content: center;">
        <!-- <button type="button" class="btn btn-outline-success" (click)="addProcuracao()"><i class="fas fa-plus"></i>
            Adicionar Procuração</button> -->
    </div>
    <div class="row col-12">
        <div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Procuração</th>
                        <th class="th-rigth-border">Ações</th>
                    </tr>
                </thead>
                <tbody id="teste" *ngIf="procuracoes.length > 0">
                    <tr *ngFor="let p of procuracoes">
                        <td>{{ p.nomeEleitor }} - Representando {{p.nomeRepresentado ? p.nomeRepresentado : "-"}} pela empresa {{p.empresa}}</td>
                        <td>
                            <div style="display: inline-flex;">
                                <i class="fas fa-file-download acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Download da procuração" (click)="download(p.doc)"></i>
                                <i class="far fa-trash-alt acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Excluir procuração" (click)="deleteProcuracao(p)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <p class="erro" *ngIf="procuracoes.length <= 0">Não existem procurações cadastradas</p>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
</div>
