import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HardCodeService } from 'src/app/services/hard-code.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-correcao-sind',
  templateUrl: './modal-correcao-sind.component.html',
  styleUrls: ['./modal-correcao-sind.component.css']
})
export class ModalCorrecaoSindComponent implements OnInit {

  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService,
    public hardCode: HardCodeService,

    ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cnpj: [null, Validators.required],
      nome: [null, Validators.required],
      endereco: [null],
      uf: [null],
      cidade: [null],
      bairro: [null],
      cep: [null],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      tel: [null],
      cel: [null],
      responsavel: [null],
      ativo: [true, Validators.required]
    });

    this.form.patchValue(this.item.obj);

  }

  async verificaCnpj(cnpj) {
    if (this.trataDocs.validaCNPJ(cnpj)) {
      try {
        const resp = await this.api.getGeneric(environment.urlBase + environment.paths.sindicatosCnpj + `/${this.trataDocs.limpaDoc(cnpj)}`);
        if(resp.body.documento){
          this.form.patchValue({ cnpj: null });
          this.toaster.pop("error", "SEVI", "O CNPJ informado já possui cadastro na plataforma.");
        }
      } catch (err) {
        if(err.error.statusCode !== 404 ) {
          console.error("[[ERRO GET SINDICATO]] => ", err)
        }
      }
    } else {
      this.form.patchValue({ cnpj: null });
      this.toaster.pop("error", "SEVI", "CNPJ inválido, verifique o mesmo e tente novamente.");
    }
  }

  async verificaEndereco(cep) {
    if (cep.length === 9) {
      try {
        this.blockUi.start("Buscando endereço")
        const resp = await this.api.viaCep(cep);
        if (!resp.error) {
          this.form.patchValue({
            endereco: resp.logradouro,
            uf: resp.uf,
            cidade: resp.localidade,
            bairro: resp.bairro
          });
        } else {
          this.form.patchValue({
            endereco: null,
            uf: null,
            cidade: null,
            bairro: null
          });

        }
        this.blockUi.stop()
      } catch (err) {
        this.blockUi.stop()
        console.error("[[ERRO GET VIA CEP]] => ", err)
      }
    } else {
      this.form.patchValue({
        cep: null,
        endereco: null,
        uf: null,
        cidade: null,
        bairro: null
      });
      this.toaster.pop("error", "SEVI", "CEP inválido, verifique o mesmo e tente novamente.");
    }
  }

  salvar() {
    this.submitted = true;
    if (this.form.valid && this.trataDocs.validaCNPJ(this.form.get('cnpj').value)) {
      this.modal.close(this.form.getRawValue());
    } else {
      this.verificaCnpj(this.form.get('cnpj').value);
    }
  }


}
