import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import * as moment from 'moment';
moment.locale('pt-br');

@Component({
  selector: 'app-modal-eleicoes',
  templateUrl: './modal-eleicoes.component.html',
  styleUrls: ['./modal-eleicoes.component.css'],
})
export class ModalEleicoesComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private util: UtilService,
    private api: ApiService,
    private trataDatas: TrataNumeros
  ) {}

  ngOnInit(): void {
    console.log(this.item.idSindicato);
    this.form = this.formBuilder.group({
      idSindicato: [this.item.idSindicato],
      nome: [null, Validators.required],
      dataInicio: [
        this.item.obj ? this.item.obj.dataInicio.split(' ')[0] : null,
        Validators.required,
      ],
      horaInicio: [
        this.item.obj ? this.item.obj.dataInicio.split(' ')[1] : null,
        Validators.required,
      ],
      dataFinal: [
        this.item.obj ? this.item.obj.dataFinal.split(' ')[0] : null,
        Validators.required,
      ],
      horaFinal: [
        this.item.obj ? this.item.obj.dataFinal.split(' ')[1] : null,
        Validators.required,
      ],
      ordemDia: [''],
      eleitores: [[]],
      status: [true],
    });

    this.form.get('dataFinal').disable();
    if (this.item.tipo === 'view') {
      this.form.patchValue({
        idSindicato: this.item.obj.idSindicato,
        nome: this.item.obj.nome,
        eleitores: this.item.obj.eleitores,
        status: this.item.obj.status,
        ordemDia: this.item.obj.ordemDia,
      });
      this.form.disable();
    } else if (this.item.tipo === 'edit') {
      this.form.patchValue({
        idSindicato: this.item.obj.idSindicato,
        nome: this.item.obj.nome,
        eleitores: this.item.obj.eleitores,
        status: this.item.obj.status,
        ordemDia: this.item.obj.ordemDia,
      });
      this.form.get('dataFinal').enable();
    }
  }

  validaDataInicial(data) {
    if (this.util.checkVariavel(data)) {
      let dataSplit = data.split('/');
      if (
        data.length === 10 &&
        moment(`${dataSplit[1]}-${dataSplit[0]}-${dataSplit[2]}`).isValid()
      ) {
        const dataInicioMoment = moment(
          `${dataSplit[1]}-${dataSplit[0]}-${dataSplit[2]}`
        );
        if (dataInicioMoment.isSameOrAfter(moment(), 'day')) {
          this.form.get('dataFinal').enable();
        } else {
          this.toaster.pop(
            'error',
            'SEVI',
            'A data de início não pode ser anterior a data atual.'
          );
          this.form.patchValue({ dataInicio: null, dataFinal: null });
          this.form.get('dataFinal').disable();
        }
      } else {
        this.toaster.pop('error', 'SEVI', 'Data de início inválida.');
        this.form.patchValue({ dataInicio: null, dataFinal: null });
        this.form.get('dataFinal').disable();
      }
    } else {
      this.form.patchValue({ dataInicio: null, dataFinal: null });
      this.form.get('dataFinal').disable();
    }
  }

  validaDataFinal(data) {
    if (this.util.checkVariavel(data)) {
      let dataSplit = data.split('/');
      if (
        data.length === 10 &&
        moment(`${dataSplit[1]}-${dataSplit[0]}-${dataSplit[2]}`).isValid()
      ) {
        if (
          !this.trataDatas.validaDataMenorOuIgual(
            this.form.get('dataInicio').value,
            data
          )
        ) {
          this.toaster.pop(
            'error',
            'SEVI',
            'A data final deve ser igual ou maior que a data de início.'
          );
          this.form.patchValue({ dataFinal: null });
        }
      } else {
        this.toaster.pop('error', 'SEVI', 'Data final inválida.');
        this.form.patchValue({ dataFinal: null });
      }
    } else {
      this.form.patchValue({ dataFinal: null });
    }
  }

  validaHorario(hora, tipo) {
    if (this.util.checkVariavel(hora)) {
      if (hora.length === 5) {
        let horaSplit = hora.split(':');
        if (
          parseInt(horaSplit[0]) >= 0 &&
          parseInt(horaSplit[0]) < 24 &&
          parseInt(horaSplit[1]) >= 0 &&
          parseInt(horaSplit[1]) <= 59
        ) {
          return;
        } else {
          this.toaster.pop(
            'error',
            'SEVI',
            `Horário ${tipo === 'horaInicio' ? 'de início' : 'final'} inválido`
          );
          this.form.get(tipo).setValue(null);
          return;
        }
      } else {
        this.toaster.pop(
          'error',
          'SEVI',
          `Horário ${tipo === 'horaInicio' ? 'de início' : 'final'} inválido`
        );
        this.form.get(tipo).setValue(null);
        return;
      }
    } else {
      this.form.get(tipo).setValue(null);
      return;
    }
  }

  private _validaHorariosInicioFim() {
    const horaInicio = this.form.get('horaInicio').value;
    const horaFinal = this.form.get('horaFinal').value;
    const dataInicio = this.form.get('dataInicio').value;
    const dataFinal = this.form.get('dataFinal').value;

    if (horaInicio && horaFinal && dataInicio === dataFinal) {
      const [horaInicioHora, horaInicioMin] = horaInicio.split(':').map(Number);
      const [horaFinalHora, horaFinalMin] = horaFinal.split(':').map(Number);
      if (horaInicioHora === horaFinalHora && horaInicioMin > horaFinalMin) {
        this.toaster.pop(
          'error',
          'SEVI',
          'O horário de início não pode ser superior ao horário final se os minutos forem maiores.'
        );
        this.form.patchValue({ horaInicio: null, horaFinal: null });
      }

      if (horaInicioHora > horaFinalHora && dataInicio === dataFinal) {
        this.toaster.pop(
          'error',
          'SEVI',
          'O horário de início não pode ser superior ao horário final.'
        );
        this.form.patchValue({ horaInicio: null, horaFinal: null });
      }
     }
  }

  async salvar() {
    this.submitted = true;
    this._validaHorariosInicioFim();
    if (this.form.valid) {
      this.blockUi.start('Salvando os dados');
      try {
        let resp: any = {};
        let eleicao = this.form.getRawValue();
        eleicao.dataInicio = `${eleicao.dataInicio} ${eleicao.horaInicio}`;
        eleicao.dataFinal = `${eleicao.dataFinal} ${eleicao.horaFinal}`;
        if (this.item.obj) {
          resp = await this.api.putGeneric(
            environment.urlBase +
              environment.paths.eleicoes +
              `/${this.item.obj._id}`,
            eleicao
          );
          eleicao._id = this.item.obj._id;
          this.blockUi.stop();
          this.modal.close(eleicao);
        } else {
          resp = await this.api.postGeneric(
            environment.urlBase + environment.paths.eleicoes,
            eleicao
          );
          this.blockUi.stop();
          eleicao._id = resp.body.documento._id;
          this.modal.close(eleicao);
        }
      } catch (err) {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista abra um BPM.'
        );
        console.error('[[ERRO POST SINDICATO]] => ', err);
      }
    }
  }
}
