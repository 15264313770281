<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{item.tipo === "edit" ? "Edição de" : item.tipo === "view" ? "" : "Nova"}} Assembleia</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <label class="labels">Nome da Assembleia<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome"
                    [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-12">
                <label class="labels">Tipo<span class="campo-required"> *</span></label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="ordinaria" formControlName="ordinaria">
                    <label class="form-check-label" for="ordinaria">Ordinária</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="extraordinaria"
                        formControlName="extraordinaria">
                    <label class="form-check-label" for="extraordinaria">Extraordinária</label>
                </div>

            </div>
            <div class="col-12">
                <label class="labels">Ordem do Dia</label>
                <textarea name="descricao" id="descricao" rows="5" formControlName="descricao"
                    style="width: 100%;"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-12" style="display: flex; justify-content: space-between; align-items: center;">
                <p class="titulo" style="font-weight: bold;font-size: 20px;">Horários (Convocações)</p>
                <button *ngIf="item.tipo !== 'view'" class="btn btn-outline-success" (click)="addChamada('new')"><i class="fas fa-plus"></i>
                    Adicionar Horário (Convocação)</button>
            </div>
            <div class="col-12" *ngIf="chamadas.length <= 0">
                <p class="erro">Não existem chamadas nesta assembleia</p>
            </div>
            <div class="col-12" *ngIf="chamadas.length > 0">
                <table>
                    <thead>
                        <tr>
                            <th class="th-rigth-border">Convocação</th>
                            <th class="th-rigth-border">Período</th>
                            <th class="th-rigth-border">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of chamadas">
                            <td>{{c.chamada }}</td>
                            <td>{{c.dataInicio}} à {{c.dataFim}}</td>
                            <td>
                                <i *ngIf="item.tipo !== 'view'" class="fas fa-edit acoes" style="padding-left: 5px" placement="bottom"
                                    ngbTooltip="Editar" (click)="addChamada('edit', c)"></i>
                                <i *ngIf="item.tipo !== 'view'" class="far fa-trash-alt acoes" placement="bottom" ngbTooltip="Excluir"
                                    (click)="delChamada(c)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Status da Assembleia</label>
                <label class="switch">
                    <input type="checkbox" formControlName="ativa">
                    <span class="slider round" [ngClass]="{sliderDisabled: item.tipo === 'view'}">
                        <p class="label-switch"
                            [ngClass]="{'label-switch-padding-right': form.get('ativa').value, 'label-switch-padding-left': !form.get('ativa').value}">
                            {{form.get('ativa').value ? 'ATIVA' : 'INATIVA'}}
                        </p>
                    </span>
                </label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">{{item.tipo === "view" ? "Fechar"
        : "Cancelar"}}</button>
    <button type="button" class="btn btn-outline-success" *ngIf="item.tipo !== 'view'"
        (click)="salvar()">Salvar</button>
</div>