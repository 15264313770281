import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TrataNumeros {
  constructor() {}

  retornaIdade(dataNascimento) {
    if (
      dataNascimento !== null &&
      dataNascimento !== undefined &&
      dataNascimento !== ''
    ) {
      let dataSplitNasc = dataNascimento.split('/');
      let dataSplitAtual = moment().format('L').split('/');
      const date1 = new Date(
        dataSplitNasc[1] +
          '/' +
          dataSplitNasc[0] +
          '/' +
          dataSplitNasc[2].replace(' 00:00', '')
      );
      const date2 = new Date(
        dataSplitAtual[1] +
          '/' +
          dataSplitAtual[0] +
          '/' +
          dataSplitAtual[2].replace(' 00:00', '')
      );
      const diffTime = Math.abs(date2.getTime() - date1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return (diffDays / 365).toString().split('.')[0];
    } else {
      return null;
    }
  }

  retornaDiferencaDeDias(data1, data2) {
    let data1Split = data1.split('/');
    let data2Split = data2.split('/');
    const date1 = new Date(
      data1Split[1] +
        '/' +
        data1Split[0] +
        '/' +
        data1Split[2].replace(' 00:00', '')
    );
    const date2 = new Date(
      data2Split[1] +
        '/' +
        data2Split[0] +
        '/' +
        data2Split[2].replace(' 00:00', '')
    );
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  validaHora(hora) {
    if (hora !== null && hora !== '' && hora !== undefined) {
      if (hora.replace('_').length === 5) {
        let horaSplit = hora.split(':');
        if (parseInt(horaSplit[0]) <= 23 && parseInt(horaSplit[1]) <= 59) {
          return hora;
        } else {
          let d = new Date();
          let min =
            d.getMinutes() < 9
              ? '0' + d.getMinutes().toString()
              : d.getMinutes().toString();
          return d.getHours().toString() + ':' + min;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  formataHoraDoExcel(hora) {
    if (hora !== null && hora !== '' && hora !== undefined) {
      return moment(hora).add(6, 'minute').format('LT');
    } else {
      return null;
    }
  }

  quantidadeDeHoras(hora1, hora2) {
    let hora1Split = hora1.split(':');
    let hora2Split = hora2.split(':');
    if (
      parseInt(hora1Split[0]) <= 23 &&
      parseInt(hora1Split[1]) <= 59 &&
      parseInt(hora2Split[0]) <= 23 &&
      parseInt(hora2Split[1]) <= 59
    ) {
      let horas = (hora2Split[0] * 60 - hora1Split[0] * 60) / 60;
      let minutos = hora2Split[1] - hora1Split[1];
      if (minutos < 0) {
        horas -= 1;
        return (
          (horas < 9 ? '0' + horas : horas) +
          ':' +
          (60 + minutos < 9 ? '0' + (60 + minutos).toString() : 60 + minutos)
        );
      } else {
        return (
          (horas < 9 ? '0' + horas : horas) +
          ':' +
          (minutos < 9 ? '0' + minutos : minutos)
        );
      }
    } else {
      return '';
    }
  }

  quantidadeDeHoras24x7(hora1, hora2) {
    let hora1Split = hora1.split(':');
    let hora2Split = hora2.split(':');

    if (
      parseInt(hora1Split[0]) <= 23 &&
      parseInt(hora1Split[1]) <= 59 &&
      parseInt(hora2Split[0]) <= 23 &&
      parseInt(hora2Split[1]) <= 59
    ) {
      let horas = 0;
      let horaInicial = (hora1Split[0] * 60) / 60;
      let horaFinal = (hora2Split[0] * 60) / 60;
      let minutos = hora2Split[1] - hora1Split[1];
      if (horaInicial > horaFinal) {
        horas = 24 - horaInicial + horaFinal;
      } else {
        horas = horaFinal - horaInicial;
      }
      if (minutos < 0) {
        horas = horas === 0 ? 23 : horas - 1;
        return (
          (horas < 9 ? '0' + horas : horas) +
          ':' +
          (60 + minutos < 9 ? '0' + (60 + minutos).toString() : 60 + minutos)
        );
      } else {
        return (
          (horas < 9 ? '0' + horas : horas) +
          ':' +
          (minutos < 9 ? '0' + minutos : minutos)
        );
      }
    } else {
      return '';
    }
  }
  validaHoraMenorMaior(horaInicio, horaFinal) {
    let horaInicioSplit = horaInicio.split(':');
    let horaFinalSplit = horaFinal.split(':');
    if (
      parseInt(horaInicioSplit[0]) <= 23 &&
      parseInt(horaInicioSplit[1]) <= 59 &&
      parseInt(horaFinalSplit[0]) <= 23 &&
      parseInt(horaFinalSplit[1]) <= 59
    ) {
      return (
        parseInt(horaInicio.replace(':', '')) <=
        parseInt(horaFinal.replace(':', ''))
      );
    } else {
      return false;
    }
  }

  validaDataMaiorOuIgual(dataInicio, dataFim) {
    let data1 = new Date(
      dataInicio.split('/')[2],
      dataInicio.split('/')[1],
      dataInicio.split('/')[0]
    );
    let data2 = new Date(
      dataFim.split('/')[2],
      dataFim.split('/')[1],
      dataFim.split('/')[0]
    );
    return data1 >= data2;
  }

  validaDataMenorOuIgual(dataInicio, dataFim) {
    let data1 = new Date(
      dataInicio.split('/')[2],
      dataInicio.split('/')[1],
      dataInicio.split('/')[0]
    );
    let data2 = new Date(
      dataFim.split('/')[2],
      dataFim.split('/')[1],
      dataFim.split('/')[0]
    );
    return data1 <= data2;
  }

  validaDataMaior(dataInicio, dataFim) {
    let data1 = new Date(
      dataInicio.split('/')[2],
      dataInicio.split('/')[1],
      dataInicio.split('/')[0]
    );
    let data2 = new Date(
      dataFim.split('/')[2],
      dataFim.split('/')[1],
      dataFim.split('/')[0]
    );
    return data1 > data2;
  }

  validaDataMenor(dataInicio, dataFim) {
    let data1 = new Date(
      dataInicio.split('/')[2],
      dataInicio.split('/')[1],
      dataInicio.split('/')[0]
    );
    let data2 = new Date(
      dataFim.split('/')[2],
      dataFim.split('/')[1],
      dataFim.split('/')[0]
    );
    return data1 < data2;
  }

  validaDataHoraMaiorOuIgual(dataInicio, dataFim) {
    let hora1 = dataInicio.split(' ')[1];
    let hora2 = dataFim.split(' ')[1];
    let data1 = new Date(
      parseInt(dataInicio.split('/')[2]),
      parseInt(dataInicio.split('/')[1]),
      parseInt(dataInicio.split('/')[0]),
      parseInt(hora1.split(':')[0]),
      parseInt(hora1.split(':')[1])
    );
    let data2 = new Date(
      parseInt(dataFim.split('/')[2]),
      parseInt(dataFim.split('/')[1]),
      parseInt(dataFim.split('/')[0]),
      parseInt(hora2.split(':')[0]),
      parseInt(hora2.split(':')[1])
    );
    return data1.valueOf() >= data2.valueOf();
  }

  validaDataHoraMenorOuIgual(dataInicio, dataFim) {
    let hora1 = dataInicio.split(' ')[1];
    let hora2 = dataFim.split(' ')[1];
    let data1 = new Date(
      parseInt(dataInicio.split('/')[2]),
      parseInt(dataInicio.split('/')[1]),
      parseInt(dataInicio.split('/')[0]),
      parseInt(hora1.split(':')[0]),
      parseInt(hora1.split(':')[1])
    );
    let data2 = new Date(
      parseInt(dataFim.split('/')[2]),
      parseInt(dataFim.split('/')[1]),
      parseInt(dataFim.split('/')[0]),
      parseInt(hora2.split(':')[0]),
      parseInt(hora2.split(':')[1])
    );
    return data1.valueOf() <= data2.valueOf();
  }

  validaDataHoraMaior(dataInicio, dataFim) {
    let hora1 = dataInicio.split(' ')[1];
    let hora2 = dataFim.split(' ')[1];
    let data1 = new Date(
      parseInt(dataInicio.split('/')[2]),
      parseInt(dataInicio.split('/')[1]),
      parseInt(dataInicio.split('/')[0]),
      parseInt(hora1.split(':')[0]),
      parseInt(hora1.split(':')[1])
    );
    let data2 = new Date(
      parseInt(dataFim.split('/')[2]),
      parseInt(dataFim.split('/')[1]),
      parseInt(dataFim.split('/')[0]),
      parseInt(hora2.split(':')[0]),
      parseInt(hora2.split(':')[1])
    );
    return data1.valueOf() > data2.valueOf();
  }

  validaDataHoraMenor(dataInicio, dataFim) {
    let hora1 = dataInicio.split(' ')[1];
    let hora2 = dataFim.split(' ')[1];
    let data1 = new Date(
      parseInt(dataInicio.split('/')[2]),
      parseInt(dataInicio.split('/')[1]),
      parseInt(dataInicio.split('/')[0]),
      parseInt(hora1.split(':')[0]),
      parseInt(hora1.split(':')[1])
    );
    let data2 = new Date(
      parseInt(dataFim.split('/')[2]),
      parseInt(dataFim.split('/')[1]),
      parseInt(dataFim.split('/')[0]),
      parseInt(hora2.split(':')[0]),
      parseInt(hora2.split(':')[1])
    );
    return data1.valueOf() < data2.valueOf();
  }

  addMaskIntNumber(num) {
    let valueString = num.toString();
    let returnString = '';
    for (let i = 1; i <= valueString.length; i++) {
      returnString = valueString[valueString.length - i] + returnString;
      if (returnString.length === 3 && valueString.length > 3) {
        returnString = '.' + returnString;
      } else if (returnString.length === 7 && valueString.length > 6) {
        returnString = '.' + returnString;
      } else if (returnString.length === 11 && valueString.length > 9) {
        returnString = '.' + returnString;
      } else if (returnString.length === 15 && valueString.length > 12) {
        returnString = '.' + returnString;
      } else if (returnString.length === 19 && valueString.length > 15) {
        returnString = '.' + returnString;
      }
    }
    return returnString;
  }
}
