<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{item.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group col-12">
            <span>
                {{item.msg}}
            </span>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" (click)="confirmar()">Sim</button>
</div>