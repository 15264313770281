<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Adicionar Procuração
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">


    <form [formGroup]="form">
        <div class="row">


            <div class="col-12">
                <label class="labels">Eleitor Representante<span class="campo-required"> *</span></label>
                <ng-select class="input-round" formControlName="idEleitor"
                    placeholder="Selecione o eleitor representante"
                    (change)="changeEleitor(form.get('idEleitor').value)"
                    [ngClass]="{'invalido':form.get('idEleitor').invalid && submitted}">
                    <ng-option *ngFor="let eleitor of eleitoresRepresentantes" [value]="eleitor._id">{{eleitor.nome}}</ng-option>
                </ng-select>
                <p class="erro" *ngIf="form.get('idEleitor').invalid && submitted">Campo Obrigatório</p>
            </div>

            <div class="col-12">
                <label class="labels">Eleitor Representado<span class="campo-required"> *</span></label>
                <ng-select class="input-round" formControlName="idRepresentado"
                    placeholder="Selecione o eleitor representante"
                    (change)="changeRepresentado(form.get('idRepresentado').value)"
                    [ngClass]="{'invalido':form.get('idRepresentado').invalid && submitted}">
                    <ng-option *ngFor="let eleitor of eleitoresRepresentados" [value]="eleitor._id">{{eleitor.nome}}</ng-option>
                </ng-select>
                <p class="erro" *ngIf="form.get('idRepresentado').invalid && submitted">Campo Obrigatório</p>
            </div>


            <div class="col-12">
                <label class="labels">Empresa Representada<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="empresa" formControlName="empresa" [ngClass]="{'invalido':form.get('empresa').invalid && submitted}" placeholder="Informe o nome da empresa que será representada">
                <p class="erro" *ngIf="form.get('empresa').invalid && submitted">Campo Obrigatório</p>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="labels">Procuração <span class="campo-required">*</span></label>
                </div>
                <div *ngIf="docs.length == 0" class="input-group col-12" style="align-items: flex-end!important;">
                    <label class="input-group-btn" style="margin: 0px!important;">
                        <span class="btn btn-outline-primary"
                            style="border-radius: 0!important; border-top-left-radius: 50px!important; border-bottom-left-radius: 50px!important;">
                            Carregar arquivo<input id="select-doc" type="file" style="display:none;"
                                onchange="$('#doc').val($(this).val());" (change)="carregarArquivo($event.target.files);">
                        </span>
                    </label>
                    <input autocomplete="off" type="text" class="form-control" id="doc" placeholder="Selecione a procuração" style="border-top-left-radius: 0!important; border-bottom-left-radius: 0!important;" readonly>
                </div>
                <div class="col-12" *ngIf="docs.length > 0">
                    <div class="row">
                        <p class="arquivoP" *ngFor="let d of docs">{{new ? d.name : d}}
                            <span class="deleteIcon" placement="bottom" ngbTooltip="Remover Arquivo"><i class="far fa-trash-alt"
                                    (click)="removerArquivo(d)"></i></span>
                            <span *ngIf="!new" class="downloadIcon" placement="bottom" ngbTooltip="Download Arquivo"><i
                                    class="fas fa-file-download" (click)="downloadArquivo(d)"></i></span>
                        </p>
                    </div>
                </div>
                <div class="col-12" *ngIf="docs.length <= 0 && submitted">
                    <p class="erro">Não existe uma procuração selecionada, item obrigatório</p>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" *ngIf="item.tipo !== 'view'"
        (click)="salvar()">Salvar</button>
</div>