import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Role } from 'src/app/models/role.enum';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HardCodeService } from 'src/app/services/hard-code.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';

@Component({
  selector: 'app-modal-view-user',
  templateUrl: './modal-view-user.component.html',
  styleUrls: ['./modal-view-user.component.css']
})
export class ModalViewUserComponent implements OnInit {

  @Input() public tipo;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  @Input() name;
  @BlockUI() blockUi: NgBlockUI;
  public user: User;
  public cpf_cnpj: string;
  public tipoUsuario: string;

  constructor(private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService,
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    public hardCode: HardCodeService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.user = this.item.obj;
    this.cpf_cnpj = this.getCpfCnpj();
    this.tipoUsuario = this.getTipo();
  }

  private getTipo(): string {
    switch (this.user.roles[0]) {
      case Role.ADM:
        return 'Administrador'
        break;

      case Role.ELEITOR:
        return 'Eleitor'
        break;

      case Role.SIND:
        return 'Sindicato'
        break;

      default:
        break;
    }
  }

  private getCpfCnpj(): string {
    switch (this.user.roles[0]) {
      case Role.ADM:
        return this.trataDocs.addMaskCpf(this.user.cpf);
        break;

      case Role.ELEITOR:
        return this.trataDocs.addMaskCpf(this.user.cpf);
        break;

      case Role.SIND:
        return this.trataDocs.addMaskCnpj(this.user.cpf);
        break;

      default:
        break;
    }
  }

}
