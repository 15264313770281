<div [id]="'cid'">
    <div id="div-chart" style="height: 700px;">
        <ngx-charts-pie-chart [scheme]="colorScheme" [legend]="legend" [legendTitle]="legendTitle" [legendPosition]="legendPosition" [results]="data" [labels]="labels" [trimLabels]="trimLabels" [doughnut]="isDoughnut" [arcWidth]="arcWidth" [labelFormatting]="setLabelFormatting"
            [maxLabelLength]="40" [tooltipText]="setTooltipText" [view]="[0, 500]">
        </ngx-charts-pie-chart>
    </div>
    <div class="chart-acoes" style="float: right; margin-bottom: 10px;">
        <p class="chart-label">{{legend ? 'Ocultar Legendas' : 'Mostrar Legendas'}}</p>
        <label class="switch">
            <input type="checkbox" [(ngModel)]="legend">
            <span class="slider round"></span>
        </label>
    </div>
</div>