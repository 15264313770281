<div class="row" style="justify-content: space-between; align-items: center">
  <h3 class="titulo">Usuários</h3>
  <!-- <button class="btn btn-outline-success" (click)="openModalSindicato('new')"><i class="fas fa-plus"></i> Novo Sindicato</button> -->
  <button class="btn btn-outline-success" (click)="openModalUsuario()">
    <i class="fas fa-plus"></i> Novo Usuário
  </button>
</div>
<hr />

<div class="d-flex justify-content-end">
  <input
    type="text"
    class="form-control"
    style="width: 400px; border-radius: 14px"
    [(ngModel)]="searchVal"
    placeholder="Buscar Nome ou CPF/CNPJ"
  />
</div>
<div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px">
  <table>
    <thead>
      <tr>
        <th class="th-rigth-border">Usuário</th>
        <th class="th-rigth-border" (click)="sortTable('nome')">
          Nome
          <i
            class="fas"
            [ngClass]="getSortIcon('nome')"
            style="margin-left: 5px; cursor: pointer"
          ></i>
        </th>
        <th class="th-rigth-border">Email</th>
        <th class="th-rigth-border">CPF/CNPJ</th>
        <th class="th-rigth-border" (click)="sortTable('roles')">
          Tipo
          <i
            class="fas"
            [ngClass]="getSortIcon('roles')"
            style="margin-left: 5px; cursor: pointer"
          ></i>
        </th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody id="teste" *ngIf="usuarios.length > 0 && usuarios">
      <tr
        *ngFor="
          let u of filteredUsuarios
            | slice : (paginaAtual - 1) * size : (paginaAtual - 1) * size + size
        "
      >
        <td>{{ u.login }}</td>
        <td>{{ u.nome }}</td>
        <td>{{ u.email }}</td>
        <td>{{ u.cpf }}</td>
        <td *ngIf="u.roles[0] === 'SEVI_ADM[*]'">Administrador</td>
        <td *ngIf="u.roles[0] === 'SEVI_ELEITOR[*]'">Eleitor</td>
        <td *ngIf="u.roles[0] === 'SEVI_SIND[*]'">Sindicato</td>
        <td *ngIf="!u.roles[0]">Vazio</td>
        <td>
          <div style="display: inline-flex">
            <i
              class="fas fa-search acoes"
              (click)="openModalViewUsuario(u)"
              style="padding-left: 5px"
              placement="bottom "
              ngbTooltip="Visualizar "
            ></i>
            <i
              class="fas fa-edit acoes"
              (click)="openModalEditUsuario(u)"
              style="padding-left: 5px"
              placement="bottom "
              ngbTooltip="Editar "
            ></i>
            <!-- <i class="fas fa-ban acoes " style="padding-left: 5px " placement="bottom " ngbTooltip="Inativar "></i>
                        <i class="fas fa-check acoes " style="padding-left: 5px " placement="bottom " ngbTooltip="Ativar "></i> -->
            <!-- <i class="fas fa-search acoes " style="padding-left: 5px " placement="bottom " ngbTooltip="Visualizar " (click)="openModalSindicato( 'view', sind) "></i>
                        <i class="fas fa-edit acoes " style="padding-left: 5px " placement="bottom " ngbTooltip="Editar " (click)="openModalSindicato( 'edit', sind) "></i>
                        <i class="fas fa-ban acoes " *ngIf="sind.ativo " style="padding-left: 5px " placement="bottom " ngbTooltip="Inativar " (click)="changeStatus(sind) "></i>
                        <i class="fas fa-check acoes " *ngIf="!sind.ativo " style="padding-left: 5px " placement="bottom " ngbTooltip="Ativar " (click)="changeStatus(sind) "></i> -->
          </div>
        </td>
      </tr>
    </tbody>
    <p
      *ngIf="filteredUsuarios.length <= 0"
      style="color: #ff0000; font-weight: bold"
    >
      • Não existem usuários cadastrados.
    </p>
  </table>
</div>
<div class="row">
  <div class="col-12" style="padding: 0; text-align: right">
    Quantidade de usuários: {{ filteredUsuarios.length }}
  </div>
</div>
<div class="row" style="justify-content: space-between">
  <select
    style="width: fit-content !important"
    class="form-control"
    type="text "
    name="qtdPage "
    id="qtdPage "
    [(ngModel)]="size"
    (change)="changeOp(size)"
  >
    <option *ngFor="let op of opcoes">{{ op }}</option>
  </select>
  <ngb-pagination
    class="d-flex justify-content-end"
    [collectionSize]="filteredUsuarios.length"
    [(page)]="paginaAtual"
    [pageSize]="size"
    [maxSize]="2"
    [rotate]="true"
    [ellipses]="false"
    [boundaryLinks]="false"
    (pageChange)="changePage($event)"
    size="sm "
  ></ngb-pagination>
</div>
