import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-vertical-bar-chart',
  templateUrl: './custom-vertical-bar-chart.component.html',
  styleUrls: ['./custom-vertical-bar-chart.component.css']
})
export class CustomVerticalBarChartComponent implements OnInit {
  @Input() id: string;
  @Input() cid: any;
  @Input() data: any;
  @Input() title: string;

  legend: boolean = true;
  legendTitle: string = "Legendas";
  legendPosition: string = "below";
  xAxis: boolean = true;
  yAxis: boolean = true;
  trimXAxisTicks: boolean = true;
  showDataLabel: boolean = true;
  yAxisTicks: any[] = []
  barPadding: number = 50;
  colorScheme: any = {
    domain: ["#92d050", "#48bb94", "#00b0f0", "#0070c0", "#002060"]
  };

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
  }

  setLabelFormatting(c) {
    return `${c}`;
  }


}
