import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Role } from 'src/app/models/role.enum';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-eleitores',
  templateUrl: './modal-eleitores.component.html',
  styleUrls: ['./modal-eleitores.component.css'],
})
export class ModalEleitoresComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      nome: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      empresa: [null],
      status: [true],
      sindicatos: [[]],
    });

    if (this.item.obj) {
      this.form.patchValue(this.item.obj);
      this.form.get('cpf').disable();
      if (this.item.tipo === 'view') {
        this.form.disable();
      }
    }
  }

  checkCpf(cpf) {
    if (this.util.checkVariavel(cpf)) {
      if (!this.trataDocs.validaCPF(cpf)) {
        this.toaster.pop(
          'error',
          'SEVI',
          'CPF inválido, verifique e tente novamente.'
        );
        this.form.patchValue({ cpf: null });
      } else {
        this.verificaCpf(cpf);
      }
    }
  }

  async verificaCpf(cpf) {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleitoresCpf +
          `/${this.trataDocs.limpaDoc(cpf)}`
      );
      if (resp.body.documento) {
        const { nome, email } = resp.body.documento;
        this.form.patchValue({ nome, email });
        this.toaster.pop(
          'info',
          'SEVI',
          'O CPF informado já possui cadastro na plataforma.'
        );
      }
    } catch (err) {
      if (err.error.statusCode === 404) {
        this.toaster.pop(
          'info',
          'SEVI',
          'CPF não encontrado, você pode cadastrar um novo usuário.'
        );
      } else {
        console.error('[[ERRO GET ELEITOR CPF]] => ', err);
      }
    }
  }

  salvar() {
    this.submitted = true;
    if (
      this.form.valid &&
      this.trataDocs.validaCPF(this.form.get('cpf').value)
    ) {
      this.blockUi.start('Salvando Dados');
      this._gerarLogin();
    } else {
      this.checkCpf(this.form.get('cpf').value);
    }
  }

  private async _gerarLogin() {
    try {
      this._checkCadastro();
    } catch (err) {
      console.error('[[ERRO CADASTRA USUÁRIO]] => ', err);
      if (err.error.statusCode === 422) {
        this._addRole();
      } else {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
        );
      }
    }
  }

  private async _addRole() {
    try {
      await this.api.postGeneric(
        environment.urlBase +
          environment.paths.usuario +
          `/${this.trataDocs.limpaDoc(this.form.get('cpf').value)}/role/${
            Role.ELEITOR
          }`,
        {}
      );
      this._checkCadastro();
    } catch (err) {
      console.error('[[ERRO ADD ROLE]] => ', err);
      if (err.error.statusCode === 404) {
        this._checkCadastro();
      } else {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
        );
      }
    }
  }

  private async _checkCadastro() {
    let eleitor = this.form.getRawValue();
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleitoresCpf +
          `/${this.trataDocs.limpaDoc(this.form.get('cpf').value)}`
      );
      if (
        eleitor.sindicatos.filter((e) => e === this.item.idSindicato).length <=
        0
      ) {
        eleitor.sindicatos.push(this.item.idSindicato);
      }
      this.salvarEleitor(eleitor, 'edit');
    } catch (err) {
      console.error('[[ERRO GET ELEITOR]]');
      if (err.error.statusCode === 404) {
        eleitor.sindicatos.push(this.item.idSindicato);
        this.salvarEleitor(eleitor, 'new');
      } else {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
        );
      }
    }
  }

  private async salvarEleitor(eleitor, tipo?) {
    try {
      eleitor.cpf = this.trataDocs.limpaDoc(eleitor.cpf);
      if (tipo === 'edit') {
        const respGet = await this.api.getGeneric(
          environment.urlBase +
            environment.paths.eleitoresCpf +
            `/${this.trataDocs.limpaDoc(this.form.get('cpf').value)}`
        );

        const documento = respGet.body.documento;

        const resp = await this.api.putGeneric(
          environment.urlBase +
            environment.paths.eleitores +
            `/${documento._id}`,
          eleitor
        );
        eleitor._id = resp.body.documento._id;
      } else {
        const resp = await this.api.postGeneric(
          environment.urlBase + environment.paths.eleitores,
          eleitor
        );
        eleitor._id = resp.body.documento._id;
      }
      this.blockUi.stop();
      this.modal.close(eleitor);
    } catch (err) {
      this.blockUi.stop();
      this.modal.close();

      console.error('[[ERRO CADASTRO ELEITOR]]');
    }
  }
}
