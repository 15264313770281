import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  public horaToken: any = "";
  public token: any = "";
  public rotaAtual: any = "/";

  public logado = false;
  public urlAtual = "";
  public username: any = "";
  public role: any = "";

  public masks = {
    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    data: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    hora: [/\d/, /\d/, ':', /\d/, /\d/],
    tel: ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/],
    ano: [/\d/, /\d/, /\d/, /\d/],
    cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  }


}

