import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ModalVotacaoChapaComponent } from 'src/app/modais/modal-votacao-chapa/modal-votacao-chapa.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
moment.locale('pt-br');

declare var $: any;

@Component({
  selector: 'app-home-eleitor',
  templateUrl: './home-eleitor.component.html',
  styleUrls: ['./home-eleitor.component.css'],
})
export class HomeEleitorComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public infoEleitor: any = {};
  public eleicoes = [];
  private idsEleicoes = [];
  public sindicatos = [];
  public habilitarVotacao: boolean = false;

  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private trataDatas: TrataNumeros,
    public modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.getInfoEleitor(this.auth.currentUserValue.nome);
    // this.test();
  }

  private async getInfoEleitor(cpf) {
    this.blockUi.start('Carregando Eleições');
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleitoresCpf +
          `/${this.auth.currentUserValue.nomeCompleto}`
      );
      this.infoEleitor = resp.body.documento;
      this._getEleicoesEleitor(this.infoEleitor._id);
    } catch (err) {
      this.blockUi.stop();
      // this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error('[[ERRO GET ELEITOR]] => ', err);
    }
  }

  private async _getEleicoesEleitor(idEleitor) {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleicoes +
          '/eleitores/' +
          idEleitor
      );
      if (resp.body.documento.length > 0) {
        console.log('aq');
        this.eleicoes = [];
        resp.body.documento.forEach(async (e) => {
          if (e.status && this._checkPeriodo(e)) {
            this.idsEleicoes.push(e._id);
            this.eleicoes.push({
              _id: e._id,
              nome: e.nome,
              dataInicio: e.dataInicio,
              dataFinal: e.dataFinal,
              idSindicato: e.idSindicato,
              votoEleitor: e.eleitores.includes(idEleitor),
              situacao: true,
              status: e.staus,
              nomeSindicato: e.nomeSindicato,
            });
          }
        });
      }

      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
      );
      console.error('[[ERRO GET ELEIÇÕES]] => ', err);
    }
  }

  openModalVotacao(eleicao) {
    const modalRef = this.modal.open(ModalVotacaoChapaComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      eleicao: eleicao,
      eleitorId: this.infoEleitor._id,
    };
    modalRef.result.then((res) => {
      if (res) {
        this._getEleicoesEleitor(this.infoEleitor._id);
      }
    });
  }

  private async test() {
    document.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );
  }

  private _checkPeriodo(e) {
    return this.trataDatas.validaDataHoraMenorOuIgual(
      moment().format('DD/MM/YYYY HH:mm'),
      e.dataFinal
    );
  }
}
