<div class="row" style="justify-content: space-between; align-items: center;">
    <h3 class="titulo">Assembleias</h3>
    <button class="btn btn-outline-success" (click)="modalAssembleias('new')"><i class="fas fa-plus"></i> Nova Assembleia</button>
</div>
<hr>


<div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
    <table>
        <thead>
            <tr>
                <th class="th-rigth-border">Assembleias</th>
                <th class="th-rigth-border">Convocações</th>
                <th class="th-rigth-border">Status</th>
                <th>Ações</th>
            </tr>
        </thead>
        <tbody id="assembleia" *ngIf="assembleias.length > 0">
            <tr *ngFor="let a of assembleias | slice: (paginaAtual - 1) * size : ((paginaAtual - 1) * size) + size">
                <td>{{ a.nome }}</td>
                <td><p style="margin: 0;" *ngFor="let c of a.chamadas">{{(momentUse(c.dataInicio).isValid() && c.dataInicio.length != 16) ? momentUse(c.dataInicio).add(3, 'hours').format('L LT') : c.dataInicio}} à {{(momentUse(c.dataFim).isValid() && c.dataFim.length != 16) ? momentUse(c.dataFim).add(3, 'hours').format('L LT') : c.dataFim}}</p></td>
                <td>
                    <div [ngClass]="{'tag-apro': a.ativa, 'tag-repro': !a.ativa }" class="tag-apro">
                        {{a.ativa ? 'ATIVO' : 'INATIVA'}}
                    </div>
                </td>
                <td>
                    <div style="display: inline-flex;">
                        <i class="fas fa-search acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Visualizar" (click)="modalAssembleias('view', a)"></i>
                        <i class="fas fa-edit acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Editar" (click)="modalAssembleias('edit', a)"></i>
                        <i class="fas fa-list-ul acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Gerenciar Pautas" (click)="openModalPautas(a)"></i>
                        <i class="fas fa-user acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Selecionar Eleitores" (click)="selecionarEleitores(a)"></i>
                        <i class="far fa-file-alt acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Procurações" (click)="submeterProcuracao(a)"></i>
                    </div>
                </td>
            </tr>
        </tbody>
        <p *ngIf="assembleias.length <= 0" style="color: #ff0000; font-weight: bold;">• Não existem Assembleias cadastradas.
        </p>
    </table>
</div>
<div class="row">
    <div class="col-12" style="padding: 0; text-align: right;">
        Quantidade de Assembleias: {{assembleias.length}}
    </div>
</div>
<div class="row" style="justify-content: space-between;">
    <select style="width: fit-content!important;" class="form-control" type="text" name="qtdPage" id="qtdPage" [(ngModel)]="size" (change)="changeOp(size)">
        <option *ngFor="let op of opcoes">{{op}}</option>
    </select>
    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="numRegistros" [(page)]="paginaAtual" [pageSize]="size" [maxSize]="2" [rotate]="true" [ellipses]="false" [boundaryLinks]="false" (pageChange)="changePage($event)" size="sm"></ngb-pagination>
</div>