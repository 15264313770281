<nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onTabChanged($event)">
  <ng-container ngbNavItem>
    <a ngbNavLink>Eleições</a>
    <ng-template ngbNavContent>
      <div class="row" style="display: initial; text-align: center !important">
        <h3 class="titulo">Eleições</h3>
      </div>

      <div class="d-flex justify-content-end">
        <input
          type="text"
          class="form-control"
          style="width: 200px; border-radius: 14px; margin: 0px 5px"
          placeholder="Data Inicio"
          [textMask]="{ mask: globals.masks.data, guide: false }"
          [(ngModel)]="filterDataInicio"
        />
        <input
          type="text"
          class="form-control"
          style="width: 200px; border-radius: 14px; margin: 0px 5px"
          placeholder="Data Fim"
          [textMask]="{ mask: globals.masks.data, guide: false }"
          [(ngModel)]="filterDataFinal"
        />
        <select
          class="form-control"
          style="width: 300px; border-radius: 14px; margin: 0px 5px"
          [(ngModel)]="filterSituacao"
        >
          <option value="">Status</option>
          <option value="ELEIÇÃO AINDA NÃO INICIADA">
            ELEIÇÃO AINDA NÃO INICIADA
          </option>
          <option value="APURAÇÃO CONCLUÍDA">APURAÇÃO CONCLUÍDA</option>
          <option value="AGUARDANDO O ENCERRAMENTO">
            AGUARDANDO O ENCERRAMENTO
          </option>
        </select>
        <button
          class="btn btn-outline-success"
          style="margin: 0px 5px"
          (click)="filterElections()"
        >
          Filtrar
        </button>
      </div>

      <div class="row" *ngIf="eleicoes.length > 0">
        <div
          class="col-lg-4 col-md-6 col-12"
          *ngFor="let e of filteredEleicoes"
        >
          <div class="card-eleicao">
            <h5 class="titulo">{{ e.nome }}</h5>
            <p style="margin-bottom: 0px">
              {{ e.dataInicio }} à {{ e.dataFinal }}
            </p>
            <h5 style="margin: 5px 0px" class="titulo">Status</h5>
            <div
              style="margin-bottom: 10px"
              [ngClass]="{
                'label-nao-iniciada':
                  e.situacao === 'ELEIÇÃO AINDA NÃO INICIADA',
                'label-concluida': e.situacao === 'APURAÇÃO CONCLUÍDA',
                'label-aguardando': e.situacao === 'AGUARDANDO O ENCERRAMENTO'
              }"
            >
              {{ e.situacao }}
            </div>
            <button
              type="button"
              class="btn btn-sm btn-outline-success"
              (click)="openListaPresenca(e)"
            >
              Lista de Presença
            </button>
            <button
              type="button"
              class="btn btn-sm btn-outline-success"
              [disabled]="!e.situacao"
              (click)="openDashboardEleicao(e)"
            >
              Exibir Apuração
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="filteredEleicoes.length <= 0">
        <p class="erro">Não existem eleições realizadas.</p>
      </div>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
