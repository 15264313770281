import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HardCodeService } from 'src/app/services/hard-code.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-sindicato',
  templateUrl: './modal-sindicato.component.html',
  styleUrls: ['./modal-sindicato.component.css'],
})
export class ModalSindicatoComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    public hardCode: HardCodeService,
    private formBuilder: FormBuilder,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cnpj: [null, Validators.required],
      nome: [null, Validators.required],
      endereco: [null],
      uf: [null],
      cidade: [null],
      bairro: [null],
      cep: [null],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      tel: [null, Validators.required],
      cel: [null],
      responsavel: [null],
      ativo: [true, Validators.required],
    });

    if (this.item.tipo === 'view') {
      this.form.patchValue(this.item.obj);
      this.form.disable();
    } else if (this.item.tipo === 'edit') {
      this.form.patchValue(this.item.obj);
      this.form.get('cnpj').disable();
    }
  }

  async verificaCnpj(cnpj) {
    if (this.trataDocs.validaCNPJ(cnpj)) {
      try {
        const resp = await this.api.getGeneric(
          environment.urlBase +
            environment.paths.sindicatosCnpj +
            `/${this.trataDocs.limpaDoc(cnpj)}`
        );
        if (resp.body.documento) {
          this.form.patchValue({ cnpj: null });
          this.toaster.pop(
            'error',
            'SEVI',
            'O CNPJ informado já possui cadastro na plataforma.'
          );
        }
      } catch (err) {
        if (err.error.statusCode !== 404) {
          console.error('[[ERRO GET SINDICATO]] => ', err);
        }
      }
    } else {
      this.form.patchValue({ cnpj: null });
      this.toaster.pop(
        'error',
        'SEVI',
        'CNPJ inválido, verifique o mesmo e tente novamente.'
      );
    }
  }

  async verificaEndereco(cep) {
    if (cep.length === 9) {
      try {
        this.blockUi.start('Buscando endereço');
        const resp = await this.api.viaCep(cep);
        if (!resp.error) {
          this.form.patchValue({
            endereco: resp.logradouro,
            uf: resp.uf,
            cidade: resp.localidade,
            bairro: resp.bairro,
          });
        } else {
          this.form.patchValue({
            endereco: null,
            uf: null,
            cidade: null,
            bairro: null,
          });
        }
        this.blockUi.stop();
      } catch (err) {
        this.blockUi.stop();
        console.error('[[ERRO GET VIA CEP]] => ', err);
      }
    } else {
      this.form.patchValue({
        cep: null,
        endereco: null,
        uf: null,
        cidade: null,
        bairro: null,
      });
      this.toaster.pop(
        'error',
        'SEVI',
        'CEP inválido, verifique o mesmo e tente novamente.'
      );
    }
  }

  private async _criarLogin() {
    try {
      await this.api.postGeneric(
        environment.urlBase + environment.paths.usuario,
        {
          nome: this.form.get('nome').value,
          email: this.form.get('email').value,
          cpf_cnpj: this.trataDocs.limpaDoc(this.form.get('cnpj').value),
          role: 'SEVI_SIND[*]',
        }
      );
    } catch (err) {
      console.error('[[ERROR POST USUARIO]] => ', err);
    }
  }

  async salvar() {
    this.submitted = true;
    if (this.form.valid) {
      this.blockUi.start('Salvando os dados');
      try {
        let sindicato = this.form.getRawValue();
        let resp: any = {};
        sindicato.cnpj = this.trataDocs.limpaDoc(sindicato.cnpj);
        if (this.item.obj) {
          sindicato._id = this.item.obj._id;
          resp = await this.api.putGeneric(
            environment.urlBase +
              environment.paths.sindicatos +
              `/${this.item.obj._id}`,
            sindicato
          );
          this.blockUi.stop();
          this.modal.close(sindicato);
        } else {
          resp = await this.api.postGeneric(
            environment.urlBase + environment.paths.sindicatos,
            sindicato
          );
          // await this._criarLogin();
          this.blockUi.stop();
          sindicato = resp.body.documento;
          sindicato.cnpj = this.trataDocs.addMaskCnpj(sindicato.cnpj);
          this.modal.close(sindicato);
        }
      } catch (err) {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista abra um BPM.'
        );
        console.error('[[ERRO POST SINDICATO]] => ', err);
      }
    }
  }
}
