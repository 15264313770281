<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload de Sindicatos</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-2" style="text-align: center;">
            <button class="btn btn-outline-success" (click)="downloadEspelho()">
              <span>Download Modelo</span>
          </button>
        </div>
        <div class="input-group col-10" style="align-items: flex-end!important;">
            <label class="input-group-btn" style="margin: 0px!important;">
              <span class="btn btn-outline-primary"
                  style="border-radius: 0!important; border-top-left-radius: 50px!important; border-bottom-left-radius: 50px!important;">
                  Carregar arquivo<input autocomplete="off" id="my-file-selector" type="file" style="display:none;"
                      onchange="$('#upload-file-info').val($(this).val());" (change)="onFileChange($event);"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              </span>
          </label>
            <input autocomplete="off" type="text" class="form-control" id="upload-file-info" placeholder="Selecione a planilha" style="border-top-left-radius: 0!important; border-bottom-left-radius: 0!important;" readonly>
        </div>
    </div>
    <div class="row col-12">
        <div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Nome</th>
                        <th class="th-rigth-border">CNPJ</th>
                        <th class="th-rigth-border">E-mail</th>
                        <th class="th-rigth-border">CEP</th>
                        <th class="th-rigth-border">Endereço</th>
                        <th class="th-rigth-border">Estado</th>
                        <th class="th-rigth-border">Cidade</th>
                        <th class="th-rigth-border">Bairro</th>
                        <th class="th-rigth-border">Telefone</th>
                        <th class="th-rigth-border">Celular</th>
                        <th class="th-rigth-border">Responsável</th>
                    </tr>
                </thead>
                <tbody id="teste">
                    <tr *ngFor="let s of sindicatos">
                        <td>{{ s.nome }}</td>
                        <td>{{ s.cnpj }}</td>
                        <td>{{ s.email }}</td>
                        <td>{{ s.cep }}</td>
                        <td>{{ s.endereco }}</td>
                        <td>{{ s.estado }}</td>
                        <td>{{ s.cidade }}</td>
                        <td>{{ s.bairro }}</td>
                        <td>{{ s.telefone }}</td>
                        <td>{{ s.celular }}</td>
                        <td>{{ s.responsavel }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="sindicatosComFalha.length > 0" class="row col-12">
        <h5 style="margin: 0px;">Sindicatos com erros para registro</h5>
        <hr style="border-bottom: solid 1px #000; width: 100%; margin: 3px 0px;">
        <p style="color: #ff0000; font-weight: bold; margin: 0px;">• Erros que invalidam o cadastro, nome em branco, CPF inválido ou em branco e e-mail fora de formato ou em branco.</p>
    </div>

    <div *ngIf="sindicatosComFalha.length > 0" class="row col-12">
        <div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Nome</th>
                        <th class="th-rigth-border">CPF</th>
                        <th class="th-rigth-border">E-mail</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody id="teste">
                    <tr *ngFor="let c of sindicatosComFalha">
                        <td>{{ c.nome }}</td>
                        <td>{{ c.cpf }}</td>
                        <td>{{ c.email }}</td>
                        <td>
                            <i class="fas fa-edit acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Editar" (click)="openModalCorrecao(c)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
    <button type="button" class="btn btn-outline-success" (click)="salvar()">Salvar</button>
</div>