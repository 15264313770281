import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-votacao-assembleia',
  templateUrl: './modal-votacao-assembleia.component.html',
  styleUrls: ['./modal-votacao-assembleia.component.css']
})
export class ModalVotacaoAssembleiaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  private checkVotos: any = [];
  private error: boolean = false;

  public voto: any;
  public votos = [];
  public pautas = [];
  public chapaSelecionada = null;
  public tipos: any = []
  public liberarVoto = true;
  public selecaoVoto = true;

  public stepIndex = 1;

  constructor(
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this._checkVotos(this.item.assembleia._id, this.item.eleitorId);
  }

  private async _checkVotos(idAssembleia, idEleitor) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idAssembleia=${idAssembleia}&idEleitor=${idEleitor}`)
      this.checkVotos = resp.body.documento;
      this._getPautas(idAssembleia)
      if (this.item.assembleia.votoEleitor && this.checkVotos.filter(c => c.idAssembleia === idAssembleia && c.idProcuracao === undefined).length <= 0) {
        this.votos.push({
          eleitorRepresentado: null,
          idAssembleia: idAssembleia,
          idProcuracao: null,
          votos: []
        })
      }
    } catch (err) {
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO CHECK VOTO]] => ", err);
    }
  }

  private async _getPautas(idAssembleia) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.pautas + `?idAssembleia=${idAssembleia}`);
      if (resp.body.documento.length > 0) {
        resp.body.documento.forEach(p => {
          this.pautas.push({
            _id: p._id,
            nome: p.nome,
            descricao: p.descricao,
            index: p.index
          });
        });

        this._ordenaPautas();
      }
    } catch (err) {
      console.error("[[ERRO GET CHAPAS]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
    }
  }

  private _ordenaPautas() {
    return this.pautas.sort(function (a, b) {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
  }

  public selectPauta(tipo) {
    if (tipo === "anterior") {
      this.stepIndex = this.stepIndex - 1;
    } else if (tipo === "proxima") {
      let pautaId = this.pautas[this.stepIndex - 1]._id;
      if (!this.votos.map(v => v.votos.filter(vv => vv.idPauta === pautaId && vv.voto === null).length > 0).includes(true)) {
        this.stepIndex = this.stepIndex + 1;
      } else {
        this.toaster.pop("warning", "SEVI", "Verifique se todos os votos desta pauta foram realizandos.");
      }
    }
  }

  private _montaVotos() {
    this.votos.forEach(v => {
      this.pautas.forEach(p => {
        v.votos.push({
          idPauta: p._id,
          voto: null
        })
      });
    });
  }

  public async finalizarVoto() {
    if (!this.votos.map(v => v.votos.filter(vv => vv.idPauta === this.pautas[this.pautas.length - 1]._id && vv.voto === null).length > 0).includes(true)) {
      this.blockUi.start("Salvando os votos")
      this.votos.forEach(async v => {
          if (v.idProcuracao) {
            try {
              await this.api.postGeneric(environment.urlBase + environment.paths.urnaAssembleia + `/${this.item.eleitorId}/procuracao/${v.idProcuracao}`, v)
            } catch (err) {
              this.error = true;
              console.error("[[ERRO POST VOTOS C/ PROCURAÇÃO]] => ", err)
            }
          } else {
            try {
              await this.api.postGeneric(environment.urlBase + environment.paths.urnaAssembleia + `/${this.item.eleitorId}`, v)
            } catch (err) {
              console.error("[[ERRO POST VOTOS]] => ", err)
              this.error = true;
            }
          }
      });

      this.blockUi.stop();
      if(this.error){
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      } else {
        this.toaster.pop("success", "SEVI", "Votos registrados com sucesso.");
        this.modal.close();
      }
    } else {
      this.toaster.pop("warning", "SEVI", "Verifique se todos os votos desta pauta foram realizandos.");
    }
  }
}
