import { ModalUploadSindComponent } from './../../modais/modal-upload-sind/modal-upload-sind.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalConfirmacaoComponent } from 'src/app/modais/modal-confirmacao/modal-confirmacao.component';
import { ModalSindicatoComponent } from 'src/app/modais/modal-sindicato/modal-sindicato.component';
import { ApiService } from 'src/app/services/api.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-adm',
  templateUrl: './home-adm.component.html',
  styleUrls: ['./home-adm.component.css'],
})
export class HomeAdmComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  public searchVal: string = '';

  public sindicatos = [];
  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  private ultimaPagCarregada = 1;
  public opcoes = [5, 10, 25, 50];
  public sortColumn: string = '';
  public sortOrder: boolean = true;

  constructor(
    public modal: NgbModal,

    private toaster: ToasterService,
    private api: ApiService,
    private trataDocs: TrataDocsService
  ) {}

  ngOnInit(): void {
    this._getSindicatos();
  }

  private async _getSindicatos() {
    try {
      this.blockUi.start('Carregando as informações');
      const resp = await this.api.getGeneric(
        environment.urlBase + environment.paths.sindicatos
      );
      resp.body.documento.forEach((s) => {
        s.cnpj = this.trataDocs.addMaskCnpj(s.cnpj);
        this.sindicatos.push(s);
      });
      this.numRegistros = this.sindicatos.length;

      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET SINDICATOS]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.sindicatos = [];
    this.blockUi.start('Carregando');
    setTimeout(() => {
      this._getSindicatos();
      this.blockUi.stop();
    }, 1500);
  }

  openModalSindicato(tipo, sind?) {
    const modalRef = this.modal.open(ModalSindicatoComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      tipo: tipo,
      obj: sind,
    };
    modalRef.result.then((res) => {
      if (res) {
        if (sind) {
          let index = this.sindicatos.findIndex((s) => s._id === sind._id);
          this.sindicatos[index] = res;
          this.sindicatos[index].cnpj = this.trataDocs.addMaskCnpj(
            this.sindicatos[index].cnpj
          );
        } else {
          this.sindicatos.push(res);
        }
      }
    });
  }

  changeStatus(sind) {
    const modalRef = this.modal.open(ModalConfirmacaoComponent, {
      size: 'sm',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      title: sind.ativo ? 'Inativar Sindicato?' : 'Inativar Sindicato?',
      msg: sind.ativo
        ? 'Ao inativar o sindicato o mesmo não podera executar mais ações no sistema até que seja ativado novamente, você confirma a ação?'
        : 'Você deseja reativar o sindicato selecionado?',
    };
    modalRef.result.then(async (res) => {
      if (res) {
        try {
          this.blockUi.start('Salvando Dados');
          let index = this.sindicatos.findIndex((s) => s._id === sind._id);
          sind.ativo = !sind.ativo;
          sind.cnpj = this.trataDocs.limpaDoc(sind.cnpj);
          await this.api.putGeneric(
            environment.urlBase + environment.paths.sindicatos + `/${sind._id}`,
            sind
          );
          this.sindicatos[index] = sind;
          this.sindicatos[index].cnpj = this.trataDocs.addMaskCnpj(
            this.sindicatos[index].cnpj
          );
          this.blockUi.stop();
        } catch (err) {
          console.error('[[ERRO PUT SINDICATO]] => ', err);
          this.blockUi.stop();
          this.toaster.pop(
            'error',
            'SEVI',
            'Erro interno, tente novamente e caso o erro persista abra um BPM.'
          );
        }
      }
    });
  }

  openModalUpload() {
    const modalRef = this.modal.open(ModalUploadSindComponent, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((res) => {
      if (res) {
        // console.log('res');
        // for (let s of res) {
        //   // let index = this.sindicatos.findIndex(s => s._id === s._id);
        //   // this.sindicatos[index] = s;
        //   // this.sindicatos[index].cnpj = this.trataDocs.addMaskCnpj(this.sindicatos[index].cnpj);
        //   this.sindicatos.push(s);
        // }
        this.paginaAtual = 1;
        this.sindicatos = [];
        this._getSindicatos();
      }
    });
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortColumn = column;

    this.sindicatos.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      }

      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn === column) {
      return this.sortOrder ? 'fas fa-angle-down' : 'fas fa-angle-up';
    }
    return 'fas fa-arrows-alt-v';
  }

  get filteredSindicatos() {
    if (!this.searchVal) {
      return this.sindicatos; //
    }
    return this.sindicatos.filter(
      (sind) =>
        sind.nome.toLowerCase().includes(this.searchVal.toLowerCase()) ||
        sind.cnpj.includes(this.searchVal)
    );
  }
}
