import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { ModalAddChamadaComponent } from '../modal-add-chamada/modal-add-chamada.component';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';
import * as moment from 'moment';
moment.locale('pt-br');

@Component({
  selector: 'app-modal-assembleias',
  templateUrl: './modal-assembleias.component.html',
  styleUrls: ['./modal-assembleias.component.css']
})
export class ModalAssembleiasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public momentUse = moment;
  public form: FormGroup;
  public submitted: boolean = false;
  public chamadas: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private ngbModal: NgbModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private util: UtilService,
    private api: ApiService,
    private trataDatas: TrataNumeros
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      idSindicato: [this.item.idSindicato],
      nome: [null, Validators.required],
      ordemDia: [""],
      ordinaria: [false],
      extraordinaria: [false],
      descricao: ["", Validators.required],
      chamadas: [[]],
      eleitores: [[]],
      ativa: [true]
    });

    if(this.item.tipo !== "new"){
      this.form.patchValue(this.item.obj);
      this.item.obj.chamadas.forEach(c => {
        this.chamadas.push({
          chamada : c.chamada,
          dataInicio : moment(c.dataInicio).isValid() ? moment(c.dataInicio).add(3, `hours`).format("L LT") : c.dataInicio,
          dataFim : moment(c.dataFim).isValid() ? moment(c.dataFim).add(3, `hours`).format("L LT") : c.dataFim
        })
      });
      if(this.item.tipo === "view"){
        this.form.disable();
      }
    }
  }

  addChamada(tipo, chamada?) {
    if(tipo === "edit"){
      if (this.trataDatas.validaDataHoraMaiorOuIgual(moment().format("L LT"), `${chamada.dataFim}`)) {
        this.toaster.pop("error", "SEVI", "O período desta chamadas já está fechado, não é possível editar a mesma.");
        return;
      }
    }
    const modalRef = this.ngbModal.open(ModalAddChamadaComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: tipo,
      obj: chamada,
      lista: Object.assign([], this.chamadas)
    }
    modalRef.result.then(res => {
      if (res) {
        if (tipo === "new") {
          this.chamadas.push({
            chamada : res.chamada,
            dataInicio: `${res.dataInicio} ${res.horaInicio}`,
            dataFim: `${res.dataFim} ${res.horaFim}`,
          })
        } else {
          let index = this.chamadas.findIndex(c => c === chamada);
          this.chamadas[index] = {
            chamada : res.chamada,
            dataInicio: `${res.dataInicio} ${res.horaInicio}`,
            dataFim: `${res.dataFim} ${res.horaFim}`,
          }
        }
        this._ordenaChamadas();
      }
    });
  }

  private _ordenaChamadas(){
      return this.chamadas.sort(function (a, b) {
        if (parseInt(a.dataInicio.split(" ")[0].split("/").reverse().join("") + a.dataInicio.split(" ")[1].replace(":","")) > parseInt(b.dataInicio.split(" ")[0].split("/").reverse().join("") + b.dataInicio.split(" ")[1].replace(":",""))) {
          return 1;
        }
        if (parseInt(a.dataInicio.split(" ")[0].split("/").reverse().join("") + a.dataInicio.split(" ")[1].replace(":","")) < parseInt(b.dataInicio.split(" ")[0].split("/").reverse().join("") + b.dataInicio.split(" ")[1].replace(":",""))) {
          return -1;
        }
        return 0;
      });
  }


  delChamada(chamada){
    if (this.trataDatas.validaDataHoraMaiorOuIgual(moment().format("L LT"), `${chamada.dataFim}`)) {
      this.toaster.pop("error", "SEVI", "O período desta chamadas já está fechado, não é possível excluir a mesma.");
      return;
    }
    const modalRef = this.ngbModal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      title: "Excluir chamada?",
      msg: "Ao excluir a chamada a mesma não poderá ser recuperada, você confirma a ação?"
    }
    modalRef.result.then(async res => {
      if (res) {
        let index = this.chamadas.findIndex(c => c === chamada);
        this.chamadas.splice(index, 1)
      }
    });

  }

  async salvar() {
    this.submitted = true;
    if (this.form.valid && this.chamadas.length > 0 && (this.form.get("ordinaria").value || this.form.get("extraordinaria").value)) {
      this.blockUi.start("Salvando assembleia")
      try {
        let assembleia = this.form.getRawValue();
        assembleia.chamadas = this.chamadas;
        try { 
          if (this.item.obj) {
            const resp = await this.api.putGeneric(environment.urlBase + environment.paths.assembleias + `/${this.item.obj._id}`, assembleia);
            this.toaster.pop("success", "SEVI", "Assembleia editada com sucesso.");
            this.blockUi.stop();
            assembleia._id = this.item.obj._id;
            this.modal.close(assembleia);
          } else {
            const resp = await this.api.postGeneric(environment.urlBase + environment.paths.assembleias, assembleia); 
            this.toaster.pop("success", "SEVI", "Assembleia cadastrada com sucesso.");
            this.blockUi.stop();
            this.modal.close(resp.body.documento);
          }
        } catch (err) {
          this.blockUi.stop();
          console.error(`[[ERRO ${this.item.tipo === 'new' ? 'POST' : 'PUT'}]] => `, err)
          this.toaster.pop("error", "SEVI", "Erro Interno, tente novamente se persistir avise os administradores da plataforma.");
        }
      } catch (err) {
        console.error("[[ERRO POST ASSEMBLEIA]] => ", err);
      }
    } else {
      this.toaster.pop("error", "SEVI", "Verifique os dados obrigatórios e se existe ao menos uma chamada adicionada a assembléia.");
    }
  }
}
