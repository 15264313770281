<nav class="navbar navbar-expand-lg navbar-light bg-light header">
  <a class="navbar-brand" href="#">
    <img class="logo" src="assets/images/logo_fiesc.png" />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse navbar-align"
    id="navbarSupportedContent"
  >
    <ul class="navbar-nav mr-auto">
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_ADM[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/home-administrador"
          [ngClass]="{
            'active-route': globals.rotaAtual === '/home-administrador'
          }"
          >Sindicatos</a
        >
      </li>
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_ADM[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/usuarios"
          [ngClass]="{ 'active-route': globals.rotaAtual === '/usuarios' }"
          >Usuários</a
        >
      </li>
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_SIND[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/home-sindicatos"
          [ngClass]="{
            'active-route': globals.rotaAtual === '/home-sindicatos'
          }"
          >Eleições</a
        >
      </li>
      <!-- <li class="nav-item" class="btns-header" *ngIf="user.roles[0] === 'SEVI_SIND[*]'">
                <a class="nav-link labels-headers" href="#/assembleias" [ngClass]="{'active-route': globals.rotaAtual === '/assembleias'}">Assembleias</a>
            </li> -->
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_ELEITOR[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/home-eleitor"
          [ngClass]="{ 'active-route': globals.rotaAtual === '/home-eleitor' }"
          >Eleições</a
        >
      </li>
      <!-- <li class="nav-item" class="btns-header" *ngIf="user.roles[0] === 'SEVI_ELEITOR[*]'">
                <a class="nav-link labels-headers" href="#/eleitor-assembleias" [ngClass]="{'active-route': globals.rotaAtual === '/eleitor-assembleias'}">Assembleias</a>
            </li> -->
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_SIND[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/eleitores"
          [ngClass]="{ 'active-route': globals.rotaAtual === '/eleitores' }"
          >Eleitores</a
        >
      </li>
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_SIND[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/apuracao"
          [ngClass]="{ 'active-route': globals.rotaAtual === '/apuracao' }"
          >Apuração</a
        >
      </li>
      <li
        class="nav-item"
        class="btns-header"
        *ngIf="user.roles[0] === 'SEVI_SIND[*]'"
      >
        <a
          class="nav-link labels-headers"
          href="#/perfil"
          [ngClass]="{ 'active-route': globals.rotaAtual === '/perfil' }"
          >Perfil</a
        >
      </li>
    </ul>
    <ul class="navbar-nav flex-row ml-md-auto d-md-flex">
      <li class="nav-item dropdown">
        <a
          class="user-name labels-headers nav-link"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ name.toUpperCase()
          }}<img class="user-logo" src="assets/images/user.png" />
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <!-- <p *ngIf="user.roles[0] !== 'SEVI_ADM[*]'" class="dropdown-item" (click)="changePassword()"><i class="fas fa-unlock-alt"></i> Trocar Senha</p> -->
          <p class="dropdown-item" (click)="changePassword()">
            <i class="fas fa-unlock-alt"></i> Trocar Senha
          </p>
          <div class="dropdown-divider"></div>
          <p class="dropdown-item" (click)="logout()">
            <i class="fas fa-sign-out-alt"></i>Sair
          </p>
        </div>
      </li>
    </ul>
  </div>
</nav>
