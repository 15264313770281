import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-modal-trocar-senha',
  templateUrl: './modal-trocar-senha.component.html',
  styleUrls: ['./modal-trocar-senha.component.css']
})
export class ModalTrocarSenhaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  //Função para ficar assistindo se a senha e senha de confirmação são iguais.
  private checkPasswords(group: FormGroup) {
    let pass = group.get('senha').value;
    let confirmPass = group.get('senhaConf').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  public form: FormGroup;
  public submitted: boolean = false;
  public ocultarSenha = true;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      cpf: [this.item.cpf],
      senha: [null,
        Validators.required,
        // Validators.pattern("/^{8,}$/")] //Regex para verificar a existência de pelo menos uma letra( Maiúscula e Minúscula ), um numero e no mínimo 8 caracteres.
      ],
      senhaConf: [null,
        Validators.required,
        // Validators.pattern("/^{8,}$/")] //Regex para verificar a existência de pelo menos uma letra( Maiúscula e Minúscula ), um numero e no mínimo 8 caracteres.
      ],
    }, { validator: this.checkPasswords });
  }

  exibirSenhas() {
    if ($("#senha").attr("type") === "password") {
      document.getElementById("senha").setAttribute("type", "text")
      document.getElementById("senhaConf").setAttribute("type", "text")
      this.ocultarSenha = false;
    } else {
      document.getElementById("senha").setAttribute("type", "password")
      document.getElementById("senhaConf").setAttribute("type", "password")
      this.ocultarSenha = true;
    }
  }

  async trocar() {
    if (this.form.valid) {
      this.blockUi.start("Carregando");
      try {
        await this.api.postGeneric(environment.urlBase + environment.paths.usuarioAlteraSenha, { "login": this.authService.currentUserValue.login, "senha": this.form.get("senha").value });
        this.toaster.pop("success", "SEVI", "Senha alterada com sucesso, logue novamente na plataforma");
        this.blockUi.stop();
        this.authService.logout();
      } catch (err) {
        console.error("[[ERRO PUT SENHA]] => ", err);
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise a equipe do edital.");
        this.blockUi.stop();
      }
    }
  }

}
