<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Votação</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <div *ngIf="this.votos.length <= 0" style="text-align: center;">
        <h5>Votos já computados</h5>
    </div>

    <div  *ngIf="this.votos.length > 0" class="row">
        <div class="col-12" style="display: flex;justify-content: space-between;">
            <button type="button" class="btn btn-outline-success" [disabled]="stepIndex === 1"
                (click)="selectPauta('anterior')">Anterior</button>
            <div style="display: flex;">
                <div class="pauta-index">
                    {{stepIndex}}ª Pauta
                </div>
            </div>
            <button type="button" class="btn btn-outline-success" *ngIf="stepIndex !== pautas.length"
                (click)="selectPauta('proxima')">Próxima</button>
            <button type="button" class="btn btn-outline-success" *ngIf="stepIndex === pautas.length"
                (click)="finalizarVoto()">Finalizar Votação</button>
        </div>
        <!-- <hr style="width: 100%;"> -->
        <div class="col-12" style="margin-top: 15px;">
            <div *ngFor="let p of pautas">
                <div *ngIf="p.index === stepIndex">
                    <p style="margin-bottom: 5px!important;">{{p.nome}}</p>
                    <label class="labels lable-custom">Descrição</label>
                    <p style="margin-bottom: 5px!important;">{{p.descricao}}</p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div *ngFor="let v of votos">
                <div style="display: flex; align-items: baseline;">
                    <p style="width: fit-content; margin: 0px; white-space: pre; color: #27a845; font-weight: bold;">
                        {{v.eleitorRepresentado === null ? "Voto do eleitor logado" : "Voto representando " +
                        v.eleitorRepresentado}}</p>
                    <hr style="width: 100%; margin: 0px 0px 0px 15px; border-top: 1px solid #27a845;">
                </div>
                <div *ngFor="let vv of v.votos">
                    <div *ngIf="this.pautas[stepIndex - 1]._id === vv.idPauta">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"
                                [name]="vv.idPauta + 'aprovo' + v.eleitorRepresentado"
                                [id]="vv.idPauta + 'aprovo' + v.eleitorRepresentado" value="APROVO"
                                [(ngModel)]="vv.voto">
                            <label class="form-check-label"
                                [for]="vv.idPauta + 'aprovo' + v.eleitorRepresentado">Aprovo</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"
                                [name]="vv.idPauta + 'reprovo' + v.eleitorRepresentado"
                                [id]="vv.idPauta + 'reprovo' + v.eleitorRepresentado" value="REPROVO"
                                [(ngModel)]="vv.voto">
                            <label class="form-check-label"
                                [for]="vv.idPauta + 'reprovo' + v.eleitorRepresentado">Reprovo</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio"
                                [name]="vv.idPauta + 'abstenho' + v.eleitorRepresentado"
                                [id]="vv.idPauta + 'abstenho' + v.eleitorRepresentado" value="ABSTENHO"
                                [(ngModel)]="vv.voto">
                            <label class="form-check-label" [for]="vv.idPauta + 'abstenho' + v.eleitorRepresentado">Me
                                abstenho</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>