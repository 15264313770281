import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-modal-correcao-eleitor',
  templateUrl: './modal-correcao-eleitor.component.html',
  styleUrls: ['./modal-correcao-eleitor.component.css']
})
export class ModalCorrecaoEleitorComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      nome: [null, Validators.required],
      cpf: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      empresa: [null]
    });
    this.form.patchValue(this.item.obj);
  }

  checkCpf(cpf) {
    if (this.util.checkVariavel(cpf)) {
      if (!this.trataDocs.validaCPF(cpf)) {
        this.toaster.pop("error", "SEVI", "CPF inválido, verifique e tente novamente.")
        this.form.patchValue({ cpf: null })
      }
    }
  }

  salvar() {
    this.submitted = true;
    if (this.form.valid && this.trataDocs.validaCPF(this.form.get('cpf').value)) {
      this.modal.close(this.form.getRawValue());
    } else {
      this.checkCpf(this.form.get('cpf').value);
    }
  }
}
