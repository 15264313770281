import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { Role } from './models/role.enum';
import * as $ from 'jquery';
import { ApuracaoComponent } from './pages/apuracao/apuracao.component';
import { HomeAdmComponent } from './pages/home-adm/home-adm.component';
import { HomeEleitorComponent } from './pages/home-eleitor/home-eleitor.component';
import { HomeSindComponent } from './pages/home-sind/home-sind.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { GlobalsService } from './services/globals.service';
import { EleitoresComponent } from './pages/eleitores/eleitores.component';
import { ListaPresencaComponent } from './pages/lista-presenca/lista-presenca.component';
import { AssembleiasComponent } from './pages/assembleias/assembleias.component';
import { EleitorAssembleiasComponent } from './pages/eleitor-assembleias/eleitor-assembleias.component';
import { ListaPresencaAssembleiasComponent } from './pages/lista-presenca-assembleias/lista-presenca-assembleias.component';
import { DashboardApuracaoAssembleiasComponent } from './pages/dashboard-apuracao-assembleias/dashboard-apuracao-assembleias.component';
import { DashboardApuracaoEleicoesComponent } from './pages/dashboard-apuracao-eleicoes/dashboard-apuracao-eleicoes.component';
import { SindicatoPerfilComponent } from './pages/sindicato-perfil/sindicato-perfil.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'home-administrador',
    component: HomeAdmComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.ADM] },
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.ADM] },
  },
  {
    path: 'apuracao',
    component: ApuracaoComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'perfil',
    component: SindicatoPerfilComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'home-sindicatos',
    component: HomeSindComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'assembleias',
    component: AssembleiasComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'lista-presenca/:eleicaoId',
    component: ListaPresencaComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'apuracao-eleicao/:eleicaoId',
    component: DashboardApuracaoEleicoesComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'lista-presenca-assembleia/:assembleiaId',
    component: ListaPresencaAssembleiasComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'apuracao-assembleia/:assembleiaId',
    component: DashboardApuracaoAssembleiasComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'eleitores',
    component: EleitoresComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SIND] },
  },
  {
    path: 'home-eleitor',
    component: HomeEleitorComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.ELEITOR] },
  },
  {
    path: 'eleitor-assembleias',
    component: EleitorAssembleiasComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.ELEITOR] },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private globals: GlobalsService,
    private authService: AuthenticationService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let user = this.authService.currentUserValue;
        this.globals.urlAtual = this.router.url;
        if (sessionStorage.getItem('currentUser')) {
          $('.conteudo').css('padding-top', '110px');
          this.globals.logado = true;
          this.globals.rotaAtual = this.router.url;
          if (this.router.url === '/') {
            switch (user.roles[0]) {
              case 'SEVI_ADM[*]':
                this.router.navigate(['/home-administrador']);
                break;
              case 'SEVI_SIND[*]':
                this.router.navigate(['/home-sindicatos']);
                break;
              case 'SEVI_ELEITOR[*]':
                this.router.navigate(['/home-eleitor']);
                break;
              default:
                break;
            }
          }
        } else {
          this.globals.logado = false;
          this.globals.rotaAtual = this.router.url;
          if (
            this.router.url === '/' ||
            this.router.url === '/cadastro-avaliador'
          ) {
            $('.conteudo').css('padding-top', '10px');
          }
        }
      }
    });
  }
}
