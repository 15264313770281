<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sessão Expirada</h4>
</div>
<div class="modal-body">
    <p>Sua sessão expirou, para seguir utilizando o sistema por favor logue novamente.</p>
    <form [formGroup]="form">
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Usuário<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="user" formControlName="user" [ngClass]="{'invalido':form.get('user').invalid && submitted}">
                <p class="erro" *ngIf="form.get('user').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-12">
                <label class="labels">Senha<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="password" id="password" formControlName="password" [ngClass]="{'invalido':form.get('password').invalid && submitted}">
                <p class="erro" *ngIf="form.get('password').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="acoes col-12">
                <label class="acao" (click)="exibirSenha()">{{ocultarSenha ? 'Mostrar Senha' : 'Ocultar Senha'}}</label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="logout()">Sair do Sistema</button>
    <button type="button" class="btn btn-outline-success" (click)="login()">Logar</button>
</div>