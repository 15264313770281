import { User } from 'src/app/models/user';
import { Usuario } from './../../models/usuario';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HardCodeService } from 'src/app/services/hard-code.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Role } from 'src/app/models/role.enum';

@Component({
  selector: 'app-modal-add-user',
  templateUrl: './modal-add-user.component.html',
  styleUrls: ['./modal-add-user.component.css'],
})
export class ModalAddUserComponent implements OnInit {
  @Input() public tipo;
  @Input() public item;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public submitted: boolean = false;

  @Input() name;
  @BlockUI() blockUi: NgBlockUI;

  newUser = {} as User;

  constructor(
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService,
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    public hardCode: HardCodeService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cpf_cnpj: [null, Validators.required],
      nome: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      tipo: [null, Validators.required],
      login: [null, Validators.required],
      password: [null, Validators.required],
    });

    if (this.item.tipo === 'view') {
      this.form.patchValue(this.item.obj);
      this.form.disable();
    } else if (this.item.tipo === 'edit') {
      this.form.patchValue(this.item.obj);
      this.form.get('cpf_cnpj').disable();
    }
  }

  // validar cpf de todos os usurios da base
  async verificaCnpj(cnpj) {
    if (this.trataDocs.validaCNPJ(cnpj)) {
      // try {
      //   const resp = await this.api.getGeneric(environment.urlBase + environment.paths.sindicatosCnpj + `/${this.trataDocs.limpaDoc(cnpj)}`);
      //   if (resp.body.documento) {
      //     this.form.patchValue({ cnpj: null });
      //     this.toaster.pop("error", "SEVI", "O CNPJ informado já possui cadastro na plataforma.");
      //   }
      // } catch (err) {
      //   if (err.error.statusCode !== 404) {
      //     console.error("[[ERRO GET SINDICATO]] => ", err)
      //   }
      // }
    } else {
      this.form.patchValue({ cnpj: null });
      this.toaster.pop(
        'error',
        'SEVI',
        'CNPJ inválido, verifique o mesmo e tente novamente.'
      );
    }
  }

  async verificaCnf(cnpj) {
    if (this.trataDocs.validaCPF(cnpj)) {
      // try {
      //   const resp = await this.api.getGeneric(environment.urlBase + environment.paths.eleitoresCpf + `/${this.trataDocs.limpaDoc(cnpj)}`);
      //   if (resp.body.documento) {
      //     this.form.patchValue({ cnpj: null });
      //     this.toaster.pop("error", "SEVI", "O CPF informado já possui cadastro na plataforma.");
      //   }
      // } catch (err) {
      //   if (err.error.statusCode !== 404) {
      //     console.error("[[ERRO GET ELEITOR CPF]] => ", err)
      //   }
      // }
    } else {
      this.form.patchValue({ cnpj: null });
      this.toaster.pop(
        'error',
        'SEVI',
        'CPF inválido, verifique o mesmo e tente novamente.'
      );
    }
  }

  async verificarLogin() {}

  passBack() {
    this.modal.close();
  }

  async salvar() {
    // let tipo = this.form.get('tipo').value
    // switch (tipo) {
    //   case 'SEVI_ADM[*]':
    //     console.log('SALVAR ADM');
    //     break;

    //   case 'SEVI_SIND[*]':
    //     console.log('SALVAR ADM');
    //     break;

    //   case 'SEVI_ELEITOR[*]':
    //     console.log('SALVAR eleitor');
    //     break;

    //   default:
    //     break;
    // }

    this.submitted = true;
    if (this.form.valid) {
      this.blockUi.start('Salvando os dados');
      try {
        let resp: any = {};

        this.newUser.nome = this.form.get('nome').value;
        this.newUser.email = this.form.get('email').value;
        this.newUser.cpf = this.trataDocs.limpaDoc(
          this.form.get('cpf_cnpj').value
        );
        let roles = [];
        roles.push(this.form.get('tipo').value);
        this.newUser.roles = roles;
        this.newUser.login = this.form.get('login').value;
        this.newUser.password = this.form.get('password').value;
        resp = this.api.postGeneric(
          environment.urlBase + environment.paths.usuario,
          this.newUser
        );
        this.blockUi.stop();
        resp.then((data) => {
          this.newUser = data.body.usuario;
          this.toaster.pop('success', 'SEVI', 'Usuário cadastrado');
          this.modal.close(this.newUser);
        });
        // newUser = resp.body.documento;

        // sindicato = resp.body.documento;
        // if(resp) {

        // }
      } catch (err) {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista abra um BPM.'
        );
        console.error('[[ERRO POST USUARIO]] => ', err);
      }
    }
  }

  // private async _criarLogin() {
  //   try {
  //     await this.api.postGeneric(environment.urlBase + environment.paths.usuario, {
  //       "nome": this.form.get("nome").value,
  //       "email": this.form.get("email").value,
  //       "cpf_cnpj": this.trataDocs.limpaDoc(this.form.get("cnpj").value),
  //       "role": "SEVI_SIND[*]"
  //     }
  //     )
  //   } catch (err) {
  //     console.error("[[ERROR POST USUARIO]] => ", err)
  //   }
  // }
}
