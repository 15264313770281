<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{item.tipo === "edit" ? "Edição de" : "Novo"}} Horário (Convocação)</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <label class="labels">Título da Convocação<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="chamada" formControlName="chamada"
                    [ngClass]="{'invalido':form.get('chamada').invalid && submitted}">
                <p class="erro" *ngIf="form.get('chamada').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <label class="labels">Data Inicio<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="dataInicio" formControlName="dataInicio" (change)="validaCampos('dataInicio')"
                    [ngClass]="{'invalido':form.get('dataInicio').invalid && submitted}" [textMask]="{mask: globals.masks.data, guide: false}" >
                <p class="erro" *ngIf="form.get('dataInicio').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <label class="labels">Hora Inicio<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="horaInicio" formControlName="horaInicio" (change)="validaCampos('horaInicio')"
                    [ngClass]="{'invalido':form.get('horaInicio').invalid && submitted}" [textMask]="{mask: globals.masks.hora, guide: false}">
                <p class="erro" *ngIf="form.get('horaInicio').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <label class="labels">Data Fim<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="dataFim" formControlName="dataFim" (change)="validaCampos('dataFim')"
                    [ngClass]="{'invalido':form.get('dataFim').invalid && submitted}" [textMask]="{mask: globals.masks.data, guide: false}">
                <p class="erro" *ngIf="form.get('dataFim').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
                <label class="labels">Hora Fim<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="horaFim" formControlName="horaFim" (change)="validaCampos('horaFim')"
                    [ngClass]="{'invalido':form.get('horaFim').invalid && submitted}" [textMask]="{mask: globals.masks.hora, guide: false}">
                <p class="erro" *ngIf="form.get('horaFim').invalid && submitted">Campo Obrigatório</p>
            </div>
        </div>
     
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" (click)="salvar()">{{item.tipo === "new" ? "Adicionar" : "Editar"}}</button>
</div>