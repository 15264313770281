import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class TrataDocsService {

  constructor(
  ) { }

  validaCPF = function (cpf) {
    if (cpf !== null && cpf !== undefined && cpf !== "") {
      cpf = cpf.replace(/[^\d]+/g, '');
      var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11) {
        return false;
      }
      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      }
      if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--) {
          soma += numeros.charAt(10 - i) * i;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) {
          return false;
        }
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--) {
          soma += numeros.charAt(11 - i) * i;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) {
          return false;
        }
        return true;
      }
      else {
        return false;
      }
    } else {
      return false;
    }
  }

  validaCNPJ = function (cnpj) {
    if (cnpj !== null && cnpj !== undefined && cnpj !== "") {
      cnpj = cnpj.replace(/[^\d]+/g, '');
      var tamanho, numeros, digitos, soma, pos, resultado, i;
      if (cnpj == '') {
        return false;
      }
      if (cnpj.length != 14) {
        return false;
      }
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || cnpj == "11111111111111" || cnpj == "22222222222222" || cnpj == "33333333333333" ||
        cnpj == "44444444444444" || cnpj == "55555555555555" || cnpj == "66666666666666" || cnpj == "77777777777777" ||
        cnpj == "88888888888888" || cnpj == "99999999999999") {
        return false;
      }
      // Valida DVs
      tamanho = cnpj.length - 2
      numeros = cnpj.substring(0, tamanho);
      digitos = cnpj.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
        return false;
      }
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  limpaDoc = function (doc) {
    var docLimpo = doc.toString();
    docLimpo = docLimpo.replace('.', '');
    docLimpo = docLimpo.replace('.', '');
    docLimpo = docLimpo.replace('-', '');
    docLimpo = docLimpo.replace('/', '');
    return docLimpo;
  }

  verificaTamanhoCpf(cpf) {
    if (cpf !== null && cpf !== undefined && cpf !== "") {
      var cpfTratado = this.limpaDoc(cpf).toString();
      if (cpfTratado.length < 11) {
        var x = (11 - cpfTratado.length);
        for (var i = 0; i < x; i++) {
          cpfTratado = "0" + cpfTratado;
        }
        return cpfTratado;
      } else {
        return cpfTratado;
      }
    } else {
      return null;
    }
  }

  addMaskCep(cep) {
    if (cep !== null && cep !== undefined && cep !== "") {
      return cep.replace(/(\d{5})(\d{2})/g, "\$1\-\$2");
    }
  }

  addMaskCpf(cpf) {
    if (cpf !== null && cpf !== undefined && cpf !== "") {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }
  }

  addMaskCnpj(cnpj) {
    if (cnpj !== null && cnpj !== undefined && cnpj !== "") {
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
  }

}
