import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalAssembleiasComponent } from 'src/app/modais/modal-assembleias/modal-assembleias.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ModalPautasComponent } from 'src/app/modais/modal-pautas/modal-pautas.component';
import { ModalSelecionarEleitoresComponent } from 'src/app/modais/modal-selecionar-eleitores/modal-selecionar-eleitores.component';
import { ModalProcuracaoComponent } from 'src/app/modais/modal-procuracao/modal-procuracao.component';
moment.locale('pt-br');

@Component({
  selector: 'app-assembleias',
  templateUrl: './assembleias.component.html',
  styleUrls: ['./assembleias.component.css']
})
export class AssembleiasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public momentUse = moment;
  public assembleias = [];
  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  public opcoes = [5, 10, 25, 50];

  private ultimaPagCarregada = 1;
  private infoSindicato: any = {};

  constructor(
    private modal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService,
    private authService: AuthenticationService,
    private trataDocs: TrataDocsService,
    private trataDatas: TrataNumeros
  ) { }

  ngOnInit(): void {
    this._getInfoSindicato();
  }

  private async _getInfoSindicato() {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.sindicatosCnpj + `/${this.authService.currentUserValue.nomeCompleto}`);
      this.infoSindicato = resp.body.documento;
      this._getAssembleias();
    } catch (err) {
      console.error("[[ERRO GET INFO SINDICATO]]")
    }
  }

  private async _getAssembleias() {
    try {
      this.blockUi.start("Carregando as informações")
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.assembleias + `?idSindicato=${this.infoSindicato._id}`);
      resp.body.documento.forEach(e => {
        this.assembleias.push(e);
      });
      this.numRegistros = this.assembleias.length;
      this.blockUi.stop()
    } catch (err) {
      this.blockUi.stop()
      console.error("[[ERRO GET ASSEMBLEIAS]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista abra um BPM.");
    }
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.assembleias = [];
    this.blockUi.start("Carregando");
    setTimeout(() => {
      this._getAssembleias();
      this.blockUi.stop();
    }, 1500);
  }

  modalAssembleias(tipo, assembleia?) {
    if (assembleia) {
      if (tipo === "view") {
        this._openModalAssembleias(tipo, assembleia);
      } else {
        // if (this._checkPeriodo(assembleia)) {
        this._openModalAssembleias(tipo, assembleia);
        // } else {
        //   this.toaster.pop("error", "SEVI", "O período desta assembleia já esta fechado, não é possível editar a mesma.");
        // }
      }
    } else {
      this._openModalAssembleias(tipo);
    }
  }

  private _openModalAssembleias(tipo, assembleia?) {
    if (tipo === "edit") {
      if (this.trataDatas.validaDataHoraMaior(moment().format("L LT"), moment(assembleia.chamadas[assembleia.chamadas.length - 1].dataFim).add(3, 'hours').format("L LT"))) {
        this.toaster.pop("error", "SEVI", "O período de todas as chamadas desta assembleia já estão fechados, não é possível editar a mesma.");
        return;
      }
    }
    const modalRef = this.modal.open(ModalAssembleiasComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: tipo,
      obj: assembleia,
      idSindicato: this.infoSindicato._id
    }
    modalRef.result.then(res => {
      if (res) {
        if (assembleia) {
          let index = this.assembleias.findIndex(s => s._id === assembleia._id);
          this.assembleias[index] = res;
        } else {
          this.assembleias.push(res);
        }
      }
    });
  }

  public async openModalPautas(assembleia) {
    let existeVotos = false;
    let periodoFechado = false;
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.urnaAssembleia + `?idAssembleia=${assembleia._id}`);
      existeVotos = resp.body.documento.length > 0
    } catch (err) {
      console.error("[[ERRO GET VOTOS ASSEMBLEIA]]")
    }

    const modalRef = this.modal.open(ModalPautasComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      chamadas: assembleia.chamadas,
      idAssembleia: assembleia._id,
      existeVotos: existeVotos,
      periodoFechado: this.trataDatas.validaDataHoraMaior(moment().format("L LT"), moment(assembleia.chamadas[assembleia.chamadas.length - 1].dataFim).add(3, 'hours').format("L LT"))
    }
    modalRef.result.then(res => {
      if (res) { }
    });
  }

  selecionarEleitores(assembleia) {
    if (this.trataDatas.validaDataHoraMaior(moment().format("L LT"), moment(assembleia.chamadas[assembleia.chamadas.length - 1].dataFim).add(3, 'hours').format("L LT"))) {
      this.toaster.pop("error", "SEVI", "O período de todas as chamadas desta assembleia já estão fechados, não é possível gerenciar os eleitores.");
      return;
    }
    const modalRef = this.modal.open(ModalSelecionarEleitoresComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: "assembleia",
      obj: assembleia,
      idSindicato: this.infoSindicato._id
    }
    modalRef.result.then(res => {
      if (res) {
        let index = this.assembleias.findIndex(e => e._id === assembleia._id)
        this.assembleias[index] = res;
      }
    });
  }

  submeterProcuracao(assembleia) {
    if (this.trataDatas.validaDataHoraMaior(moment().format("L LT"), moment(assembleia.chamadas[assembleia.chamadas.length - 1].dataFim).add(3, 'hours').format("L LT"))) {
      this.toaster.pop("error", "SEVI", "O período de todas as chamadas desta assembleia já estão fechados, não é possível gerenciar os eleitores.");
      return;
    }
    const modalRef = this.modal.open(ModalProcuracaoComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: "assembleia",
      idAssembleia: assembleia._id,
      idSindicato: assembleia.idSindicato
    }
    modalRef.result.then(async res => {
      try {
        let index = this.assembleias.findIndex(e => e._id === assembleia._id);
        const resp = await this.api.getGenericId(environment.urlBase + environment.paths.assembleias, assembleia._id);
        this.assembleias[index] = resp.body.documento;
      } catch (err) {
        console.error("[[ERRO GET ASSEMBLEIAS]] => ", err)
      }
    });
  }

}
