import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule } from 'angular2-toaster';
import { AutosizeModule } from 'ngx-autosize';
import { TextMaskModule } from 'angular2-text-mask';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { ModalTrocarSenhaComponent } from './modais/modal-trocar-senha/modal-trocar-senha.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeAdmComponent } from './pages/home-adm/home-adm.component';
import { HomeEleitorComponent } from './pages/home-eleitor/home-eleitor.component';
import { HomeSindComponent } from './pages/home-sind/home-sind.component';
import { HeaderComponent } from './components/header/header.component';
import { ApuracaoComponent } from './pages/apuracao/apuracao.component';
import { ModalSindicatoComponent } from './modais/modal-sindicato/modal-sindicato.component';
import { ModalLoginComponent } from './modais/modal-login/modal-login.component';
import { ModalResetSenhaComponent } from './modais/modal-reset-senha/modal-reset-senha.component';
import { ModalConfirmacaoComponent } from './modais/modal-confirmacao/modal-confirmacao.component';
import { EleitoresComponent } from './pages/eleitores/eleitores.component';
import { ModalEleicoesComponent } from './modais/modal-eleicoes/modal-eleicoes.component';
import { ModalEleitoresComponent } from './modais/modal-eleitores/modal-eleitores.component';
import { ModalChapaComponent } from './modais/modal-chapa/modal-chapa.component';
import { ModalGerenciaChapasComponent } from './modais/modal-gerencia-chapas/modal-gerencia-chapas.component';
import { ModalUploadEleitoresComponent } from './modais/modal-upload-eleitores/modal-upload-eleitores.component';
import { ModalCorrecaoEleitorComponent } from './modais/modal-correcao-eleitor/modal-correcao-eleitor.component';
import { ModalSelecionarEleitoresComponent } from './modais/modal-selecionar-eleitores/modal-selecionar-eleitores.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng2ScreenshotModule } from 'ng2-screenshot';
import { CustomPieDonutChartComponent } from './components/custom-pie-donut-chart/custom-pie-donut-chart.component';
import { ModalVotacaoChapaComponent } from './modais/modal-votacao-chapa/modal-votacao-chapa.component';
import { CustomVerticalBarChartComponent } from './components/custom-vertical-bar-chart/custom-vertical-bar-chart.component';
import { ModalAvisoComponent } from './modais/modal-aviso/modal-aviso.component';
import { ModalProcuracaoComponent } from './modais/modal-procuracao/modal-procuracao.component';
import { ModalAddProcuracaoComponent } from './modais/modal-add-procuracao/modal-add-procuracao.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListaPresencaComponent } from './pages/lista-presenca/lista-presenca.component';
import { AssembleiasComponent } from './pages/assembleias/assembleias.component';
import { ModalAssembleiasComponent } from './modais/modal-assembleias/modal-assembleias.component';
import { ModalAddChamadaComponent } from './modais/modal-add-chamada/modal-add-chamada.component';
import { ModalPautasComponent } from './modais/modal-pautas/modal-pautas.component';
import { ModalAddPautaComponent } from './modais/modal-add-pauta/modal-add-pauta.component';
import { EleitorAssembleiasComponent } from './pages/eleitor-assembleias/eleitor-assembleias.component';
import { ModalVotacaoAssembleiaComponent } from './modais/modal-votacao-assembleia/modal-votacao-assembleia.component';
import { ListaPresencaAssembleiasComponent } from './pages/lista-presenca-assembleias/lista-presenca-assembleias.component';
import { DashboardApuracaoAssembleiasComponent } from './pages/dashboard-apuracao-assembleias/dashboard-apuracao-assembleias.component';
import { DashboardApuracaoEleicoesComponent } from './pages/dashboard-apuracao-eleicoes/dashboard-apuracao-eleicoes.component';
import { ModalAddUserComponent } from './modais/modal-add-user/modal-add-user.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ModelEditUserComponent } from './components/model-edit-user/model-edit-user.component';
import { ModalEditarUsuarioComponent } from './modais/modal-editar-usuario/modal-editar-usuario.component';
import { ModalEditUserComponent } from './modais/modal-edit-user/modal-edit-user.component';
import { ModalViewUserComponent } from './modais/modal-view-user/modal-view-user.component';
import { ModalUploadSindComponent } from './modais/modal-upload-sind/modal-upload-sind.component';
import { ModalCorrecaoSindComponent } from './modais/modal-correcao-sind/modal-correcao-sind.component';
import { SindicatoPerfilComponent } from './pages/sindicato-perfil/sindicato-perfil.component';
import { ModalEditSindicatoComponent } from './modais/modal-edit-sindicato/modal-edit-sindicato.component';

@NgModule({
  declarations: [
    AppComponent,
    TableFilterPipe,
    LoginComponent,
    HomeAdmComponent,
    HomeEleitorComponent,
    HomeSindComponent,
    SindicatoPerfilComponent,
    HeaderComponent,
    ApuracaoComponent,
    ModalTrocarSenhaComponent,
    ModalSindicatoComponent,
    ModalLoginComponent,
    ModalResetSenhaComponent,
    ModalConfirmacaoComponent,
    EleitoresComponent,
    ModalEleicoesComponent,
    ModalEleitoresComponent,
    ModalChapaComponent,
    ModalGerenciaChapasComponent,
    ModalUploadEleitoresComponent,
    ModalCorrecaoEleitorComponent,
    ModalSelecionarEleitoresComponent,
    ModalVotacaoChapaComponent,
    CustomPieDonutChartComponent,
    CustomVerticalBarChartComponent,
    ModalAvisoComponent,
    ModalProcuracaoComponent,
    ModalAddProcuracaoComponent,
    ListaPresencaComponent,
    AssembleiasComponent,
    ModalAssembleiasComponent,
    ModalAddChamadaComponent,
    ModalPautasComponent,
    ModalAddPautaComponent,
    EleitorAssembleiasComponent,
    ModalVotacaoAssembleiaComponent,
    ListaPresencaAssembleiasComponent,
    DashboardApuracaoAssembleiasComponent,
    DashboardApuracaoEleicoesComponent,
    ModalAddUserComponent,
    UsuariosComponent,
    ModelEditUserComponent,
    ModalEditarUsuarioComponent,
    ModalEditUserComponent,
    ModalEditSindicatoComponent,
    ModalViewUserComponent,
    ModalUploadSindComponent,
    ModalCorrecaoSindComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    BlockUIModule.forRoot(),
    BrowserAnimationsModule,
    ToasterModule.forRoot(),
    AutosizeModule,
    TextMaskModule,
    NgxChartsModule,
    Ng2ScreenshotModule,
    NgSelectModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalTrocarSenhaComponent,
    ModalSindicatoComponent,
    ModalLoginComponent,
    ModalResetSenhaComponent,
    ModalConfirmacaoComponent,
    ModalEleicoesComponent,
    ModalEleitoresComponent,
    ModalChapaComponent,
    ModalGerenciaChapasComponent,
    ModalUploadEleitoresComponent,
    ModalCorrecaoEleitorComponent,
    ModalSelecionarEleitoresComponent,
    ModalVotacaoChapaComponent,
    ModalAvisoComponent,
    ModalProcuracaoComponent,
    ModalAddProcuracaoComponent,
    ModalAssembleiasComponent,
    ModalAddChamadaComponent,
    ModalPautasComponent,
    ModalAddPautaComponent,
    ModalVotacaoAssembleiaComponent,
  ],
})
export class AppModule {}
