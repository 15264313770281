<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Esqueci Minha Senha</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12" style="text-align: justify;">
                <p>Esta opção é destinada aos usuários que realizam o login na plataforma através de seu CNPJ ou CPF, usuários do Sistema FIESC devem solicitar ajuda ao Service Desk no Ramal 54699.</p>
                <p>Ao solicitar uma nova senha você irá recebe-la em seu e-mail cadastrado na plataforma.</p>
            </div>
            <div class="col-12">
                <label class="labels">CNPJ ou CPF<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="cpf" formControlName="cpf" (blur)="checkCnpj(form.get('cpf').value)" placeholder="Informe somente os números de seu CNPJ ou CPF" [ngClass]="{'invalido':form.get('cpf').invalid && submitted}">
                <p class="erro" *ngIf="form.get('cpf').invalid && submitted">Campo Obrigatório</p>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-outline-success" (click)="reset()">Solicitar Nova Senha</button>
</div>