<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        Pautas da Assembleia</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row col-12" style="justify-content: space-between; align-items: center;">
        <h3 class="titulo">Lista de Pautas</h3>
        <button *ngIf="!item.existeVotos && !item.periodoFechado" type="button" class="btn btn-outline-success"
            (click)="openModalAddPauta('new')"><i class="fas fa-plus"></i> Adicionar Pauta</button>
    </div>

    <div *ngIf="item.existeVotos" class="col-12 alerta">
            <p style="margin: 0;">Esta assembleia já possui votos computados por este motivo não é mais permitido adicionar ou remover pautas, assim como realizar a reordenação de apresentação das mesmas.</p>
            <p style="margin: 0;">Desta forma garantimos que todos os eleitores possuirão a mesma visualização das pautas a serem votadas.</p>
    </div>
    
    <div class="row">
        <div class="col-12" *ngIf="pautas.length <= 0">
            <p class="erro">Não existem pautas cadastradas para esta assembleia</p>
        </div>
        <div class="col-12" *ngIf="pautas.length > 0">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Ordem de Apresentação</th>
                        <th class="th-rigth-border">Pauta</th>
                        <th class="th-rigth-border">Ação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of pautas">
                        <td>
                            <i *ngIf="item.tipo !== 'view' && (!item.existeVotos && !item.periodoFechado)" style="margin-right: 5px"
                                class="fas fa-arrow-up acoes" placement="bottom" ngbTooltip="Subir na apresentação"
                                (click)="setOrdem('UP', p.index)"></i>
                            {{ p.index}}ª pauta
                            <i *ngIf="item.tipo !== 'view'  && (!item.existeVotos && !item.periodoFechado)" class="fas fa-arrow-down acoes"
                                placement="bottom" ngbTooltip="Descer na apresentação"
                                (click)="setOrdem('DOWN', p.index)"></i>
                        </td>
                        <td>{{ p.nome }}</td>
                        <td>
                            <i *ngIf="item.tipo !== 'view' && !item.existeVotos && !item.periodoFechado" class="fas fa-edit acoes"
                                style="padding-left: 5px" placement="bottom" ngbTooltip="Editar"
                                (click)="openModalAddPauta('edit', p)"></i>
                            <i *ngIf="item.tipo !== 'view' && !item.existeVotos && !item.periodoFechado" class="far fa-trash-alt acoes"
                                placement="bottom" ngbTooltip="Excluir"></i>
                            <i *ngIf="item.existeVotos || item.periodoFechado" class="far fa-eye acoes" placement="bottom" ngbTooltip="Visualizar"
                                (click)="openModalAddPauta('view', p)"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
</div>