import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { UtilService } from 'src/app/services/util.service';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
moment.locale('pt-br')
@Component({
  selector: 'app-modal-add-procuracao',
  templateUrl: './modal-add-procuracao.component.html',
  styleUrls: ['./modal-add-procuracao.component.css']
})
export class ModalAddProcuracaoComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  public docs: any[] = [];
  public filesDelete: any[] = [];
  public new = true;
  public eleitoresRepresentantes = [];
  public eleitoresRepresentados = [];
  private eleitoresStr = "";

  private comFalha = false;
  private evento: any = {};
  private votos = [];
  private procuracoes = [];

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService
  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      idEleitor: [null, Validators.required],
      idRepresentado: [null, Validators.required],
      doc: [null],
      empresa: [null, Validators.required],
      nomeEleitor: [null, Validators.required],
      nomeRepresentado: [null, Validators.required]
    });

    if (this.item.idEleicao) {
      this.form.addControl("idEleicao", new FormControl( this.item.idEleicao))
    } else {
      this.form.addControl("idAssembleia", new FormControl( this.item.idAssembleia))
    }

    this.eleitoresStr = JSON.stringify(this.item.eleitores)
    this.eleitoresRepresentantes = JSON.parse(this.eleitoresStr);
    this.eleitoresRepresentados = JSON.parse(this.eleitoresStr);
    await this._getEvento();
    await this._getVotosEvento();
    await this._getProcuracoesEvento();
    this._limpaListas();
  }

  private async _getEvento() {
    try {
      let resp;
      if (this.item.idEleicao) {
        resp = await this.api.getGenericId(environment.urlBase + environment.paths.eleicoes, this.item.idEleicao);
        this.evento = resp.body.documento;
      } else {
        resp = await this.api.getGenericId(environment.urlBase + environment.paths.assembleias, this.item.idAssembleia);
        this.evento = resp.body.documento;
        this.evento.chamadas.forEach(e => {
          e.dataInicio = moment(e.dataInicio).add(3, "hours").format("L LT")
          e.dataFim = moment(e.dataFim).add(3, "hours").format("L LT")
        });


      }
    } catch (err) {
      console.error(`[[ERRO GET ${this.item.idAssembleia ? "ASSEMBLEIA" : "ELEIÇÃO"}]] => `, err)
    }
  }

  private async _getVotosEvento() {
    try {
      let resp;
      if (this.item.idEleicao) {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${this.item.idEleicao}`);
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idAssembleia=${this.item.idAssembleia}`);
      }
      this.votos = resp.body.documento;
    } catch (err) {
      console.error(`[[ERRO GET VOTOS ${this.item.idAssembleia ? "ASSEMBLEIA" : "ELEIÇÃO"}]] => `, err)
    }
  }

  private async _getProcuracoesEvento() {
    try {
      let resp;
      if (this.item.idEleicao) {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idEleicao=${this.item.idEleicao}`);
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idAssembleia=${this.item.idAssembleia}`);
      }
      this.procuracoes = resp.body.documento;
    } catch (err) {
      console.error(`[[ERRO GET PROCURAÇÕES ${this.item.idAssembleia ? "ASSEMBLEIA" : "ELEIÇÃO"}]] => `, err)
    }
  }

  private _limpaListas() {
    if (this.procuracoes.length > 0) {
      this.procuracoes.forEach(p => {
        let indexRepresentante = this.eleitoresRepresentantes.findIndex(er => er._id === p.idRepresentado);
        let indexRepresentado = this.eleitoresRepresentados.findIndex(er => er._id === p.idRepresentado);
        if (indexRepresentante >= 0) {
          this.eleitoresRepresentantes.splice(indexRepresentante, 1)
        }
        if (indexRepresentado >= 0) {
          this.eleitoresRepresentados.splice(indexRepresentado, 1)
        }
      });
    }
    setTimeout(() => {
      if (this.votos.length > 0) {
        this.votos.forEach(v => {
          let indexRepresentado = this.eleitoresRepresentados.findIndex(er => er._id === v.idEleitor);
          if (indexRepresentado >= 0) {
            this.eleitoresRepresentados.splice(indexRepresentado, 1)
          }
        });
      }
    }, 500);
  }

  async changeEleitor(id) {
    if (this.util.checkVariavel(id)) {
      let eleitor = this.item.eleitores.find(e => e._id === id);
      if (this.form.get("idEleitor").value !== this.form.get("idRepresentado").value) {
        if (await this._checkProcuracao(eleitor._id)) {
          this.form.patchValue({ nomeEleitor: eleitor.nome });
        } else {
          this.form.patchValue({ idEleitor: null, nomeEleitor: null });
          this.toaster.pop("error", "SEVI", "Existe um procuração para o eleitor selecionado ser representado por este motivo o mesmo não pode ser um representante.");
        }
      } else {
        this.form.patchValue({ idEleitor: null, nomeEleitor: null });
        this.toaster.pop("error", "SEVI", "O eleitor selecionado não pode ser igual ao eleitor a ser representado.");
      }
    } else {
      this.form.patchValue({ nomeEleitor: null });
    }
  }

  async changeRepresentado(id) {
    if (this.util.checkVariavel(id)) {
      let eleitor = this.item.eleitores.find(e => e._id === id);
      if (this.form.get("idRepresentado").value !== this.form.get("idEleitor").value) {
        if (await this._checkProcuracao(eleitor._id)) {
          this.form.patchValue({ empresa: eleitor.empresa, nomeRepresentado: eleitor.nome });
        } else {
          this.form.patchValue({ idRepresentado: null, empresa: null, nomeRepresentado: null });
          this.toaster.pop("error", "SEVI", "O eleitor selecionado já possui uma procuração cadastrada para ser representado.");
        }
      } else {
        this.form.patchValue({ idRepresentado: null, empresa: null, nomeRepresentado: null });
        this.toaster.pop("error", "SEVI", "O eleitor selecionado não pode ser igual ao eleitor representante.");
      }
    } else {
      this.form.patchValue({ empresa: null, nomeRepresentado: null });
    }
  }

  private async _checkProcuracao(idRepresentado) {
    try {
      let resp;
      if (this.item.idEleicao) {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idEleicao=${this.item.idEleicao}&idRepresentado=${idRepresentado}`)
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idAssembleia=${this.item.idAssembleia}&idRepresentado=${idRepresentado}`)
      }
      let procuracoes = resp.body.documento;
      return procuracoes.length <= 0;
    } catch (err) {
      console.error("[[ERRO GET PROCURACAO]] => ", err)
    }
  }

  private async _deleteDocs(doc) {
    try {
      await this.api.deleteGeneric(environment.urlBase, environment.paths.upload + '/' + doc)
      this.comFalha = false;
    } catch (err) {
      console.error("[[ERRO DELETE DOC]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
      this.comFalha = true;
      this.blockUi.stop();
      return;
    }
  }

  private async _postDocs(doc) {
    try {
      let formData = new FormData();
      formData.append("arquivo", doc, doc.name)
      const resp = await this.api.postGenericSemToken(environment.urlBase + environment.paths.upload, formData);
      this.comFalha = false;
      return resp.key;
    } catch (err) {
      console.error("[[ERRO POST DOC]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
      this.comFalha = true;
      this.blockUi.stop();
      return;
    }
  }

  public carregarArquivo(arquivo: FileList) {
    if (arquivo.length != 0) {
      if (arquivo.item(0).size <= 16000000) {
        this.docs = [];
        this.docs.push(arquivo.item(0))
        this.new = true;
        $("#doc").val(null)
        $("#select-doc").val(null)
      }
    } else {
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
      $("#doc").val(null)
      $("#select-doc").val(null)
    }
  }

  public removerArquivo(n) {
    if (typeof n === "string") {
      this.filesDelete.push(n);
    }
    this.docs = [];
  }

  async downloadArquivo(key) {
    try {
      let resp = await this.api.getGenericId(environment.urlBase + environment.paths.upload, key);
      window.open(resp.body.url, '_blank');
    } catch (err) {
      console.error("[[ERRO DOWNLOAD DOC]] => ", err)
    }
  }

  private async _postProcuracao(procuracao) {
    try {
      const resp = await this.api.postGeneric(environment.urlBase + environment.paths.procuracoes, procuracao);
      this.blockUi.stop();
      this.modal.close(true);
    } catch (err) {
      console.error("[[ERRO POST PROCURAÇÃO]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise os administradores.");
      this.blockUi.stop();
    }
  }

  async salvar() {
    this.submitted = true;
    this.comFalha = false;
    if (this.form.valid && this.docs.length > 0) {
      this.blockUi.start("Salvando procuração")
      let index = this.evento.eleitores.findIndex(e => e === this.form.get("idRepresentado").value);
      if(index >= 0){
        this.evento.eleitores.splice(index, 1)
        try {
          if (this.item.idEleicao) {
            await this.api.putGeneric(environment.urlBase + environment.paths.eleicoes + `/${this.evento._id}`, this.evento);
          } else {
            await this.api.putGeneric(environment.urlBase + environment.paths.assembleias + `/${this.evento._id}`, this.evento);
          }
        } catch (err) {
          console.error("[[ERRO PUT ELEIÇÃO]] => ", err);
          this.comFalha = true;
        }
      }
      let procuracao = this.form.getRawValue();
      this.filesDelete.forEach(async (doc) => {
        await this._deleteDocs(doc);
      });
      if (!this.comFalha) {
        procuracao.doc = this.docs.length <= 0 ? [] : typeof this.docs[0] === "string" ? this.docs[0] : await this._postDocs(this.docs[0]);
        if (!this.comFalha) {
          this._postProcuracao(procuracao);
        }
      }
    }
  }
}
