import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-reset-senha',
  templateUrl: './modal-reset-senha.component.html',
  styleUrls: ['./modal-reset-senha.component.css']
})
export class ModalResetSenhaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      cpf: [null, Validators.required],
    });
  }

  checkCnpj(doc) {
    if (!this.trataDocs.validaCNPJ(doc) && !this.trataDocs.validaCPF(doc)) {
      this.toaster.pop("error", "SEVI", "CNPJ ou CPF inválido, verifique o mesmo.");
      this.form.patchValue({
        cpf: null
      });
    }
  }

  async reset() {
    if (this.form.valid) {
      this.blockUi.start("Solicitando Nova Senha")
      try {
        let cpf = this.form.get('cpf').value;
        await this.api.postGenericSemToken(environment.urlBase + `${environment.paths.usuario}/resetsenha`, { cpf: this.trataDocs.limpaDoc(cpf) });
        this.blockUi.stop();
        this.toaster.pop("success", "SEVI", "Nova senha solicitada com sucesso.");
        this.modal.close(true)
      } catch (err) {
        console.error("[[ERRO POST RESET]] => ", err);
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista avise a equipe do edital.");
        this.blockUi.stop();
      }
    }
  }

}
