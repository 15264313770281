<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Seleção de Eleitores</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row" style="align-items: flex-end;">
        <div class="col-lg-8 col-md-6 col-12" style="margin-bottom: 10px;">
            <label>Nome do Eleitor</label>
            <input class="form-control input-round" type="text" id="eleitorFilter" [(ngModel)]="eleitorFilter"
                autocomplete="off" placeholder="Informe o nome do eleitor ou parte dele para executar o filtro">
        </div>
        <div class="col-lg-4 col-md-6 col-12" style="margin-bottom: 10px; text-align: center;">
            <button type="button" class="btn btn-outline-success" (click)="selecionarTodos()">Selecionar Todos</button>
            <button type="button" class="btn btn-outline-success" (click)="desmarcarTodos()">Desmarcar Todos</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div style="height: 400px; overflow-y: scroll;">
                <div class="form-check select-eleitor" *ngFor="let e of eleitores | tableFilter:eleitorFilter:'nome'">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="e.select" (change)="selectEleitor(e.id, true)">
                    <label class="form-check-label" (click)="selectEleitor(e.id)">{{e.nome}}</label>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">Fechar</button>
    <button type="button" class="btn btn-outline-success" (click)="salvar()">Salvar</button>
</div>