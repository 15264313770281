<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{item.tipo === "edit" ? "Edição de" : item.tipo === "view" ? "" : "Nova"}} Chapa</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Nome da Chapa<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nomeChapa" formControlName="nomeChapa" [ngClass]="{'invalido':form.get('nomeChapa').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nomeChapa').invalid && submitted">Campo Obrigatório</p>
            </div>
        </div>
    </form>
    <div class="row" *ngIf="item.tipo !== 'view'">
        <div class="add-margin-top col-md-6 col-12">
            <label class="labels">Nome do Integrante<span class="campo-required"> *</span></label>
            <input class="form-control input-round" type="text" id="nome" name="nome" [(ngModel)]="integrante.nome">
        </div>
        <div class="add-margin-top col-md-6 col-12">
            <label class="labels">Cargo do Integrante<span class="campo-required"> *</span></label>
            <input class="form-control input-round" type="text" id="cargo" name="cargo" [(ngModel)]="integrante.cargo">
        </div>
    </div>
    <div class="row" *ngIf="item.tipo !== 'view'" style="text-align: center;">
        <div class="add-margin-top col-12">
            <button type="button" class="btn btn-outline-success" (click)="incluirIntegrante()"><i
                    class="fas fa-user"></i> Incluir Integrante</button>
        </div>
    </div>
    <div class="row col-12">
        <div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px;">
            <table>
                <thead>
                    <tr>
                        <th class="th-rigth-border">Nome</th>
                        <th class="th-rigth-border">Cargo</th>
                        <th *ngIf="item.tipo !== 'view'">Ações</th>
                    </tr>
                </thead>
                <tbody id="teste" *ngIf="chapa.length > 0">
                    <tr *ngFor="let c of chapa">
                        <td>{{ c.nome }}</td>
                        <td>{{ c.cargo }}</td>
                        <td *ngIf="item.tipo !== 'view'">
                            <div style="display: inline-flex;">
                                <i class="far fa-trash-alt acoes" style="padding-left: 5px" placement="bottom" ngbTooltip="Excluir Integrante" (click)="excluirIntegrante(c)"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <p *ngIf="chapa.length <= 0" style="color: #ff0000; font-weight: bold;">• Não existem integrantes cadastrados.
                </p>
            </table>
        </div>
    </div>
    <form [formGroup]="form">
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Status da Chapa</label>
                <label class="switch">
                    <input type="checkbox" formControlName="status">
                    <span class="slider round" [ngClass]="{sliderDisabled: item.tipo === 'view'}">
                        <p class="label-switch"
                            [ngClass]="{'label-switch-padding-right': form.get('status').value, 'label-switch-padding-left': !form.get('status').value}">
                            {{form.get('status').value ? 'ATIVA' : 'INATIVA'}}
                        </p>
                    </span>
                </label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">{{item.tipo === "view" ? "Fechar" : "Cancelar"}}</button>
    <button type="button" class="btn btn-outline-success" *ngIf="item.tipo !== 'view'" (click)="salvar()">Salvar</button>
</div>