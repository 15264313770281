import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-add-pauta',
  templateUrl: './modal-add-pauta.component.html',
  styleUrls: ['./modal-add-pauta.component.css']
})
export class ModalAddPautaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    public globals: GlobalsService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private util: UtilService,
    private trataDatas: TrataNumeros,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      idAssembleia: [this.item.idAssembleia],
      nome: [null, Validators.required],
      descricao: [null],
      index: [this.item.index],
      ativa: [true]
    });

    if (this.item.tipo === "edit") {
      this.form.patchValue(this.item.pauta);
    } else if(this.item.tipo === "view"){
      this.form.patchValue(this.item.pauta);
      this.form.disable();
    }
  }

  async salvar() {
    this.submitted = true;
    try {
      let pauta = this.form.getRawValue();
      if (this.item.tipo === "new") {
        const resp = await this.api.postGeneric(environment.urlBase + environment.paths.pautas, pauta);
        this.modal.close(resp.body.documento);
      } else {
        const resp = await this.api.putGeneric(environment.urlBase + environment.paths.pautas + `/${this.item.pauta._id}`, pauta);
        pauta._id = this.item.pauta._id;
        this.modal.close(pauta);
      }
    } catch (err) {
      console.error("[[ERRO POST PAUTA]] => ", err)
    }
  }

}
