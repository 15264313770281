import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
moment.locale("pt-br");

@Component({
  selector: 'app-modal-selecionar-eleitores',
  templateUrl: './modal-selecionar-eleitores.component.html',
  styleUrls: ['./modal-selecionar-eleitores.component.css']
})
export class ModalSelecionarEleitoresComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public eleitores = [];
  public eleitorFilter = "";
  public lista = [];
  private votos = [];

  constructor(
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    private api: ApiService,
    private toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this._getEleitores();
    this._getVotosEleicao();
    if (this.item.tipo === "assembleia") {
      this.item.obj.chamadas.forEach(e => {
        e.dataInicio = moment(e.dataInicio).isValid() ? moment(e.dataInicio).add(3, "hours").format("L LT") : e.dataInicio
        e.dataFim = moment(e.dataFim).isValid() ? moment(e.dataFim).add(3, "hours").format("L LT") : e.dataFim
      });
    }
  }

  async selectEleitor(id, change?) {
    let index = this.eleitores.findIndex(o => o.id === id);
    if (this.votos.filter(v => v.idEleitor === id && v.idProcuracao === undefined).length <= 0) {
      if (!change) {
        this.eleitores[index].select = !this.eleitores[index].select;
      }
    } else {
      this.eleitores[index].select = true;
      this.toaster.pop("warning", "SEVI", "Este eleitor não pode ser desmarcado pois já exerceu o seu voto.");
    }
  }

  public selecionarTodos() {
    this.lista = [];
    this.eleitores.forEach(async e => {
      e.select = true;
      this.lista.push(e.nome)
    });
  }

  public desmarcarTodos() {
    this.eleitores.forEach(e => {
      if (this.votos.filter(v => v.idEleitor === e.id && v.idProcuracao === undefined).length <= 0) {
        e.select = false;
      }
    });
    if (this.eleitores.filter(e => e.select === true).length > 0) {
      this.toaster.pop("error", "SEVI", "Existem eleitores que continuam selecionados pois já exerceram os seus votos.");
    }
  }

  private async _getVotosEleicao() {
    try {
      let resp;
      if (this.item.tipo === "assembleia") {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idAssembleia=${this.item.obj._id}`);
        this.votos = resp.body.documento;
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${this.item.obj._id}`);
        this.votos = resp.body.documento;
      }
    } catch (err) {
      console.error(`[[ERRO GET VOTOS ${this.item.tipo === "assembleia" ? "ASSEMBLÉIA" : "ELEIÇÃO"}]] => `, err)
    }
  }

  private async _getEleitores() {
    try {
      this.blockUi.start("Carregando as informações")
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.eleitores + `?sindicatos=${this.item.idSindicato}`);
      resp.body.documento.forEach(e => {
        this.eleitores.push({
          nome: e.nome,
          id: e._id,
          select: this.item.obj.eleitores.findIndex(o => o === e._id) >= 0
        });
      });
      this.eleitores = this.eleitores.sort((a, b) => a["nome"].toUpperCase().localeCompare(b["nome"].toUpperCase()));
      this.blockUi.stop()
    } catch (err) {
      this.blockUi.stop()
      console.error("[[ERRO GET ELEITORES]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista abra um BPM.");
    }
  }

  salvar() {
    this.blockUi.start("Salvando dados")
    let ids = [];
    let eleitoresSelecionados = this.eleitores.filter(e => e.select);
    if (eleitoresSelecionados.length > 0) {
      for (let i = 0; i < eleitoresSelecionados.length; i++) {
        ids.push(eleitoresSelecionados[i].id);
        if ((i + 1) === eleitoresSelecionados.length) {
          this._putEleitores(ids)
        }
      }
    } else {
      this._putEleitores(ids)
    }
  }

  private async _putEleitores(ids) {
    try {
      this.item.obj.eleitores = ids;
      if (this.item.tipo === "assembleia") {
        await this.api.putGeneric(environment.urlBase + environment.paths.assembleias + `/${this.item.obj._id}`, this.item.obj);
      } else {
        await this.api.putGeneric(environment.urlBase + environment.paths.eleicoes + `/${this.item.obj._id}`, this.item.obj);
      }
      this.blockUi.stop()
      this.toaster.pop("success", "SEVI", "Eleitores selecionados com sucesso.");
      this.modal.close(this.item.obj)
    } catch (err) {
      console.error("[[ERRO PUT ELEIÇÃO]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista abra um BPM.");
      this.blockUi.stop()
    }
  }
}
