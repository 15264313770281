import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalTrocarSenhaComponent } from 'src/app/modais/modal-trocar-senha/modal-trocar-senha.component';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  public user: User;
  public name: String;

  constructor(
    public globals: GlobalsService,
    public modalService: NgbModal,
    private authService: AuthenticationService,
  ) {
    this.user = this.authService.currentUserValue;
    this.name= !this.user.user ? this.user.nome : this.user.user;
  }

  ngOnInit() {

  }

  changePassword() {
    const modalRef = this.modalService.open(ModalTrocarSenhaComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      username: this.authService.currentUserValue.nome
    };
  }

  logout() {
    this.authService.logout();
  }

}
