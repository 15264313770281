<div class="modal-header">
    <div class="acoes">
        <button [hidden]="!toggle" type="button" class="btn-expand" (click)="download()" placement="bottom"
            ngbTooltip="Baixar Imagem">
            <i class="far fa-save"></i>
        </button>
        <div class="btnToggle"
            style="display: flex;justify-content: flex-end;padding-left: 15px;padding-right: 15px; align-items: center;">
            <p style="margin: 0px; margin-right: 10px;">{{toggle ? 'Cancelar' : 'Capturar Gráficos'}}</p>
            <label class="switch" [ngClass]="{'switch-enabled': toggle, 'switch-disabled': !toggle}">
                <input type="checkbox" [(ngModel)]="toggle">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
</div>

<ng2-screenshot [isOpen]="toggle" (isOpenChange)="isOpenChange($event)" (apiInitialized)="apiInitialized($event)"
    *ngIf="show">
    <div class="col-12 titulos" style="text-align: center; text-decoration: underline;">
        {{nomeEleicao}}
    </div>
    <div style="font-family: panton-bold; font-size: 20px; text-align: center; padding: 5px 0px;">
        {{info}}
    </div>
    <app-custom-vertical-bar-chart [cid]="'bar'" [title]="" [data]="apuracao"></app-custom-vertical-bar-chart>
</ng2-screenshot>
