import { Component, Input, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import * as alasql from 'alasql';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'src/app/services/globals.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { ToasterService } from 'angular2-toaster';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { ModalCorrecaoEleitorComponent } from '../modal-correcao-eleitor/modal-correcao-eleitor.component';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/models/role.enum';

declare var $: any;

@Component({
  selector: 'app-modal-upload-eleitores',
  templateUrl: './modal-upload-eleitores.component.html',
  styleUrls: ['./modal-upload-eleitores.component.css'],
})
export class ModalUploadEleitoresComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public eleitores = [];
  public eleitoresComFalha = [];
  public eleitoresNaoImportados = [];
  public eleitoresExistentes = [];
  public eleitoresSalvos = [];

  constructor(
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    public globals: GlobalsService,
    private util: UtilService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private api: ApiService
  ) {}

  ngOnInit(): void {}

  public async downloadEspelho() {
    let espelho = [
      {
        nome: 'Exemplo',
        cpf: '00000000000',
        email: 'exemplo@email.com',
        empresa: 'Empresa Exemplo',
      },
    ];
    await alasql.default(
      `SELECT * INTO XLSX("Espelho da Planilha para Upload de Eleitores.xlsx", {headers: true}) FROM ?`,
      [espelho, 'String']
    );
  }

  onFileChange(ev) {
    //this.blockUi.start("Processando Planilha")
    this.eleitores = [];
    this.eleitoresComFalha = [];
    let workBook = null;
    let jsonData = null;
    let sheetName = '';
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          blankrows: false,
          defval: null,
        });
        return initial;
      }, {});

      for (let i = 0; i < jsonData[sheetName].length; i++) {
        if (this.trataDocs.validaCPF(jsonData[sheetName][i].cpf.toString())) {
          this.eleitores.push({
            nome: jsonData[sheetName][i].nome,
            cpf: jsonData[sheetName][i].cpf,
            email: jsonData[sheetName][i].email,
            empresa: jsonData[sheetName][i].empresa,
            status: true,
            sindicatos: [this.item.idSindicato],
          });
          this._checkCadastro(jsonData[sheetName][i]);
        } else {
          this.eleitoresComFalha.push({
            nome: jsonData[sheetName][i].nome,
            cpf: jsonData[sheetName][i].cpf,
            email: jsonData[sheetName][i].email,
            empresa: jsonData[sheetName][i].empresa,
          });
        }
      }
    };
    $('#my-file-selector').val(null);
    $('#upload-file-info').val(null);
    reader.readAsBinaryString(file);
  }

  openModalCorrecao(eleitor) {
    let index = this.eleitoresComFalha.findIndex((e) => e === eleitor);
    const modalRef = this.ngbModal.open(ModalCorrecaoEleitorComponent, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      obj: eleitor,
    };
    modalRef.result.then((res) => {
      if (res) {
        this.eleitores.push(res);
        this._checkCadastro(res);
        this.eleitoresComFalha.splice(index, 1);
      }
    });
  }

  private async _checkCadastro(eleitor) {
    try {
      const resp = await this.api.getGeneric(
        environment.urlBase +
          environment.paths.eleitoresCpf +
          `/${this.trataDocs.limpaDoc(eleitor.cpf)}`
      );
      this.eleitoresExistentes.push(resp.body.documento);
    } catch (err) {
      console.error('[[ERRO GET ELEITOR]] => ', err);
    }
  }

  salvar() {
    if (this.eleitores.length > 0) {
      this.blockUi.start('Importando Eleitores');
      const resp = this.api.postGeneric(
        environment.urlBase +
          environment.paths.eleitoresUpload +
          this.item.idSindicato,
        this.eleitores
      );
      resp.then((data) => {
        console.log(data.body.responseList);
        this.eleitoresSalvos = data.body.responseList.success;
        this.blockUi.stop();
        this.toaster.pop(
          'success',
          'SEVI',
          'Eleitores importados com sucesso.'
        );
        this.modal.close(this.eleitoresSalvos);
      });
      // this.eleitores.forEach(async eleitor => {
      //    let e = eleitor;
      //    let index = null;
      //    e.cpf = this.trataDocs.limpaDoc(e.cpf);
      //    try {
      //      if (this.eleitoresExistentes.findIndex(ee => ee.cpf === e.cpf) < 0) {
      //        await this._gerarLogin({
      //          nome: e.nome,
      //          cpf: e.cpf,
      //          email: e.email,
      //          empresa: e.empresa,
      //          status: true,
      //          sindicatos: [this.item.idSindicato]
      //        });
      //      } else {
      //        index = this.eleitoresExistentes.findIndex(ee => ee.cpf === e.cpf);
      //        let eleitorExiste = this.eleitoresExistentes[index];
      //        if (!eleitorExiste.sindicatos.includes(this.item.idSindicato)) {
      //          eleitorExiste.sindicatos.push(this.item.idSindicato);
      //          await this.salvarEleitor(eleitorExiste, 'edit');
      //        }
      //      }
      //     } catch (err) {
      //      console.error("[[ERRO POST ELEITOR]] => ", err);
      //      this.eleitoresNaoImportados.push(e);
      //    }
      // });
      this.blockUi.stop();
      this.toaster.pop('success', 'SEVI', 'Eleitores importados com sucesso.');
      window.location.reload();

      this.modal.close(true);
    } else {
      this.toaster.pop(
        'error',
        'SEVI',
        'Não existem eleitores a serem importados.'
      );
    }
  }

  private async _gerarLogin(eleitor) {
    try {
      // await this.api.postGeneric(environment.urlBase + environment.paths.usuario, {
      //   "nome": eleitor.nome,
      //   "email": eleitor.email,
      //   "cpf_cnpj": this.trataDocs.limpaDoc(eleitor.cpf),
      //   "role": Role.ELEITOR
      // });
      this.salvarEleitor(eleitor, 'new');
    } catch (err) {
      console.error('[[ERRO CADASTRA USUÁRIO]] => ', err);
      if (err.error.statusCode === 422) {
        this._addRole(eleitor);
      } else {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
        );
      }
    }
  }

  private async _addRole(eleitor) {
    try {
      await this.api.postGeneric(
        environment.urlBase +
          environment.paths.usuario +
          `/${this.trataDocs.limpaDoc(eleitor.cpf)}/role/${Role.ELEITOR}`,
        {}
      );
      this.salvarEleitor(eleitor, 'edit');
    } catch (err) {
      console.error('[[ERRO ADD ROLE]] => ', err);
      if (err.error.statusCode !== 404) {
        this.blockUi.stop();
        this.toaster.pop(
          'error',
          'SEVI',
          'Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.'
        );
      } else {
        if (
          this.eleitoresExistentes.findIndex((ee) => ee.cpf === eleitor.cpf) < 0
        ) {
          this.salvarEleitor(eleitor);
        } else {
          this.salvarEleitor(eleitor, 'edit');
        }
      }
    }
  }

  private async salvarEleitor(eleitor, tipo?) {
    try {
      eleitor.cpf = this.trataDocs.limpaDoc(eleitor.cpf);
      if (tipo === 'edit') {
        const resp = await this.api.putGeneric(
          environment.urlBase + environment.paths.eleitores + `/${eleitor._id}`,
          eleitor
        );
        eleitor._id = resp.body.documento._id;
      } else {
        const resp = await this.api.postGeneric(
          environment.urlBase + environment.paths.eleitores,
          eleitor
        );
        eleitor._id = resp.body.documento._id;
      }
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO CADASTRO ELEITOR]]', err);
    }
  }
}
