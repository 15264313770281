import { Role } from './role.enum';

export class User {
  _id: string;
  nome: string;
  nomeCompleto?: string;
  roles?: Role[];
  access_token?: string;
  refreshtoken?: string;
  email?: string;
  login: string;
  // cpf_cnpj: string;
  cpf: string;
  cnpj: string;

  password: string;
  idSindicato: string;
  user: string;
}
