<div class="row" style="display: initial; text-align: center !important">
  <h3 class="titulo">Eleições</h3>
</div>
<hr />

<div class="row">
  <div class="col-lg-4 col-md-6 col-12" *ngFor="let e of eleicoes">
    <div class="card-eleicao">
      <h6>Sindicato/Empresa: {{ e.nomeSindicato }}</h6>
      <h5 class="titulo">{{ e.nome }}</h5>
      <p style="margin-bottom: 10px">{{ e.dataInicio }} à {{ e.dataFinal }}</p>
      <h5 class="titulo">Status</h5>
      <!-- <div style="margin-bottom: 10px;" [ngClass]="{'tag-aguar': e.situacao === 'aguardando', 'tag-repro': e.situacao === 'fechado', 'tag-apro' : e.situacao === 'computado' }"> {{e.situacao === "aguardando" ? "AGUARDANDO O SEU VOTO" : e.situacao === "fechado" ? "PERÍODO FECHADO" : "VOTO JÁ COMPUTADO"}} </div>
            <button type="button" class="btn btn-sm btn-outline-success" [disabled]="e.situacao === 'fechado' || e.situacao === 'computado'" (click)="openModalVotacao(e)">Entrar</button> -->
      <!-- <div style="margin-bottom: 10px;" [ngClass]="{'tag-repro': !e.situacao, 'tag-apro' : e.situacao}"> {{e.situacao}} </div> -->
      <div
        style="margin-bottom: 10px"
        [ngClass]="{ 'tag-repro': !e.situacao, 'tag-apro': e.situacao }"
      >
        PERÍODO DE VOTAÇÃO ABERTO
      </div>
      <button
        type="button"
        id="btnEntrar"
        class="btn btn-sm btn-outline-success"
        (click)="openModalVotacao(e)"
      >
        Entrar
      </button>
      <!-- <ng-template #elseTemplate>
                <button type="button" class="btn btn-sm btn-outline-success" (click)="openModalVotacao(e)" disabled>Entrar</button>
            </ng-template> -->
    </div>
  </div>
</div>
<!-- <div *ngIf="!this.infoEleitor || this.eleicoes.length <= 0"> -->
<div *ngIf="this.eleicoes.length <= 0">
  <p class="text-danger font-weight-bold">
    Nenhuma eleição cadastrada no momento
  </p>
</div>
