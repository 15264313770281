import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-pie-donut-chart',
  templateUrl: './custom-pie-donut-chart.component.html',
  styleUrls: ['./custom-pie-donut-chart.component.css']
})
export class CustomPieDonutChartComponent implements OnInit {
  @Input() cid: any;
  @Input() data: any;
  @Input() title: string;
  @Input() donut: string;

  legend: boolean = true;
  legendTitle: string = "Legendas";
  legendPosition: string = "below";
  view: any = [0,400];
  labels: boolean = true;
  trimLabels: boolean = true;
  maxLabelLength: number = 20; 
  isDoughnut: boolean = false;
  arcWidth: Number = 0.5;
  colorScheme: any = {
    domain: ["#92d050", "#48bb94", "#00b0f0", "#0070c0", "#002060"]
  };

  constructor(public modalService: NgbModal) { }

  ngOnInit() {
    this.isDoughnut = this.donut ? true : false;
  }

  setTooltipText(c) {
    if (c.data.label.indexOf("|") > 0) {
      return `<div>${c.data.label.replace("|", `<br>`)}<div>`
    }
    return `
      <div>${c.data.label}<div>
      <div>${c.data.value}<div>
    `
  }

  setLabelFormatting(c) {
    return `${c.split("|")[1]}`;
  }

}
