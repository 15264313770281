import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ModalVotacaoAssembleiaComponent } from 'src/app/modais/modal-votacao-assembleia/modal-votacao-assembleia.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eleitor-assembleias',
  templateUrl: './eleitor-assembleias.component.html',
  styleUrls: ['./eleitor-assembleias.component.css']
})
export class EleitorAssembleiasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;

  private infoEleitor: any = {};
  public assembleias = [];
  private idsAssembleias = [];

  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private trataDocs: TrataDocsService,
    private toaster: ToasterService,
    private trataDatas: TrataNumeros,
    public modal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getInfoEleitor(this.auth.currentUserValue.nome);
  }

  private async getInfoEleitor(cpf) {
    this.blockUi.start("Carregando Eleições")
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.eleitoresCpf + `/${this.auth.currentUserValue.nomeCompleto}`);
      this.infoEleitor = resp.body.documento;
      this._getAssembleiasEleitor(this.infoEleitor._id);
    } catch (err) {
      this.blockUi.stop();
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO GET ELEITOR]] => ", err);
    }
  }

  private async _getAssembleiasEleitor(idEleitor) {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.assembleias + `?eleitores=${idEleitor}`)
      if (resp.body.documento.length > 0) {
        this.assembleias = [];
        resp.body.documento.forEach(async a => {
          if (a.ativa && this._checkPeriodo(moment(a.chamadas[a.chamadas.length - 1].dataFim).add(3, "hours").format("L LT"))) {
            this.idsAssembleias.push(a._id)
            let chamadas = [];
            a.chamadas.forEach(c => {
              chamadas.push({
                chamada: c.chamada,
                dataInicio: moment(c.dataInicio).add(3, "hours").format("L LT"),
                dataFim: moment(c.dataFim).add(3, "hours").format("L LT"),
              })
            });
            this.assembleias.push({
              _id: a._id,
              nome: a.nome,
              chamadas: chamadas,
              idSindicato: a.idSindicato,
              votoEleitor: a.eleitores.includes(idEleitor),
              situacao: await this._checkInfoAssembleia(chamadas),
              ativa: true,
            });
          }
        });
      }
      this._checkHorario();
      this._getProcuracoes(idEleitor);
    } catch (err) {
      this.blockUi.stop();
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO GET ELEIÇÕES]] => ", err);
    }
  }

  private async _getProcuracoes(idEleitor) {
    try {
      for (var item of this.assembleias){
        await this._getAssembleia(item, idEleitor)
      }
      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      console.error("[[ERRO GET PROCURAÇÕES]] => ", err);
    }
  }

  private async _getAssembleia(idAssembleia, idEleitor) {
    try {
      const rEleicao = await this.api.getGenericId(environment.urlBase + environment.paths.assembleias, idAssembleia);
      let a = rEleicao.body.documento;
      if (a.ativa && this._checkPeriodo(moment(a.chamadas[a.chamadas.length - 1].dataFim).add(3, "hours").format("L LT"))) {
        let chamadas = [];
        a.chamadas.forEach(c => {
          chamadas.push({
            chamada: c.chamada,
            dataInicio: moment(c.dataInicio).add(3, "hours").format("L LT"),
            dataFim: moment(c.dataFim).add(3, "hours").format("L LT"),
          })
        });
        this.assembleias.push({
          _id: a._id,
          nome: a.nome,
          chamadas: chamadas,
          idSindicato: a.idSindicato,
          votoEleitor: a.eleitores.includes(idEleitor),
          situacao: await this._checkInfoAssembleia(chamadas),
          ativa: true,
        });
      }
      return
    } catch (err) {
      console.error("[[ERRO GET ASSEMBLEIA]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
      return
    }
  }

  private _checkHorario() {
    setInterval(() => {
      this.assembleias.forEach(c => {
        c.situacao = this._checkInfoAssembleia(c.chamadas);
      });
    }, 100);
  }

  private _checkInfoAssembleia(chamadas) {
    let livre: boolean = false;
    chamadas.forEach(c => {
      if (this.trataDatas.validaDataHoraMaiorOuIgual(moment().format("L LT"), c.dataInicio) && this.trataDatas.validaDataHoraMenorOuIgual(moment().format("L LT"), c.dataFim)) {
        if (!livre) {
          livre = true;
        } else if (!livre){
          livre = false;
        }
      }
    });
    return livre;
  }

  private _checkPeriodo(dataFim) {
    return this.trataDatas.validaDataHoraMenorOuIgual(moment().format("L LT"), dataFim)
  }

   openModalVotacao(assembleia) {
     const modalRef = this.modal.open(ModalVotacaoAssembleiaComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
     modalRef.componentInstance.item = {
       assembleia: assembleia,
       eleitorId: this.infoEleitor._id
     }
     modalRef.result.then(res => {
       if (res) {
         this._getAssembleiasEleitor(this.infoEleitor._id);
       }
     });
   }

}
