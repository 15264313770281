<div class="row" style="display: initial; text-align: center!important;">
    <h3 class="titulo">Assembleias</h3>
</div>
<hr>
<div class="row">
    <div class="col-lg-4 col-md-6 col-12" *ngFor="let a of assembleias">
        <div class="card-assembleias">
            <h5 class="titulo" style="margin-bottom: 5px;">{{a.nome}}</h5>
            <h5 class="titulo" style="margin-bottom: 5px;">Chamadas</h5>
            <p style="margin-bottom: 5px; font-size: 15px;" *ngFor="let c of a.chamadas">{{c.dataInicio}} à {{c.dataFim}}</p>
            <h5 class="titulo">Status</h5>
            <div style="margin-bottom: 5px;" [ngClass]="{'tag-repro': !a.situacao, 'tag-apro' : a.situacao}"> {{a.situacao ? "PERÍODO DE VOTAÇÃO ABERTO" : "PERÍODO DE VOTAÇÃO FECHADO"}} </div>
            <button type="button" class="btn btn-sm btn-outline-success" (click)="openModalVotacao(a)" [disabled]="!a.situacao">Entrar</button>
        </div>
    </div>
</div>