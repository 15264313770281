import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';

@Component({
  selector: 'app-modal-chapa',
  templateUrl: './modal-chapa.component.html',
  styleUrls: ['./modal-chapa.component.css']
})
export class ModalChapaComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public form: FormGroup;
  public submitted: boolean = false;
  public integrante = {
    nome: null,
    cargo: null,
  }
  public chapa = [];

  constructor(

    public modal: NgbActiveModal,
    public ngbmodal: NgbModal,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private util: UtilService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      idEleicao: [this.item.id],
      nomeChapa: [null, Validators.required],
      integrantes: [[]],
      status: [true]
    });
    if (this.item.tipo !== "new") {
      this.form.patchValue(this.item.chapa);
      this.chapa = this.form.get('integrantes').value;
    }

    if (this.item.tipo === "view") {
      this.form.disable();
    }
  }

  incluirIntegrante() {
    if (this.util.checkVariavel(this.integrante.nome) && this.util.checkVariavel(this.integrante.cargo)) {
      this.chapa.push(this.integrante);
      this.integrante = { nome: null, cargo: null }
    } else {
      this.toaster.pop("error", "SEVI", "Para adicionar um integrante o mesmo deve-se informar nome e cargo");
    }
  }

  excluirIntegrante(integrante) {
    const modalRef = this.ngbmodal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      title: "Excluir Integrante?",
      msg: "Você realmente deseja excluir o integrante selecionado? Após realizar a exclusão o mesmo não poderá ser recuperada.",
    }
    modalRef.result.then(async res => {
      if (res) {
        let index = this.chapa.findIndex(c => c.nome === integrante.nome);
        this.chapa.splice(index, 1);
        this.toaster.pop("success", "SEVI", "Integrante excluído com sucesso.");
      }
    });
  }

  async salvar() {
    this.submitted = true;
    if (this.form.valid && this.chapa.length > 0) {
      this.blockUi.start("Salvando dados")
      try {
        let chapa = this.form.getRawValue();
        let resp: any;
        chapa.integrantes = this.chapa;
        if (!this.item.chapa) {
          resp = await this.api.postGeneric(environment.urlBase + environment.paths.chapas, chapa);
          this.blockUi.stop();
          this.modal.close(resp.body.documento);
        } else {
          await this.api.putGeneric(environment.urlBase + environment.paths.chapas + `/${this.item.chapa._id}`, chapa);
          this.blockUi.stop();
          this.modal.close(chapa);
        }
      } catch (err) {
        console.error("[[ERRO POST CHAPA]] => ", err)
        this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
        this.blockUi.stop();
      }
    } else {
      this.toaster.pop("error", "SEVI", "Verifique os campos obrigatórios e que a chapa tenha ao menos um integrante.");
    }
  }

}
