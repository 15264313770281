import { ModalViewUserComponent } from './../../modais/modal-view-user/modal-view-user.component';
import { Role } from './../../models/role.enum';
import { ModalEditUserComponent } from './../../modais/modal-edit-user/modal-edit-user.component';
import { User } from './../../models/user';
import { ModalEditarUsuarioComponent } from './../../modais/modal-editar-usuario/modal-editar-usuario.component';
import { ModalAddUserComponent } from './../../modais/modal-add-user/modal-add-user.component';
import { ApiService } from 'src/app/services/api.service';
import { Usuario } from './../../models/usuario';
import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  public searchVal: string = '';

  public usuarios: User[] = [];

  public roles1: Role;

  public usuarioSelecionado: User;

  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  private ultimaPagCarregada = 1;
  public opcoes = [5, 10, 25, 50];
  public sortColumn: string = '';
  public sortOrder: boolean = true;
  private getPrimaryRole(roles: string[]): string {
    return roles.length > 0 ? roles[0] : '';
  }

  constructor(
    public modal: NgbModal,
    private apiService: ApiService,
    private toaster: ToasterService,
    private trataDocs: TrataDocsService
  ) {}

  ngOnInit(): void {
    this._getUsuarios();
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.usuarios = [];
    this.blockUi.start('Carregando');
    setTimeout(() => {
      this._getUsuarios();
      this.blockUi.stop();
    }, 1500);
  }
  private async _getUsuarios() {
    try {
      this.blockUi.start('Carregando as informações');
      const resp = await this.apiService.getGeneric(
        environment.urlBase + environment.paths.usuario
      );
      resp.body.documento.forEach((usuario) => {
        // usuario.cpf_cnpj = this.trataDocs.addMaskCnpj(usuario.cpf_cnpj);
        this.usuarios.push(usuario);
      });
      this.numRegistros = this.usuarios.length;
      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      console.error('[[ERRO GET USUARIOS]] => ', err);
      this.toaster.pop(
        'error',
        'SEVI',
        'Erro interno, tente novamente e caso o erro persista abra um BPM.'
      );
    }
  }

  openModalUsuario() {
    // const modalRef = this.modal.open(ModalAddUserComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });

    const modalRef = this.modal.open(ModalAddUserComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.name = 'World';

    modalRef.result.then((result) => {
      if (result) {
        // if (sind) {
        let index = this.usuarios.findIndex((s) => s._id === result._id);
        if (index) {
          this.usuarios[index] = result;
        } else {
          this.usuarios.push(result);
        }

        // this.usuarios[index].cnpj = this.trataDocs.addMaskCnpj(this.sindicatos[index].cnpj);
        // } else {

        // }
      }
    });
  }

  openModalViewUsuario(u) {
    const modalRef = this.modal.open(ModalViewUserComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      obj: u,
    };
  }

  openModalEditUsuario(u) {
    // const modalRef = this.modal.open(ModalAddUserComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });

    const modalRef = this.modal.open(ModalEditUserComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.item = {
      obj: u,
    };

    modalRef.result.then((result) => {
      if (result) {
        // if (sind) {
        let index = this.usuarios.findIndex((s) => s._id === result._id);
        if (index) {
          this.usuarios[index] = result;
        } else {
          this.usuarios.push(result);
        }
      }
    });
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortColumn = column;

    this.usuarios.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      if (column === 'roles') {
        valueA = this.getPrimaryRole(a.roles);
        valueB = this.getPrimaryRole(b.roles);
      }

      if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      }
      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn === column) {
      return this.sortOrder ? 'fas fa-angle-down' : 'fas fa-angle-up';
    }
    return 'fas fa-arrows-alt-v';
  }

  get filteredUsuarios() {
    if (!this.searchVal) {
      return this.usuarios;
    }

    return this.usuarios.filter(
      (sind) =>
        sind.nome.toLowerCase().includes(this.searchVal.toLowerCase()) ||
        sind.cpf.includes(this.searchVal)
    );
  }
}
