import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Ng2ScreenshotAPI } from 'ng2-screenshot';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TrataNumeros } from 'src/app/services/trata-numeros.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
moment.locale("pt-br");

@Component({
  selector: 'app-dashboard-apuracao-eleicoes',
  templateUrl: './dashboard-apuracao-eleicoes.component.html',
  styleUrls: ['./dashboard-apuracao-eleicoes.component.css']
})
export class DashboardApuracaoEleicoesComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;
  toggle = false;
  api: Ng2ScreenshotAPI;
  apiInitialized($event: Ng2ScreenshotAPI) {
    this.api = $event;
  }

  public nomeSind = "";
  public nomeEleicao = "";
  public info = "";
  public apuracao = [];
  public show = false;

  private chapas = [];
  private votos = [];

  constructor(
    private apiService: ApiService,
    private auth: AuthenticationService,
    private toaster: ToasterService,
    private trataDatas: TrataNumeros,
    private acRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.nomeSind = this.auth.currentUserValue.nomeCompleto;
    this._init(this.acRoute.snapshot.paramMap.get("eleicaoId"));
  }

  isOpenChange($event: boolean) {
    this.toggle = $event;
    document.getElementById("toggle-btn").classList.add("toggle-false");
    document.getElementById("toggle-btn").classList.remove("toggle-true");
  }

  async download() {
    try {
      await this.api.download()
    } catch (err) {
      console.error("[[DOWNLOAD IMAGE ERROR]]", err);
    }
  }

  private async _init(id) {
    this.blockUi.start("Processando Dados");
    try {
      const eleicao = await this.apiService.getGenericId(environment.urlBase + environment.paths.apuracao, id);

      this.nomeEleicao = eleicao.body.documento.nome;
      console.log(this.nomeEleicao);
      this.chapas = eleicao.body.documento.chapas;

      this.chapas.forEach(c => {
        this.apuracao.push({
          name: c.nomeChapa.toUpperCase(),
          value: c.votos
            // value: this.votos.filter(v => v.voto === c._id).length,
          });
      });

      this.apuracao.push({
        name: "EM BRANCO",
        value: eleicao.body.documento.emBranco
          // value: this.votos.filter(v => v.voto === "em_branco").length
        })
      this.apuracao.push({
        name: "NULOS",
        value: eleicao.body.documento.nulo
          // value: this.votos.filter(v => v.voto === "nulo").length
        })
      this.apuracao.push({
        name: "ABSTENÇÃO",
        value: eleicao.body.documento.abstencoes
      })
      this.info = `TOTAL DE ELEITORES APTOS: ${eleicao.body.documento.totalEleitores} | TOTAL DE VOTOS CONTABILIZADOS: ${eleicao.body.documento.contabilizados} | TOTAL DE ABSTENÇÕES: ${eleicao.body.documento.abstencoes}`;


      setTimeout(() => {
        this.show = true;
        this.blockUi.stop();
      }, 500);

    } catch (err) {
      this.blockUi.stop();
      // console.error( err)
    }
  }

}
