import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { ApiService } from './api.service';
// import * as TrataRole from 'pkg-trata-role';
import * as TrataRole from '../../../pkg-trata-role/pkg-trata-role'
import * as jwtToken from 'jwt-decode';
import { ModalTrocarSenhaComponent } from '../modais/modal-trocar-senha/modal-trocar-senha.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal,
    private toaster: ToasterService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  async login(login: string, senha: string) {
    try {
      const res = await this.api.postGenericSemToken(environment.urlBase + environment.paths.login, { "user": login, "password": senha });
      let accessToken = jwtToken(res.access_token);
      let user: any = {
        access_token: res.access_token,
        refreshtoken: res.refreshtoken,
        nome: login.replace(".", " "),
        nomeCompleto: accessToken.preferred_username,
        login: login,
        roles: res.roles,
        email: res.email,
        user: res.user
      }
      sessionStorage.setItem("currentUser", JSON.stringify(user));
      if (user.roles[0] !== "SEVI_ADM[*]" && res.primeiroAcesso) {
        this._changePassword(res.primeiroAcesso, login)
      }
      this.currentUserSubject.next(user);
      return user;
    } catch (err) {
      console.error("[[LOGIN ERROR]] => ", err);
      if (err.status === 401) {
        this.toaster.pop("error", "SEVI", "Usuário sem permissão ou credenciais inválidas, verifique.");
      }
    }
  }

  private _changePassword(primeroAcesso, login) {
    const modalRef = this.modalService.open(ModalTrocarSenhaComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      primeroAcesso: primeroAcesso,
      username: login
    };
  }

  logout() {
    this.modalService.dismissAll();
    localStorage.clear();
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }


}
