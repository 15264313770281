import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { environment } from 'src/environments/environment';
import { ModalAddProcuracaoComponent } from '../modal-add-procuracao/modal-add-procuracao.component';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';
import * as moment from 'moment';
moment.locale("pt-br");

@Component({
  selector: 'app-modal-procuracao',
  templateUrl: './modal-procuracao.component.html',
  styleUrls: ['./modal-procuracao.component.css']
})
export class ModalProcuracaoComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public procuracoes = [];
  private eleitores = [];
  private evento: any = {};

  constructor(
    public modal: NgbActiveModal,
    public ngbModal: NgbModal,
    public globals: GlobalsService,
    private toaster: ToasterService,
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this._getProcuracoes();
  }

  private async _getProcuracoes() {
    try {
      this.blockUi.start("Carregando Procurações");
      let resp;
      let respE;
      if(this.item.tipo === "assembleia"){
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idAssembleia=${this.item.idAssembleia}`);
        respE = await this.api.getGenericId(environment.urlBase + environment.paths.assembleias, this.item.idAssembleia);
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.procuracoes + `?idEleicao=${this.item.idEleicao}`);
        respE = await this.api.getGenericId(environment.urlBase + environment.paths.eleicoes, this.item.idEleicao);
      }
      this.procuracoes = resp.body.documento;
      this.evento = respE.body.documento;

      if(this.item.tipo === "assembleia"){
        this.evento.chamadas.forEach(e => {
          e.dataInicio = moment(e.dataInicio).add(3, "hours").format("L LT")
          e.dataFim = moment(e.dataFim).add(3, "hours").format("L LT")
        });
      }
      
      this.procuracoes = this.procuracoes.sort((a, b) => a["nomeEleitor"].toUpperCase().localeCompare(b["nomeEleitor"].toUpperCase()));
      this._getEleitores();
    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO GET PROCURAÇÕES]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
    }
  }

  private async _getEleitores() {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.eleitores + `?sindicatos=${this.item.idSindicato}`);
      if (resp.body.documento.length > 0) {
        this.eleitores = resp.body.documento.sort((a, b) => a["nome"].toUpperCase().localeCompare(b["nome"].toUpperCase()));
      }
      this.blockUi.stop();
    } catch (err) {
      this.blockUi.stop();
      console.error("[[ERRO GET ELEICOES]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista abra um BPM.");
    }
  }

  async download(key) {
    this.blockUi.start("Carregando Documento")
    try {
      let resp = await this.api.getGenericId(environment.urlBase + environment.paths.upload, key);
      window.open(resp.body.url, '_blank');
      this.blockUi.stop();
    } catch (err) {
      console.error("[[ERRO DOWNLOAD DOC]] => ", err)
      this.blockUi.stop();
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
    }
  }

  async deleteProcuracao(procuracao) {
    try {
      let resp;
      if(this.item.tipo === "assembleia"){
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idAssembleia=${procuracao.idAssembleia}&idEleitor=${procuracao.idEleitor}`)
      } else {
        resp = await this.api.getGeneric(environment.urlBase + environment.paths.controleVotos + `?idEleicao=${procuracao.idEleicao}&idEleitor=${procuracao.idEleitor}`)
      }
      if (resp.body.documento.length > 0) {
        if (resp.body.documento.filter(v => v.idProcuracao === procuracao._id).length > 0) {
          this.toaster.pop("error", "SEVI", "O voto desta procuração já foi execido por este motivo não pode ser deletada.");
          return
        }
      }
      const modalRef = this.ngbModal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.item = {
        title: "Excluir Procuração?",
        msg: "Você realmente deseja excluir a procuração selecionada? Após realizar a exclusão o mesma não poderá ser recuperada.",
      }
      modalRef.result.then(async res => {
        if (res) {
          this.blockUi.start("Excluindo procuração");
          try {
            let index = this.procuracoes.findIndex(p => p._id === procuracao._id);
            await this.api.deleteGeneric(environment.urlBase + environment.paths.upload + "/", procuracao.doc);
            await this.api.deleteGeneric(environment.urlBase + environment.paths.procuracoes + "/", procuracao._id);
            this.evento.eleitores.push(procuracao.idRepresentado);

            if(this.item.tipo === "assembleia"){
              await this.api.putGeneric(environment.urlBase + environment.paths.assembleias + "/" + this.evento._id, this.evento);
            } else {
              await this.api.putGeneric(environment.urlBase + environment.paths.eleicoes + "/" + this.evento._id, this.evento);
            }
            this.procuracoes.splice(index, 1);
            this.blockUi.stop();
            this.toaster.pop("success", "SEVI", "Procuração excluída com sucesso.");
          } catch (err) {
            console.error("[[DELETE PROCURAÇÃO]] => ", err)
            this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
            this.blockUi.stop();
          }
        }
      });
    } catch (err) {
      console.error("[[ERRO GET CONTROLE DE VOTOS]] => ", err);
      this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
    }
  }

  addProcuracao() {
    const modalRef = this.ngbModal.open(ModalAddProcuracaoComponent, { size: 'md', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      idAssembleia: this.item.idAssembleia,
      idEleicao: this.item.idEleicao,
      eleitores: this.eleitores
    }
    modalRef.result.then(res => {
      if (res) {
        this.toaster.pop("success", "SEVI", "Procuração registrada com sucesso.");
        this._getProcuracoes();
      }
    });
  }

}
