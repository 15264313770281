<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{item.tipo === "edit" ? "Edição de" : item.tipo === "view" ? "" : "Nova"}} Eleição</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Nome da Eleição<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome" [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="add-margin-top col-12">
                <label class="labels">Ordem do Dia</label>
                <input class="form-control input-round" type="text" id="ordemDia" formControlName="ordemDia">
                <!--[ngClass]="{'invalido':form.get('ordemDia').invalid && submitted}" <p class="erro" *ngIf="form.get('ordemDia').invalid && submitted">Campo Obrigatório</p> -->
            </div>
        </div>
        <div class="row">
            <div class="add-margin-top col-lg-3 col-md-6 col-12">
                <label class="labels">Data Inicio<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="dataInicio" formControlName="dataInicio" [textMask]="{mask: globals.masks.data, guide: false}" [ngClass]="{'invalido':form.get('dataInicio').invalid && submitted}" (blur)="validaDataInicial(form.get('dataInicio').value)">
            </div>
            <div class="add-margin-top col-lg-3 col-md-6 col-12">
                <label class="labels">Hora Inicio<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="horaInicio" formControlName="horaInicio" [textMask]="{mask: globals.masks.hora, guide: false}" [ngClass]="{'invalido':form.get('horaInicio').invalid && submitted}" (blur)="validaHorario(form.get('horaInicio').value, 'horaInicio')">
            </div>
            <div class="add-margin-top col-lg-3 col-md-6 col-12">
                <label class="labels">Data Final<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="dataFinal" formControlName="dataFinal" [textMask]="{mask: globals.masks.data, guide: false}" [ngClass]="{'invalido':form.get('dataFinal').invalid && submitted}" (blur)="validaDataFinal(form.get('dataFinal').value)">
            </div>
            <div class="add-margin-top col-lg-3 col-md-6 col-12">
                <label class="labels">Hora Final<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="horaFinal" formControlName="horaFinal" [textMask]="{mask: globals.masks.hora, guide: false}" [ngClass]="{'invalido':form.get('horaFinal').invalid && submitted}" (blur)="validaHorario(form.get('horaFinal').value, 'horaFinal')">
            </div>
        </div>

        <!-- Adicionar Eleitores Aqui -->

        <div class="row">
            <div class="add-margin-top col-12">
                <label class="labels">Status da Eleição</label>
                <label class="switch">
                    <input type="checkbox" formControlName="status">
                    <span class="slider round" [ngClass]="{sliderDisabled: item.tipo === 'view'}">
                        <p class="label-switch"
                            [ngClass]="{'label-switch-padding-right': form.get('status').value, 'label-switch-padding-left': !form.get('status').value}">
                            {{form.get('status').value ? 'ATIVA' : 'INATIVA'}}
                        </p>
                    </span>
                </label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">{{item.tipo === "view" ? "Fechar" : "Cancelar"}}</button>
    <button type="button" class="btn btn-outline-success" *ngIf="item.tipo !== 'view'" (click)="salvar()">Salvar</button>
</div>