import { ModalViewUserComponent } from '../../modais/modal-view-user/modal-view-user.component';
import { Role } from '../../models/role.enum';
import { ModalEditUserComponent } from '../../modais/modal-edit-user/modal-edit-user.component';
import { User } from '../../models/user';
import { ModalEditarUsuarioComponent } from '../../modais/modal-editar-usuario/modal-editar-usuario.component';
import { ModalAddUserComponent } from '../../modais/modal-add-user/modal-add-user.component';
import { ApiService } from 'src/app/services/api.service';
import { Usuario } from '../../models/usuario';
import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { TrataDocsService } from 'src/app/services/trata-docs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChangeDetectorRef } from '@angular/core';
import { ModalEditSindicatoComponent } from 'src/app/modais/modal-edit-sindicato/modal-edit-sindicato.component';

@Component({
  selector: 'app-sindicatos-perfil',
  templateUrl: './sindicato-perfil.component.html',
  styleUrls: ['./sindicato-perfil.component.css'],
})
export class SindicatoPerfilComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  public searchVal: string = '';

  public infoSindicato = [];
  public eleitores = [];
  public numRegistros = 0;
  public paginaAtual = 1;
  public size = 10;
  private ultimaPagCarregada = 1;
  public opcoes = [5, 10, 25, 50];
  public sortColumn: string = '';
  public sortOrder: boolean = true;

  // private infoSindicato: any = {};

  constructor(
    public modal: NgbModal,
    private apiService: ApiService,
    private toaster: ToasterService,
    private trataDocs: TrataDocsService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this._getInfoSindicato();
  }

  private async _getInfoSindicato() {
    try {
      const resp = await this.apiService.getGeneric(
        environment.urlBase +
          environment.paths.sindicatosCnpj +
          `/${this.auth.currentUserValue.nomeCompleto}`
      );
      const respDocumentosSindicato = resp.body.documento;

      const respEleitor = await this.apiService.getGeneric(
        environment.urlBase +
          environment.paths.eleitores +
          `?sindicatos=${respDocumentosSindicato._id}`
      );

      const respUser = await this.apiService.getGeneric(
        environment.urlBase + environment.paths.usuario
      );
      if (
        resp.body.documento &&
        respUser.body.documento &&
        respEleitor.body.documento
      ) {
        const sindicato = resp.body.documento;
        const usuario = respUser.body.documento;
        const eleitor = respEleitor.body.documento;

        const userEleitor = usuario.filter((item) =>
          eleitor.some((eleitorItem) => eleitorItem.cpf === item.cpf)
        );

        const userSindicato = usuario.filter(
          (item) => item.cpf === sindicato.cnpj
        );
        const mergedArray = [...userSindicato, ...userEleitor];
        this.infoSindicato = mergedArray;

        this.numRegistros = this.infoSindicato.length;
      } else {
        console.error(
          'Documentos indefinidos:',
          resp.body.documento,
          respUser.body.documento
        );
      }
    } catch (err) {
      console.error('[[ERRO GET INFO SINDICATO]]:', err);
    }
  }

  changePage(page) {
    if (page > this.ultimaPagCarregada) {
      this.ultimaPagCarregada = page;
    }
  }

  changeOp(res) {
    this.size = parseInt(res);
    this.paginaAtual = 1;
    this.infoSindicato = [];
    this.blockUi.start('Carregando');
    setTimeout(() => {
      this._getInfoSindicato();
      this.blockUi.stop();
    }, 1500);
  }

  openModalUsuario() {
    const modalRef = this.modal.open(ModalAddUserComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.name = 'World';

    modalRef.result.then((result) => {
      if (result) {
        let index = this.infoSindicato.findIndex((s) => s._id === result._id);
        if (index) {
          this.infoSindicato[index] = result;
        } else {
          this.infoSindicato.push(result);
        }
      }
    });
  }

  openModalViewUsuario(u) {
    const modalRef = this.modal.open(ModalViewUserComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      obj: u,
    };
  }

  openModalEditUsuario(u) {
    const modalRef = this.modal.open(ModalEditSindicatoComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.item = {
      obj: u,
    };

    modalRef.result.then((result) => {
      if (result) {
        let index = this.infoSindicato.findIndex((s) => s._id === result._id);
        if (index) {
          this.infoSindicato[index] = result;
        } else {
          this.infoSindicato.push(result);
        }
      }
    });
  }

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortColumn = column;

    this.infoSindicato.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      }

      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn === column) {
      return this.sortOrder ? 'fas fa-angle-down' : 'fas fa-angle-up';
    }
    return 'fas fa-arrows-alt-v';
  }

  get filteredUsuariosSindicatos() {
    if (!this.searchVal) {
      return this.infoSindicato;
    }
    return this.infoSindicato.filter(
      (sind) =>
        sind.nome.toLowerCase().includes(this.searchVal.toLowerCase()) ||
        sind.cpf.includes(this.searchVal)
    );
  }
}
