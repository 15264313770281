<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{item.title}}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group col-12">
            <p style="white-space: pre-wrap;">
                {{item.msg}}
            </p>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-success" (click)="modal.close(true)">Ok</button>
</div>