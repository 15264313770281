<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        {{item.tipo === "edit" ? "Edição de" : "Nova"}} Pauta</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <label class="labels">Pauta<span class="campo-required"> *</span></label>
                <input class="form-control input-round" type="text" id="nome" formControlName="nome"
                    [ngClass]="{'invalido':form.get('nome').invalid && submitted}">
                <p class="erro" *ngIf="form.get('nome').invalid && submitted">Campo Obrigatório</p>
            </div>
            <div class="col-12">
                <label class="labels">Descrição</label>
                <textarea name="descricao" id="descricao" rows="5" formControlName="descricao"
                    style="width: 100%;" [ngClass]="{'invalido':form.get('descricao').invalid && submitted}"></textarea>
                <p class="erro" *ngIf="form.get('descricao').invalid && submitted">Campo Obrigatório</p>

            </div>
        </div>
    </form>
</div>
<div class="modal-footer" style="justify-content: center;">
    <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">{{item.tipo === "edit" ? "Fechar" :  "Cancelar"}}</button>
    <button *ngIf="item.tipo !== 'view'" type="button" class="btn btn-outline-success" (click)="salvar()">{{item.tipo === "new" ? "Adicionar" : "Editar"}}</button>
</div>