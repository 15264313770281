import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  checkVariavel(v) {
    // return v !== null && v !== "null" && v !== undefined && v !== "undefined" && v.replace(/ +?/g, '') !== "";
    return v !== null && v !== "null" && v !== undefined && v !== "undefined";
  }

  replaceAll(value, search, replace){
    return value.replace(RegExp(search, "g"), replace);
  }

  checkEmail(email){
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  removeEmptyAndNull(obj) {

    Object.keys(obj).forEach(k =>

      (obj[k] && typeof obj[k] === 'object') && this.removeEmptyAndNull(obj[k]) ||

      (!obj[k] && obj[k] !== undefined) && delete obj[k]

    );

    return obj;

  };

}
