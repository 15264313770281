import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { ModalAddPautaComponent } from '../modal-add-pauta/modal-add-pauta.component';

@Component({
  selector: 'app-modal-pautas',
  templateUrl: './modal-pautas.component.html',
  styleUrls: ['./modal-pautas.component.css']
})
export class ModalPautasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public submitted: boolean = false;
  public pautas: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private ngbModal: NgbModal,
    public globals: GlobalsService,
    private toaster: ToasterService,
    private util: UtilService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this._getPautas();
  }

  private async _getPautas() {
    this.blockUi.start("Carregando pautas")
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.pautas + `?idAssembleia=${this.item.idAssembleia}`)
      this.pautas = resp.body.documento;
      this.blockUi.stop();
    } catch (err) {
      console.error("[[ERRO GET PAUTAS]] => ", err)
      this.toaster.pop("error", "SEVI", "Erro interno, atualize a página e tente novamente caso o erro persista avise os administradores da plataforma.")
      this.blockUi.stop();
    }
  }

  public async setOrdem(tipo, index) {
    let indexItem = this.pautas.findIndex(p => p.index === index);
    if (tipo === "UP") {
      if (indexItem > 0) {
        this.blockUi.start("Salvando ordenação");
        this.pautas[indexItem].index = this.pautas[indexItem].index - 1;
        this.pautas[indexItem - 1].index = this.pautas[indexItem].index + 1;
        try {
          await this.api.putGeneric(environment.urlBase + environment.paths.pautas + `/${this.pautas[indexItem]._id}`, this.pautas[indexItem]);
          await this.api.putGeneric(environment.urlBase + environment.paths.pautas + `/${this.pautas[indexItem - 1]._id}`, this.pautas[indexItem - 1]);
          this._ordenaPautas();
          this.blockUi.stop();
        } catch (err) {
          console.error("[[ERROR PUT PAUTA]] => ", err)
          this.blockUi.stop();
        }
      } else {
        this.toaster.pop("error", "SEVI", "Este item é a primeira pauta da assembleia.")
      }
    } else if (tipo === "DOWN") {
      if (indexItem < (this.pautas.length - 1)) {
        this.blockUi.start("Salvando ordenação");
        this.pautas[indexItem].index = this.pautas[indexItem].index + 1;
        this.pautas[indexItem + 1].index = this.pautas[indexItem].index - 1;
        try {
          await this.api.putGeneric(environment.urlBase + environment.paths.pautas + `/${this.pautas[indexItem]._id}`, this.pautas[indexItem]);
          await this.api.putGeneric(environment.urlBase + environment.paths.pautas + `/${this.pautas[indexItem + 1]._id}`, this.pautas[indexItem + 1]);
          this._ordenaPautas();
          this.blockUi.stop();
        } catch (err) {
          console.error("[[ERROR PUT PAUTA]] => ", err)
          this.blockUi.stop();
        }
      } else {
        this.toaster.pop("error", "SEVI", "Este item é a última pauta da assembleia.")
      }
    }
  }

  private _ordenaPautas() {
    return this.pautas.sort(function (a, b) {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
  }



  public openModalAddPauta(tipo, pauta?) {
    const modalRef = this.ngbModal.open(ModalAddPautaComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: tipo,
      index: tipo === "edit" ? pauta.index : this.pautas.length + 1,
      idAssembleia: this.item.idAssembleia,
      pauta: Object.assign({}, pauta)
    }
    modalRef.result.then(res => {
      if (res) {
        if (tipo === "new") {
          this.pautas.push(res);
        } else {
          let index = this.pautas.findIndex(p => p._id === res._id);
          this.pautas[index] = res;
        }
      }
    });
  }

}
