import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { ModalChapaComponent } from '../modal-chapa/modal-chapa.component';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';

@Component({
  selector: 'app-modal-gerencia-chapas',
  templateUrl: './modal-gerencia-chapas.component.html',
  styleUrls: ['./modal-gerencia-chapas.component.css']
})
export class ModalGerenciaChapasComponent implements OnInit {
  @BlockUI() blockUi: NgBlockUI;
  @Input() public item;

  public chapas = [];

  constructor(
    public modal: NgbActiveModal,
    public ngbmodal: NgbModal,
    private toaster: ToasterService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this._getChapas();
  }

  private async _getChapas() {
    try {
      const resp = await this.api.getGeneric(environment.urlBase + environment.paths.chapas + `?idEleicao=${this.item.id}`)
      this.chapas = resp.body.documento;
    } catch (err) {
      console.error("[[ERRO GET CHAPAS]] => ", err)
    }
  }

  openModalChapa(tipo, chapa?) {
    const modalRef = this.ngbmodal.open(ModalChapaComponent, { size: 'xl', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      tipo: tipo,
      id: this.item.id,
      chapa: chapa
    }
    modalRef.result.then(res => {
      if (res) {
        if (chapa) {
          let index = this.chapas.findIndex(c => c.id === this.item.id);
          this.chapas[index] = res;
        } else {
          this.chapas.push(res);
        }
      }
    });
  }

  changeStatus(chapa) {
    const modalRef = this.ngbmodal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      title: chapa.status ? "Inativar Chapa?" : "Inativar Chapa?",
      msg: chapa.status ? "Ao inativar uma chapa a mesma não ira aparecer aos eleitores no momento da votação, você confirma a ação?" : "Você deseja reativar a chapa selecionada?"
    }
    modalRef.result.then(async res => {
      if (res) {
        try {
          this.blockUi.start("Salvando Dados");
          let index = this.chapas.findIndex(c => c._id === chapa._id);
          chapa.status = !chapa.status;
          await this.api.putGeneric(environment.urlBase + environment.paths.chapas + `/${chapa._id}`, chapa);
          this.chapas[index] = chapa;
          this.blockUi.stop();
        } catch (err) {
          console.error("[[ERRO PUT SINDICATO]] => ", err);
          this.blockUi.stop();
          this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista abra um BPM.");
        }
      }
    });
  }

  excluir(id){
    const modalRef = this.ngbmodal.open(ModalConfirmacaoComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.item = {
      title: "Excluir chapa?",
      msg: "Você realmente deseja excluir a chapa selecionada? Após realizar a exclusão a mesma não poderá ser recuperada.",
    }
    modalRef.result.then(async res => {
      if (res) {
        this.blockUi.start("Excluindo a chapa selecionada")
        try {
          this.api.deleteGeneric(environment.urlBase + environment.paths.chapas + `/${id}`)
          let index = this.chapas.findIndex(c => c._id === this.item.id);
          this.chapas.splice(index, 1);
          this.toaster.pop("success", "SEVI", "Chapa excluída com sucesso.");
          this.blockUi.stop();
        } catch (err) {
          console.error("[[ERROR DELETE CHAPA]] => ", err)
          this.toaster.pop("error", "SEVI", "Erro interno, tente novamente e caso o erro persista comunique os gestores da plataforma.");
          this.blockUi.stop();
        }
      }
    });
  }

  }

