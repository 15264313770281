<div class="row" style="justify-content: space-between; align-items: center">
  <h3 class="titulo">Eleitores</h3>
  <div style="text-align: center">
    <button
      class="btn btn-outline-success"
      style="margin: 0px 5px"
      (click)="openModalEleitor('new')"
    >
      <i class="fas fa-plus"></i> Novo Eleitor
    </button>
    <button
      class="btn btn-outline-success"
      style="margin: 0px 5px"
      (click)="openModalUpload()"
    >
      <i class="fas fa-file-upload"></i> Upload de Eleitores
    </button>
  </div>
</div>
<hr />
<div class="d-flex justify-content-end">
  <input
    type="text"
    class="form-control"
    style="width: 400px; border-radius: 14px"
    [(ngModel)]="searchVal"
    placeholder="Buscar Eleitor"
  />
</div>
<div class="table-responsive" style="padding-top: 10px; padding-bottom: 10px">
  <table>
    <thead>
      <tr>
        <th class="th-rigth-border" (click)="sortTable('nome')">
          Eleitor
          <i
            class="fas"
            [ngClass]="getSortIcon('nome')"
            style="margin-left: 5px; cursor: pointer"
          ></i>
        </th>
        <th class="th-rigth-border" (click)="sortTable('instituicao')">
          Empresa
          <i
            class="fas"
            [ngClass]="getSortIcon('instituicao')"
            style="margin-left: 5px; cursor: pointer"
          ></i>
        </th>
        <!-- <th style="width: 150px;" class="th-rigth-border">Status</th> -->
        <th style="width: 150px">Ações</th>
      </tr>
    </thead>
    <tbody id="teste" *ngIf="eleitores.length > 0">
      <tr
        *ngFor="
          let eleitor of filteredEleitor
            | slice : (paginaAtual - 1) * size : (paginaAtual - 1) * size + size
        "
      >
        <td>{{ eleitor.nome }}</td>
        <td>{{ eleitor.instituicao[eleitor.index]?.empresa }}</td>
        <!--
                <td>
                    <div [ngClass]="{'tag-apro': eleitor.status, 'tag-repro': !eleitor.status }" class="tag-apro">
                        {{eleitor.status ? 'ATIVO' : 'INATIVA'}}
                    </div>
                </td>
                 -->
        <td>
          <div style="display: inline-flex">
            <i
              class="fas fa-search acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Visualizar"
              (click)="openModalEleitor('view', eleitor)"
            ></i>
            <i
              class="fas fa-edit acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Editar"
              (click)="openModalEleitor('edit', eleitor)"
            ></i>
            <i
              class="far fa-trash-alt acoes"
              style="padding-left: 5px"
              placement="bottom"
              ngbTooltip="Excluir"
              (click)="excluir(eleitor)"
            ></i>
          </div>
        </td>
      </tr>
    </tbody>
    <p
      *ngIf="filteredEleitor.length <= 0"
      style="color: #ff0000; font-weight: bold"
    >
      • Não existem eleitores cadastrados.
    </p>
  </table>
</div>
<div class="row">
  <div class="col-12" style="padding: 0; text-align: right">
    Quantidade de Eleitores: {{ filteredEleitor.length }}
  </div>
</div>
<div class="row" style="justify-content: space-between">
  <select
    style="width: fit-content !important"
    class="form-control"
    type="text"
    name="qtdPage"
    id="qtdPage"
    [(ngModel)]="size"
    (change)="changeOp(size)"
  >
    <option *ngFor="let op of opcoes">{{ op }}</option>
  </select>
  <ngb-pagination
    class="d-flex justify-content-end"
    [collectionSize]="filteredEleitor.length"
    [(page)]="paginaAtual"
    [pageSize]="size"
    [maxSize]="2"
    [rotate]="true"
    [ellipses]="false"
    [boundaryLinks]="false"
    (pageChange)="changePage($event)"
    size="sm"
  ></ngb-pagination>
</div>
