<img class="logos" src="assets/images/logo_fiesc.png" alt="Logo FIERGS" />
<div class="conteiner">
  <div class="box-login">
    <div
      style="
        text-align: center;
        font-family: panton-bold !important;
        line-height: 18px;
        margin-top: 10px;
      "
    >
      <label style="font-size: 35px; margin: 0; padding: 0">SEVI</label>
      <label style="font-size: 20px; margin: 0; padding: 0"
        >Sistema Eletrônico de Votação para a Indústria</label
      >
    </div>
    <form [formGroup]="form" (ngSubmit)="login()">
      <div class="row">
        <div class="col-12 label-left">
          <label class="labels"
            >Usuário <span class="campo-required">*</span></label
          >
          <input
            class="form-control"
            type="text"
            id="login"
            formControlName="login"
            [ngClass]="{ invalido: form.get('login').invalid && submitted }"
          />
          <p class="erro" *ngIf="form.get('login').invalid && submitted">
            Campo Obrigatório
          </p>
        </div>
        <div class="col-12 input-group label-left">
          <label class="labels"
            >Senha <span class="campo-required">*</span></label
          >
          <input
            id="senha"
            class="form-control"
            type="password"
            id="senha"
            formControlName="senha"
            [ngClass]="{ invalido: form.get('senha').invalid && submitted }"
            style="border-top-left-radius: 4px; border-bottom-left-radius: 4px"
          />
          <div
            class="input-group-append"
            (click)="exibirSenha()"
            style="
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            "
            placement="top"
            ngbTooltip="{{ tooltip }}"
          >
            <span class="input-group-text"
              ><i id="iconExibirSenha" class="far fa-eye"></i
            ></span>
          </div>
          <p class="erro" *ngIf="form.get('senha').invalid && submitted">
            Campo Obrigatório
          </p>
        </div>
        <div class="col-12" style="text-align: right">
          <label class="acao" (click)="openModalResetSenha()"
            >Esqueceu a sua Senha?</label
          >
        </div>
        <div class="col-12 div-acoes">
          <button type="submit" class="btn btn-outline-success">Entrar</button>
        </div>
      </div>
    </form>
  </div>
</div>
