import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { ModalLoginComponent } from './modais/modal-login/modal-login.component';
import { AuthenticationService } from './services/authentication.service';
import { GlobalsService } from './services/globals.service';
import * as jwt_decode from 'jwt-decode';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public config: ToasterConfig = new ToasterConfig({
    timeout: 5000,
    animation: 'flyRight'
  });

  public openModal = true;

  constructor(
    public globals: GlobalsService,
    public modal: NgbModal,
    private authService: AuthenticationService,
    public toasterService: ToasterService,
    private configSelect: NgSelectConfig
  ) {

    this.configSelect.notFoundText = 'Informação não encontrada...';

    setInterval(() => {
      let user = this.authService.currentUserValue;
      if (user) {
        if (user.refreshtoken !== undefined && user.refreshtoken !== null && user.refreshtoken !== "null" && user.refreshtoken !== "") {
          let currentTime = new Date().getTime();
          let refreshTokenDecoded: any = jwt_decode(user.refreshtoken);
          if (Math.floor((refreshTokenDecoded.exp * 1000) / 60000) - Math.floor(currentTime / 60000) < 0) {
            sessionStorage.removeItem("currentUser");
            if (this.openModal) {
              this.openModal = false;
              const modalRef = this.modal.open(ModalLoginComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
              modalRef.result.then(() => {
                this.openModal = true;
              });
            }
          }
        } else if (user.access_token) {
          let currentTime = new Date().getTime();
          let tokenDecoded: any = jwt_decode(user.access_token);
          if (Math.floor((tokenDecoded.exp * 1000) / 60000) - Math.floor(currentTime / 60000) < 0) {
            sessionStorage.removeItem("currentUser");
            if (this.openModal) {
              this.openModal = false;
              const modalRef = this.modal.open(ModalLoginComponent, { size: 'sm', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
              modalRef.result.then(() => {
                this.openModal = true;
              });
            }
          }
        }
      }
    }, 5000);
  }

}
